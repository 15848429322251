import { apolloClient, BigState, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, HomePageSettings, PropsFrom, Utils, VisualStyleSettings } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { DashboardTabRRC } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import gql from "graphql-tag";
import { Header, Menu, Tab, TabProps } from "semantic-ui-react";
import { dashboardEntityDescriptor } from "../FoundationEntityDescriptors";
import { Dashboard, processDashboardAfterLoad } from "./dashboard/DashboardEntityDescriptor";
import { FindByFilterParams } from "../entity_crud/FindByFilterParams";
import { ID } from "../entity_crud/entityCrudConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "../components/CustomQuery/Filter";

export const slicePersonalHomePage = createSliceFoundation(class SlicePersonalHomePage {
    initialState = {
        dashboards: [] as Dashboard[],
        activeDashboardTabIndex: 0
    }

    reducers = {
        ...getBaseReducers<SlicePersonalHomePage>(this),
    }

    impures = {
        ...getBaseImpures<SlicePersonalHomePage>(this),

        async loadDashboards(param: [{ id: number } ]) {
            const query = `query dashboardService_findByFilter($params: FindByFilterParamsInput) {
                dashboardService_findByFilter(params: $params) { 
                    results { 
                        ${dashboardEntityDescriptor.getGraphQlFieldsToRequest()} 
                    } 
                }
            }`;
            const dashboards: Dashboard[] = (await apolloClient.query({
                query: gql(query), variables: FindByFilterParams.create().filter(Filter.create(ID, FilterOperators.forNumber.in, param.map(item => item.id).join(",")))
            })).data.dashboardService_findByFilter.results;

            if (dashboards.length == 0) { 
                return 
            };
            
            dashboards.forEach(dashboard => processDashboardAfterLoad(dashboard));
            this.getDispatchers().setInReduxState({ dashboards });
        },

    }
});
export type PersonalHomePageProps = PropsFrom<typeof slicePersonalHomePage> & {
    homePageSettings: HomePageSettings;
    visualStyleSettings: VisualStyleSettings;
};

/**
 * TODO: probably needs a bit of work to properyl work in any FBA. Not a lot. The heavy work/separation should
 * be pretty OK.
 */
export class PersonalHomePage<P extends PersonalHomePageProps = PersonalHomePageProps> extends TabbedPage<P> {

    constructor(props: P) {
        super(props);
        this.onTabChange = this.onTabChange.bind(this);
    }

    protected getTitle() {
        return { icon: "home", title: _msg("HomePage.title") };
    }

    protected getClassName() {
        return "PersonalHomePage";
    }

    componentDidMount() {
        if (this.props.homePageSettings.homePageDashboards) {
            this.props.dispatchers.loadDashboards(this.props.homePageSettings.homePageDashboards);
        }
    }

    protected renderHeader() {       
        return <div className="flex-container PersonalHomePage_header">
            <Header as="h1" className="PersonalHomePage_welcome_header">{_msg('PersonalHomePage.welcome.header', this.props.currentUser?.firstName || this.props.currentUser?.username)}</Header>
            <Header as="h1" className="PersonalHomePage_welcome_subheader">{_msg('PersonalHomePage.welcome.subheader')}</Header>
        </div>
    }

    protected renderDashboard(dashboard: Dashboard) {
        return <DashboardTabRRC id="personalHomepageDashboard"
                currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
                currentOrganization={this.props.currentOrganization} // currently only root Conn Comp have this; nested: not; hence this workaround to pass the info to the nested
                dashboardEntity={dashboard}
        />;
    }

    protected get castedProps(): PersonalHomePageProps {
        // "as unknown" needed because of 
        //"Conversion of type 'Readonly<P> & Readonly<{ children?: ReactNode; }>' to type 'Props' may be a mistake"
        // I don't know why in tabbebPage.propsCasted this problem doesn't appear
        return this.props as unknown as PersonalHomePageProps;
    }

    protected backgroundImageURL() {
        const props = this.castedProps;
        return props.visualStyleSettings.homeBackgroundImage && "url(" + Utils.adjustUrlToServerContext(props.visualStyleSettings.homeBackgroundImage) + ")";
    }

    protected onTabChange(event: React.MouseEvent<HTMLDivElement>, data: TabProps) {
        if (data.activeIndex as number === this.props.activeDashboardTabIndex ) {
            return;
        }
        this.props.dispatchers.setInReduxState({ activeDashboardTabIndex : data.activeIndex as number });
    }

    protected getTabs() {
        const tabs: any = [];
        const lst = this.props.homePageSettings.homePageDashboards.map(item => item.id);
        Object.values(this.props.dashboards).sort((a: Dashboard, b: Dashboard) =>  lst.indexOf(a.id) - lst.indexOf(b.id)).forEach((dashboard, index) =>
            tabs.push({
                menuItem:
                    <Menu.Item className="less-padding" fitted="vertically" index={index} key={index}>
                        {dashboard.name}
                    </Menu.Item>,
                render: () =>
                    <Tab.Pane className="flex-container flex-grow PersonalHomePage_dashboardTabWrapper" style={{ backgroundImage: this.backgroundImageURL() }}>
                        {this.renderDashboard(dashboard)}
                    </Tab.Pane>
            })
        );

        return tabs;
    }

    protected renderContent() {
        if (this.props.dashboards.length > 1) {
            return <Tab menu={{ attached: true, tabular: true, className: "flex-wrap" }} className="PersonalHomePage_dashboardTabWrapper flex-container flex-grow" panes={this.getTabs()} activeIndex={this.props.activeDashboardTabIndex } onTabChange={this.onTabChange} />
        }
     
        return <div className="PersonalHomePage_dashboardTabWrapper" style={{ backgroundImage: this.backgroundImageURL() }}>
            {this.props.dashboards.length === 1 ? this.renderDashboard(this.props.dashboards[0]) : null}
        </div>
    }

    protected renderMain() {
        return <div className={this.getClassName() + " flex-container"}>
            {this.renderHeader()}
            {this.renderContent()}
        </div>;
    }
}

export const infoPersonalHomePage = new ConnectedPageInfo(slicePersonalHomePage, PersonalHomePage, "PersonalHomePage");
infoPersonalHomePage.routeProps = { path: "/personalHomePage", exact: true, homePageType: "personal" };
infoPersonalHomePage.mapBigStateToProps = (state: BigState, props: PersonalHomePageProps) => {
    props.homePageSettings = state.AppContainer.initializationsForClient.homePageSettings;
    props.visualStyleSettings = state.AppContainer.initializationsForClient.visualStyleSettings;
};"./..""../components/TabbedPage/TabbedPage""./dashboard/dashboardTab/DashboardTab"