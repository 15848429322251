import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar"
import { AggregateFunctionInput, FindByFilterParamsInput } from "../apollo-gen-foundation/globalTypes";

export class FindByFilterParams {
    params: FindByFilterParamsInput = { startIndex: 0, pageSize: -1 };

    filter(filter?: Filter) {
        this.params.filter = filter;
        return this;
    }
    sorts(sorts: Sort[]) {
        this.params.sorts = sorts;
        return this;
    }
    startIndex(startIndex: number) {
        this.params.startIndex = startIndex;
        return this;
    }
    pageSize(pageSize: number) {
        this.params.pageSize = pageSize;
        return this;
    }
    countMode(countMode?: boolean) {
        this.params.countMode = countMode;
        return this;
    }
    bypassOrganizationFilter(bypassOrganizationFilter?: boolean) {
        this.params.bypassOrganizationFilter = bypassOrganizationFilter;
        return this;
    }
    aggregateFunctions(aggregateFunctions: AggregateFunctionInput[] | null) {
        this.params.aggregateFunctions = aggregateFunctions;
        return this;
    }
    static create() {
        return new FindByFilterParams();
    }
}
"../components/CustomQuery/Filter""../components/CustomQuery/SortBar"