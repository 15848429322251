import { castWithHw, HW, ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import React from "react";
import Cron from "react-js-cron";
import { Button, Icon, Popup } from "semantic-ui-react";
import { FieldEditorProps } from "../fieldRenderersEditors";
import { FieldEditor, ScriptableUiFieldEditor } from "./FieldEditor";

export class CronFieldEditor extends FieldEditor<string, FieldEditorProps> {
    setValue = (value: string, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        s.setFieldValue(hw, value ? '0 ' + value : undefined);
    }

    renderWithScriptableUi(s: ScriptableUiFieldEditor.Main): JSX.Element {
        let value: string
        const fieldValue: string | undefined = this.getValue();
        if (fieldValue && fieldValue.length > 1) {
            value = fieldValue.slice(2, fieldValue.length)
        } else {
            value = ''
        }
        if (value === '') {
            return <>
                <span className="tiny-margin-right"><i>{_msg("CronFieldEditor.notSet")}</i></span>
                <HW id="setButton" children={hw => <Button onClick={e => {
                    // Without this fix, when we try to record actions with ScriptableUi and this button is
                    // pressed, the form that contains this editor is submitted
                    e.preventDefault();
                    this.setValue("0 0 * * *", castWithHw(s), hw)
                }} className="small-margin-right">{_msg("CronFieldEditor.set")}</Button>} />
            </>
        } else {
            return <>
                <div className="small-margin-right" style={{ display: "inline-block", marginTop: '1em' }}>
                    <HW id="cron" children={hw => <Cron allowEmpty="never" clearButtonAction="empty" value={value} setValue={(value: string) => this.setValue(value, castWithHw(s), hw)} />} />;
                </div>
                <Popup
                    trigger={<Icon name="question" color="blue" circular className="small-margin-right" />}
                    content={_msg("CronFieldEditor.info")}
                    position="bottom center"
                />
                <span><i>{_msg("CronFieldEditor.expression")}: {value}</i></span>
            </>
        }
    }

}