import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { addAfterStartupRunnable } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTablePage, EntityTablePageProps, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { QueryOptions } from "apollo-client";
import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { CustomFindByFilterParams } from "./NotificationComponent";

export let notificationEntityDescriptor: EntityDescriptor;
export let problemEntityDescriptor: EntityDescriptor;

export const sliceEntityTablePageNotification: SliceEntityTablePage = createSliceFoundation(class Ext extends SliceEntityTablePage {

    loadParamType = "CustomFindByFilterParamsInput";

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        hideRead: true,
        hideIgnoredCodes: true
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        invokeLoadQuerySuper: sliceEntityTablePageOnlyForExtension.impures.invokeLoadQuery,
        async invokeLoadQuery(options: QueryOptions<FindByFilterParams>) {
            const { hideIgnoredCodes, hideRead } = this.getState();
            options.variables = CustomFindByFilterParams.create().setParams(options.variables!.params).setAdditionalFields({ hideIgnoredCodes, hideRead, onlyRead: false, userId: AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.id });
            return await this.invokeLoadQuerySuper(options);
        }
    }
});

addAfterStartupRunnable(() => {

    problemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Problem",
        icon: "bell outline",
        miniFields: ["id"]
    }, false)
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
        .addFieldDescriptor({ name: "entityName", type: FieldType.string })
        .addFieldDescriptor({ name: "entityId", type: FieldType.number })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "severity", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string })
        .addFieldDescriptor({ name: "organization", type: "Organization"})
    );

    notificationEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Notification",
        icon: "bell",
        miniFields: ["id"]
    }, false)
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
        .addFieldDescriptor({ name: "entityName", type: FieldType.string })
        .addFieldDescriptor({ name: "entityId", type: FieldType.number })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "severity", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string })
        .addFieldDescriptor({ name: "deletionDate", type: FieldType.date })
        .addFieldDescriptor({ name: "organization", type: "Organization"})
    );
    
    sliceEntityTablePageNotification.setEntityDescriptor(notificationEntityDescriptor);
    notificationEntityDescriptor.infoTable.slice = sliceEntityTablePageNotification;

    notificationEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceEntityTablePageNotification>> {
        private onCheckboxChange = (field: string) => (e: any, data: CheckboxProps) => {
            this.props.dispatchers.setInReduxState({ [field]: data.checked });
            this.refresh();
        };

        protected preRenderButtons(params: any): Array<OverrideableElement> {
            return [
                ...super.preRenderButtons(params),
                // @ts-ignore
                { elementType: Checkbox, props: { key: "hideRead", checked: this.props.hideRead, label: _msg("Notification.hideRead"), onChange: this.onCheckboxChange("hideRead") } },
                { element: <>&nbsp;</> },
                // @ts-ignore
                { elementType: Checkbox, props: { key: "hideIgnoredCodes", checked: this.props.hideIgnoredCodes, label: _msg("Notification.hideIgnoredCodes"), onChange: this.onCheckboxChange("hideIgnoredCodes") } },
            ];
        }
    };
});"../../AppMetaTempGlobals""../../components/TabbedPage/TabbedPage""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityTablePage""../../entity_crud/FieldType""../../entity_crud/FindByFilterParams""../../reduxHelpers"