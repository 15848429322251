import { createSliceFoundation, EntityEditorPage, getBaseImpures, getBaseReducers, ITableActionParamForRun, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, StateFrom } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EditMode } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { EntityTablePage, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { DashboardTab, DashboardTabRRC } from "./dashboardTab/DashboardTab";
import { DashboardWidgetFactories } from "./DashboardWidgetFactory";

export type Dashboard = {
    id: number;
    name: string;
    icon: string;
    color: string;
    width: number;
    showInMenu: boolean;
    positionInMenu: number;
    fitHeight: boolean;
    configJson: string;
    filterJson: string;
    organizationFilterJson: string;
    entityName: string;
    forEditor: boolean;

    widgetHeaderFontSize?: number;
    widgetHeaderBackgroundColor?: string;
    widgetHeaderIcon?: SemanticICONS;

    // not in Java
    filter: Filter;
    config: DashboardConfig;
}

export type DashboardConfig = {
    layouts: ReactGridLayout.Layouts;
    widgetWrapperConfigs: any;
    widgetHeaderFontSize?: number;
    widgetHeaderBackgroundColor?: string;
    widgetHeaderIcon?: SemanticICONS;
}

class WidgetHeaderFontSizeFieldDescriptor extends FieldDescriptor {

    constructor(name: string) {
        super();
        this.name = name;
        this.type = FieldType.number;
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
        this.isInDefaultColumnConfigForEditor = false;
    }

    getAppearsInUi(): boolean {
        return true;
    }

}

export class DashboardEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Dashboard",
            miniFields: ["name"],
            icon: "dashboard",
            hasAttachedDashboards: false
        });
    }
 
    protected customize() {
        this.addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
            .addFieldDescriptor({ name: "name", type: FieldType.string })
            .addFieldDescriptor({ name: "icon", type: FieldType.string })
            .addFieldDescriptor({ name: "color", type: FieldType.color })
            .addFieldDescriptor({ name: "showInMenu", type: FieldType.boolean })
            .addFieldDescriptor({ name: "positionInMenu", type: FieldType.number })
            .addFieldDescriptor({ name: "fitHeight", type: FieldType.boolean })
            .addFieldDescriptor(new WidgetHeaderFontSizeFieldDescriptor("widgetHeaderFontSize"))
            .addFieldDescriptor({ name: "widgetHeaderBackgroundColor", type: FieldType.color, clientOnly: true })
            .addFieldDescriptor({ name: "widgetHeaderIcon", type: FieldType.string, clientOnly: true })
            .addFieldDescriptor({ name: "organization", type: "Organization" })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
            .addFieldDescriptor({ name: "forEditor", type: FieldType.boolean })
            .addFieldDescriptor({ name: "filterJson", type: FieldType.filter, fieldForEntityName: "entityName", filterable: false, sortable: false })
            .addFieldDescriptor({ name: "organizationFilterJson", type: FieldType.filter, entityName: "Organization", filterable: false, sortable: false })

        this.infoTable.slice = sliceEntityTablePageDashboard.setEntityDescriptor(this);
        this.infoTable.wrappedComponentClass = EntityTablePageDashboard;
        this.infoEditor.slice = sliceEntityEditorPageDashboard.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = EntityEditorPageDashboard;
    }

    protected getInfoEditor() {
        const result = super.getInfoEditor();
        result.routeProps!.routeIsModal = false;
        return result;
    }
}

const sliceEntityTablePageDashboard = createSliceFoundation(class Ext extends SliceEntityTablePage {
    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        deleteModalOpenedFor: undefined as { name: string, id: number } | undefined
    }

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices,
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
});

class EntityTablePageDashboard extends EntityTablePage<PropsFrom<typeof sliceEntityTablePageDashboard>> {

    protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        return [
            {
                icon: "edit",
                label: _msg("entityCrud.table.edit"),
                run: (param) => {
                    this.goToEditor(entityDescriptor.getEntityEditorUrl(param.selection[0].id));
                }
            },
            {
                icon: "remove",
                label: _msg("entityCrud.table.delete"),
                run: (param) => {
                    this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: { name: entityDescriptor.name, id: param.selection[0].id } });
                }
            }
        ];
    }

    renderMain() {
        return <>
            {super.renderMain()}
            <Modal open={this.props.deleteModalOpenedFor !== undefined} closeOnDocumentClick closeOnEscape
                onClose={() => this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined })}>
                <Modal.Header>{_msg("Dashboard.button.delete.messageHeader")}</Modal.Header>
                <Modal.Content>
                    {_msg("Dashboard.button.delete.messageContent")}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined })}>{_msg("general.no")}</Button>
                    <Button positive onClick={() => {
                        if (!this.props.deleteModalOpenedFor) {
                            return;
                        }
                        this.deleteEntity(this.props.deleteModalOpenedFor.name, this.props.deleteModalOpenedFor.id, this.props.location)
                        this.props.dispatchers.setInReduxState({ deleteModalOpenedFor: undefined });
                    }}>{_msg("general.yes")}</Button>
                </Modal.Actions>
            </Modal>
        </>
    }
}

export const sliceEntityEditorPageDashboard = createSliceFoundation(class Ext extends SliceEntityEditorPage {

    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
    }

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this),

        onModeLoadedSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeLoaded,
        onModeLoaded(state: StateFrom<Ext>, entity: any) {
            this.onModeLoadedSuper(state, entity);
            processDashboardAfterLoad(entity);
        }
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
});

export class EntityEditorPageDashboard extends EntityEditorPage<PropsFrom<typeof sliceEntityEditorPageDashboard>> {

    dashboardTabRef: React.RefObject<DashboardTab>;

    constructor(props: Readonly<PropsFrom<typeof sliceEntityEditorPageDashboard>>) {
        super(props);
        const that = this;
        this.editorFormSimpleClass = class extends CrudFormInEditor {
            protected isFieldEnabled(fieldDescriptor: FieldDescriptor) {
                if (["forEditor", "filterJson"].includes(fieldDescriptor.name)) {
                    const widgetWrapperConfigs = that.props.entity?.configJson ? JSON.parse(that.props.entity.configJson).widgetWrapperConfigs : [];
                    if (widgetWrapperConfigs && Object.keys(widgetWrapperConfigs).length > 0) {
                        return false;
                    }
                    return true;
                } else {
                    return super.isFieldEnabled(fieldDescriptor);
                }
            }
        }
        this.dashboardTabRef = React.createRef<DashboardTab>();
    }

    protected async onSaveInternal() {
        const entity = this.props.entity;
        let config: any = { layouts: { "lg": [] }, widgetWrapperConfigs: {} };
        if (this.props.entity.id) {
            config = { ...this.props.entity.config };
            // CS: The following fields are not in the Java entity. It's a hack to make them editable from the 
            // entity form. 
            config.widgetHeaderFontSize = entity.widgetHeaderFontSize;
            config.widgetHeaderBackgroundColor = entity.widgetHeaderBackgroundColor;
            config.widgetHeaderIcon = entity.widgetHeaderIcon;
        }
        this.props.dispatchers.setInReduxState({ entity: { ...entity, config, configJson: JSON.stringify(config) } });
        super.onSaveInternal();
        this.dashboardTabRef.current?.props.r.setInReduxState({ dirty: false });
    }

    protected async onRevert() {
        super.onRevert();
        this.dashboardTabRef.current?.props.r.setInReduxState({ dirty: false });
    }

    isDirty() {
        return super.isDirty() || this.dashboardTabRef.current?.props.s.dirty;
    }

    protected getExtraTabPanes() {
        return [
            {
                routeProps: { path: "/dashboard" },
                menuItemProps: { icon: "chart pie", content: _msg("Dashboard.label"), disabled: (this.props.entity && this.props.entity.id ? false : true) || this.props.mode !== EditMode.EDIT || this.props.duplication },
                render: () => {
                    return <DashboardTabRRC id="dashboard" ref={this.dashboardTabRef} editable
                        currentOrganization={this.props.currentOrganization} // currently only root Conn Comp have this; nested: not; hence this workaround to pass the info to the nested
                        currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
                        buttons={this.renderButtons({ columnConfig: false, dashboard: true } as any)}
                        dashboardEntity={this.props.entity}
                        refresh={() => this.props.dispatchers.load(this.props.entity.id)}
                    />;
                },
                commit: () => {
                    if (!this.dashboardTabRef.current) {
                        return;
                    }
                    const { widgetWrapperConfigs, layouts } = this.dashboardTabRef.current.props.s;
                    const config = { ...this.props.entity.config, widgetWrapperConfigs, layouts };
                    this.props.dispatchers.setInReduxState({ entity: { ...this.props.entity, config } });
                }
            }
        ];
    }
}

export function processDashboardAfterLoad(entity: Dashboard) {
    entity.config = JSON.parse(entity.configJson);
    entity.widgetHeaderFontSize = entity.config.widgetHeaderFontSize;
    entity.widgetHeaderBackgroundColor = entity.config.widgetHeaderBackgroundColor;
    entity.widgetHeaderIcon = entity.config.widgetHeaderIcon;
}

export function getAdditionalFieldsToRequest(entity: Dashboard) {
    const config: DashboardConfig = JSON.parse(entity.configJson);
    return Array.prototype.concat.apply([], Object.keys(config.widgetWrapperConfigs).map(key => {
        const w = config.widgetWrapperConfigs[key];
        const factory = DashboardWidgetFactories.INSTANCE.widgets[w.type];
        return factory.getAdditionalFieldsToRequest(w.widgetConfig);
    }));
    
}
"../..""../../components/CustomQuery/Filter""../../entity_crud/CrudFormInEditor""../../entity_crud/EntityDescriptor""../../entity_crud/EntityEditorPage""../../entity_crud/EntityTablePage""../../entity_crud/FieldType"