import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import DropdownFieldEditor from "@crispico/foundation-react/entity_crud/fieldEditors/DropdownFieldEditor";
import NumberFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/NumberFieldRenderer";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { default as _ } from "lodash";
import React from "react";
import {
    Button, Checkbox, 
    Form, Modal, TextArea
} from "semantic-ui-react";
import { DummyToRememberPeopleToCast, EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { CronFieldEditor } from "./fieldEditors/CronFieldEditor";
import PieCountColorPaletteFieldEditor from "./fieldEditors/PieCountColorPaletteFieldEditor";
import PieCountColorPaletteFieldRenderer from "./fieldRenderers/PieCountColorPaletteFieldRenderer";
import { FieldType } from "./FieldType";
import { DatePickerFieldEditor } from "./fieldEditors/DatePickerFieldEditor";
import { EntityFieldsFieldEditor } from "./fieldEditors/EntityFieldsFieldEditor";
import { EntityFieldsFieldRenderer } from "./fieldRenderers/EntityFieldsFieldRenderer";
import { AssociationEditorProps, AssociationExtraProps, AssociationFieldEditor } from "./fieldEditors/AssociationFieldEditor";
import { AssociationFieldRenderer } from "./fieldRenderers/AssociationFieldRenderer";
import { DropdownFieldRenderer } from "./fieldRenderers/DropdownFieldRenderer";
import { ColorFieldRenderer } from "./fieldRenderers/ColorFieldRenderer";
import { FieldEditorNotUsableStandAloneProps, FieldEditorProps } from "./fieldEditors/FieldEditor";
import { StringFieldEditor } from "./fieldEditors/StringFieldEditor";
import { NumberFieldEditor } from "./fieldEditors/NumberFieldEditor";
import { DoubleFieldEditor } from "./fieldEditors/DoubleFieldEditor";
import { BooleanFieldEditor } from "./fieldEditors/BooleanFieldEditor";
import { RangePickerExtFieldEditor } from "./fieldEditors/RangePickerExtFieldEditor";
import { ColorFieldEditor } from "./fieldEditors/ColorFieldEditor";
import TimezoneFieldEditor from "./fieldEditors/TimezoneFieldEditor";
import TextFieldEditor from "./fieldEditors/TextFieldEditor";
import { EntityNameFieldEditor } from "./fieldEditors/EntityNameFieldEditor";
import { ProgressFieldRenderer } from "./fieldRenderers/ProgressFieldRenderer";
import { SortFieldRenderer } from "./fieldRenderers/SortFieldRenderer";
import { SortFieldEditor } from "./fieldEditors/SortFieldEditor";
import { FilterFieldRenderer } from "./fieldRenderers/FilterFieldRenderer";
import { FilterFieldEditor } from "./fieldEditors/FilterFieldEditor";
import { ProgressFieldEditor } from "./fieldEditors/ProgressFieldEditor";

// after moving it, still used in a lot of places from this file; so reexporting for now
export type { FieldEditorProps } from "./fieldEditors/FieldEditor";

//#region 
export interface FieldRendererProps extends DummyToRememberPeopleToCast {
    entity: any,
    value: any; // field value
    fieldDescriptor: FieldDescriptor;
    innerEntityDescriptor?: EntityDescriptor;
}

class TextFieldRenderer extends React.Component<FieldRendererProps, { modalOpen: boolean }> {

    constructor(props: FieldRendererProps) {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{text}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <TextArea rows={5} >{text}</TextArea>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

export const fieldRenderers: { [key: string]: any } = {
    [FieldType.defaultManyToOne]: (props: FieldRendererProps) => {
        return <AssociationFieldRenderer {...props} asLink />
    },
    [FieldType.defaultOneToMany]: (props: FieldRendererProps) => {
        return <AssociationFieldRenderer {...props} asLabel />
    },
    [FieldType.number]: NumberFieldRenderer,
    [FieldType.double]: NumberFieldRenderer,
    [FieldType.string]: StringFieldRenderer,
    [FieldType.text]: TextFieldRenderer,
    [FieldType.boolean]: (props: FieldRendererProps) => {
        const checked: boolean = props.value != null && props.value as boolean;
        return <Checkbox checked={checked} />
    },
    [FieldType.date]: DateFieldRenderer,
    [FieldType.color]: ColorFieldRenderer,
    [FieldType.sort]: SortFieldRenderer,
    [FieldType.entityName]: (props: FieldRendererProps) => <>{props.value ? String(props.value) : ""}</>,
    [FieldType.entityFields]: EntityFieldsFieldRenderer,
    [FieldType.timeZone]: (props: FieldRendererProps) => <>{props.value?.label || ""}</>,
    [FieldType.progress]: (props: FieldRendererProps) => <ProgressFieldRenderer value={props.value} />,
    [FieldType.cron]: (props: FieldRendererProps) => <>{props.value}</>,
    [FieldType.dropdown]: DropdownFieldRenderer,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldRenderer,
    [FieldType.filter]: FilterFieldRenderer
}

//#endregion

//#region 
export const fieldEditors: { [key: string]: any } = {
    [FieldType.double]: DoubleFieldEditor,
    [FieldType.number]: NumberFieldEditor,
    [FieldType.string]: StringFieldEditor,
    [FieldType.text]: TextFieldEditor,
    [FieldType.timeZone]: TimezoneFieldEditor,
    [FieldType.progress]: ProgressFieldEditor,
    [FieldType.color]: ColorFieldEditor,
    [FieldType.boolean]: BooleanFieldEditor,
    [FieldType.rangeDate]: RangePickerExtFieldEditor,
    [FieldType.date]: DatePickerFieldEditor,
    [FieldType.defaultManyToOne]: (props: FieldEditorNotUsableStandAloneProps & AssociationEditorProps & AssociationExtraProps) => {
        return <AssociationFieldEditor {...props} isMulti={false} />;
    },
    [FieldType.defaultOneToMany]: (props: FieldEditorNotUsableStandAloneProps & AssociationEditorProps & AssociationExtraProps) => {
        return <AssociationFieldEditor {...props} isMulti={true} />;
    },
    [FieldType.sort]: SortFieldEditor,
    [FieldType.entityName]: EntityNameFieldEditor,
    [FieldType.entityFields]: EntityFieldsFieldEditor,
    [FieldType.cron]: CronFieldEditor,
    [FieldType.dropdown]: DropdownFieldEditor,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldEditor,
    [FieldType.filter]: FilterFieldEditor
}
//#endregion"../components/ModalExt/ModalExt""./fieldRenderers/DateFieldRenderer""./fieldEditors/DropdownFieldEditor""./fieldRenderers/NumberFieldRenderer""./fieldRenderers/StringFieldRenderer"