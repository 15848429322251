import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { TestsAreDemoCheat } from "@famiprog-foundation/tests-are-demo";
import { Input, InputProps, StrictInputProps } from "semantic-ui-react";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";

export interface StringFieldEditorProps<V = any> extends FieldEditorProps<V>, StrictInputProps {
}

export class StringFieldEditor<V = string, P extends StringFieldEditorProps<V> = StringFieldEditorProps<V>> extends FieldEditor<V, P> {

    // I put any, InputProps is missing some props; e.g. "type"
    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return undefined;
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const { props } = this;
        return <Input autoFocus={props.autoFocus} ref={props.refForFocus} name={props.fieldDescriptor?.getFieldName()} value={this.getValue()} onChange={(event, data) => s.setFieldValue(hw, data.value)} {...this.getInputProps(s, hw)} {...this.getPropsToForward()} />
    }

    renderWithScriptableUi(s: ScriptableUiFieldEditor.Main) {
        return <>
            <TestsAreDemoCheat objectToPublish={this} />
            {super.renderWithScriptableUi(s)}
        </>
    }

    // CS: thanks to ScriptableUi, I don't think we'll need the lines below any more

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadType = (value: string) => {
        this.scriptableUiImpl.setFieldValue(value);
    }
}