import { SettingsEntityDescriptorDto } from "@crispico/foundation-react/apollo-gen-foundation/SettingsEntityDescriptorDto";
import { settingsEntityService_getDescriptor, settingsEntityService_getDescriptorVariables } from "@crispico/foundation-react/apollo-gen-foundation/settingsEntityService_getDescriptor";
import { settingsEntityService_getSettingsRootTypes } from "@crispico/foundation-react/apollo-gen-foundation/settingsEntityService_getSettingsRootTypes";
import { SettingsFieldDescriptorDto } from "@crispico/foundation-react/apollo-gen-foundation/SettingsFieldDescriptorDto";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { OnSelectParams, RenderItemParams, SliceTree, Tree } from "@crispico/foundation-react/components/TreeNew/Tree";
import { WizardRRC, WizardStep } from "@crispico/foundation-react/components/Wizard/Wizard";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityEditorFormSimple, ScriptableUiCrudForm } from "@crispico/foundation-react/entity_crud/EntityEditorFormSimple";
import { ADD, EntityEditorPage, SliceEntityEditorPage } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { graphQlToFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { GET_DESCRIPTOR, GET_SETTINGS_ROOT_TYPES } from "@crispico/foundation-react/pages/SettingsEntity/queries";
import { organizationEntityDescriptor } from "@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor";
import { createSliceFoundation, createSliceFoundationFromCallback, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { push } from "connected-react-router";
import { FormikProps, isInteger } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Checkbox, Container, Dropdown, DropdownProps, Form, Grid, Header, Icon, Label, MenuItemProps, Message, Search, SearchProps, Segment, SegmentGroup, TextArea } from "semantic-ui-react";
import { v4 as uuid } from 'uuid';
import { Optional } from "../../CompMeta";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

const TREE_ROOT_ID = "root";
const FIELD_LIST_TYPE = "list";
const FIELD_UID = "uid"
const CONTRIBUTORS_FOR_LABEL = "contributorsForLabel";

class SliceSettingsTree extends SliceTree {

    rootType!: string

    hasChildren(item: any) {
        return item && (item.type === this.rootType || item.w || (item instanceof Array && item.length > 0) || Object.keys(item).find(key => item[key] instanceof Array && key !== "contributorsForLabel"));
    }

    getChildren(item: any) {
        if (item.type === this.rootType) {
            return [{ localId: TREE_ROOT_ID, item: { w: item } }];
        }

        if (item.w) { item = item.w };

        if (item instanceof Array) {
            return item.map((field: any, i: any) => ({ localId: i + "", item: field }));
        }

        const children: any = [];
        Object.keys(item).forEach(key => {
            if (item[key] instanceof Array && key !== CONTRIBUTORS_FOR_LABEL) {
                children.push({ localId: key, item: item[key] });
            }
        });
        return children;

    }
}
const sliceTree: SliceSettingsTree = createSliceFoundation(SliceSettingsTree);

// dummy slice, created only to copy structure for initialState, reducers, impures
// TODO CC: find another solution to inject values from super class
const dummySlice = createSliceFoundation(SliceEntityEditorPage);

class SubtypeFieldEditor extends FieldEditor<FieldEditorProps> {
    protected handleChange (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        s.setFieldValue(hw, data.value);
        (this.props as any).onTypeSelect(data.value);
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const options = (this.props as any).subtypes.map((subtype: any) => {return {text: subtype.name, value: subtype.name}});
        return <Dropdown fluid selection disabled={(this.props as any).disabled} search={true} options={options} onChange={(event, data) => this.handleChange(event, data, s, hw)} value={this.getValue()} />
    }
}

const subtypeFieldEditor = (subtypes: any[], disabled: boolean, onTypeSelect: (type: string) => void) => new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "type";
        this.type = "string";
        this.subtypes = subtypes;
        this.disabled = disabled;
        this.onTypeSelect = onTypeSelect;
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        return React.createElement(SubtypeFieldEditor as any, {...props, ...{subtypes: subtypes, disabled: disabled, onTypeSelect: onTypeSelect}} as FieldEditorProps);
    }
}()

export const sliceSettingsEntityEditorPage = createSliceFoundationFromCallback(() => class SliceSettingsEntityEditorPage extends SliceEntityEditorPage {

    nestedSlices = {
        ...dummySlice.nestedSlices,
        tree: sliceTree,
    }

    initialState = {
        ...dummySlice.initialState,
        descriptor: undefined as Optional<SettingsEntityDescriptorDto>,
        settingObject: {} as any,
        newSettingAvailableTypes: [] as string[],
        newSettingSearchedText: "",
        currentEditorTreeItemId: undefined as Optional<string>,
        rawJson: "" as string
    }

    reducers = {
        ...dummySlice.reducers,
        ...getBaseReducers<SliceSettingsEntityEditorPage>(this),

        // utility method, needed to populate tree editor with list items
        _setEmptyLists(settingsObject: any, descriptor: any) {
            descriptor?.fields?.forEach((field: any) => {
                if (field && field.type === FIELD_LIST_TYPE) {
                    if (!settingsObject[field.name]) {
                        settingsObject[field.name] = [];
                    } else {
                        (settingsObject[field.name] as []).forEach(item => this._setEmptyLists(item, field.descriptor));
                    }
                    // this._setEmptyLists(settingsObject[field.name], field.descriptor);
                }
            })
        },

        setDescriptor(state: StateFrom<SliceSettingsEntityEditorPage>, p: { descriptor: SettingsEntityDescriptorDto, uidValue: string }) {
            state.descriptor = p.descriptor;

            let newObj = {};
            if (state.settingObject.type !== state.descriptor.name) { // reset settingObject when descriptor changed             
                newObj = { type: state.descriptor.name, [FIELD_UID]: p.uidValue }
            } else {
                newObj = JSON.parse(state.entity.json);
            }
            this._setEmptyLists(newObj, state.descriptor);
            state.settingObject = newObj;
        },

        updateItem(state: StateFrom<SliceSettingsEntityEditorPage>, p: { itemId: string, values: any }) {
            const objectId = Utils.substringAfter(p.itemId as string, Utils.defaultIdSeparator, false);
            const obj = objectId === TREE_ROOT_ID ? state.settingObject : Utils.navigate(state.settingObject, objectId);
            if (obj instanceof Array) {
                return;
            }
            Object.keys(p.values).forEach(key => {
                obj[key] = p.values[key];
            });
        },

        addEmptyItem(state: StateFrom<SliceSettingsEntityEditorPage>, p: { parentId: string | string[], descriptor: any, uidValue: string }) {
            const parentObj: Array<any> = Utils.navigate(state.settingObject, p.parentId);
            let newItem = { type: p.descriptor.name, [FIELD_UID]: p.uidValue };
            this._setEmptyLists(newItem, p.descriptor);
            parentObj.push(newItem);
        },

        removeItem(state: StateFrom<SliceSettingsEntityEditorPage>, itemId: string) {
            const parentId = Utils.substringBefore(itemId, Utils.defaultIdSeparator, true);
            const parentObj: Array<any> = Utils.navigate(state.settingObject, parentId);
            parentObj.splice(parseInt(itemId.slice(itemId.lastIndexOf('|') + 1)), 1);
        },

        changeItemIndexInList(state: StateFrom<SliceSettingsEntityEditorPage>, p: { itemId: string, oldIndex: number, newIndex: number }) {
            const parentId = Utils.substringBefore(p.itemId, Utils.defaultIdSeparator, true);
            const parentObj: Array<any> = Utils.navigate(state.settingObject, parentId);
            // const oldIndex = parseInt(p.itemId.slice(p.itemId.lastIndexOf('|') + 1));
            const obj = parentObj.splice(p.oldIndex, 1);       
            parentObj.splice(p.newIndex, 0, obj[0]);           
        }
    }

    impures = {
        ...dummySlice.impures,
        ...getBaseImpures<SliceSettingsEntityEditorPage>(this),

        async getDescriptor(settingsRootType: string) {
            const descriptor: SettingsEntityDescriptorDto = (await apolloClient.query<settingsEntityService_getDescriptor, settingsEntityService_getDescriptorVariables>({
                query: GET_DESCRIPTOR, variables: { settingsRootType }
            })).data.settingsEntityService_descriptor;

            this.getDispatchers().setDescriptor({ descriptor, uidValue: uuid() });
        },

        async getSettingsRootTypes() {
            const types: string[] | null = (await apolloClient.query<settingsEntityService_getSettingsRootTypes, any>({
                query: GET_SETTINGS_ROOT_TYPES
            })).data.settingsEntityService_settingsRootTypes;

            types && this.getDispatchers().setInReduxState({ newSettingAvailableTypes: types });
        },

        getField(itemId: string[], descriptor: any | undefined): any {
            descriptor = Object.assign({}, descriptor);
            let field;
            for (let id of itemId) {
                if (isInteger(id)) {
                    continue;
                }
                if (!descriptor || !descriptor.fields) {
                    return undefined;
                }
                field = descriptor.fields.find((field: any) => field.name === id);
                if (!field && descriptor.subtypes && descriptor.subtypes.length > 0) {
                    descriptor.subtypes.forEach((subtype: any) => {
                        if (subtype.fields && subtype.fields.length > 0) {
                            field = subtype.fields.find((field: any) => field.name === id);
                        }
                    });
                }
                if (field && field.descriptor) {
                    descriptor = Object.assign({}, field.descriptor);
                }
            }
            return field;
        },

        addTreeItem(parentId: string): any {
            const parentObjId = Utils.substringAfter(parentId, Utils.defaultIdSeparator, false);
            const field: any = this.getField(parentObjId.split(Utils.defaultIdSeparator), this.getState().descriptor);
            this.getDispatchers().addEmptyItem({ parentId: parentObjId, descriptor: field.descriptor, uidValue: uuid() });

            this.getDispatchers().tree.expandCollapse({ id: parentId, isExpand: true });
            this.getDispatchers().tree.selectItem(parentId + Utils.defaultIdSeparator + (Utils.navigate(this.getState().settingObject, parentObjId).length - 1));
        },

        removeTreeItem(treeItemId: string): any {
            const itemObjId = Utils.substringAfter(treeItemId, Utils.defaultIdSeparator, false);

            this.getDispatchers().removeItem(itemObjId);
            this.getDispatchers().tree.selectItem(Utils.substringBefore(treeItemId, Utils.defaultIdSeparator, true));
        },

        changeTreeItemIndexInList(treeItemId: string, goUp: boolean) {
            const itemObjId = Utils.substringAfter(treeItemId, Utils.defaultIdSeparator, false);

            const parentId = Utils.substringBefore(itemObjId, Utils.defaultIdSeparator, true);
            const parentObj: Array<any> = Utils.navigate(this.getState().settingObject, parentId);
            const oldIndex = parseInt(itemObjId.slice(itemObjId.lastIndexOf('|') + 1));           
            const newIndex = goUp ? (oldIndex === 0 ? 0 : oldIndex - 1) : (oldIndex === parentObj.length - 1 ? parentObj.length - 1 : oldIndex + 1);

            this.getDispatchers().changeItemIndexInList({ itemId: itemObjId, oldIndex: oldIndex, newIndex: newIndex });
            this.getDispatchers().tree.selectItem(treeItemId.slice(0, treeItemId.lastIndexOf('|') + 1) + newIndex);
        },

        triggerEditorSubmit(params: OnSelectParams, editor: Optional<SettingsEntityEditorFormSimple>): any {
            // prevent selection, first submit form
            params.prevent = true;
            this.getDispatchers().setInReduxState({ currentEditorTreeItemId: params.itemId });// store it here, will be used in editorSubmitHandler  
            return editor?.submit();
        },

        editorSubmitHandler(currentTreeItemId: Optional<string>, values: any) {
            currentTreeItemId && this.getDispatchers().updateItem({ itemId: currentTreeItemId, values: values });
            // now set new selection
            this.getState().currentEditorTreeItemId && this.getDispatchers().tree.selectItem(this.getState().currentEditorTreeItemId!);
        },

        async saveSettings(values: any, editor: Optional<SettingsEntityEditorFormSimple>) {
            if (this.getState().tree.selectedId) {
                await this.triggerEditorSubmit({ itemId: this.getState().tree.selectedId!, prevent: false }, editor);
            }
            return this.save({ ...values, json: JSON.stringify(this.getState().settingObject) });
        },

        saveSuper: dummySlice.impures.save,
        async save(entity: any) {
            const response = await this.saveSuper(entity, true, ["rootType", "organization", "json", "enabled"]);
            AppMetaTempGlobals.appMetaInstance.loadInitializationsForClient();
            return response;
        }
    }

});

// workaround for solving cycle issue
export type SliceSettingsEntityEditorPage = typeof sliceSettingsEntityEditorPage;

/**
 * Add mode: wizard with 3 steps: select type, form, settings editor
 * Edit mode: 2 tabs -> settings editor, form 
 */
// workaround for solving cycle issue
export let SettingsEntityEditorPage: any;
addAfterStartupRunnable(() => SettingsEntityEditorPage = class extends EntityEditorPage<PropsFrom<SliceSettingsEntityEditorPage>> {

    settingsEntityEditorFormSimple = React.createRef<SettingsEntityEditorFormSimple>();
    entityEditorForm = React.createRef<EntityEditorFormSimple>();

    protected async onSaveInternal() {
        this.props.dispatchers.saveSettings(this.props.entity, this.settingsEntityEditorFormSimple.current);
    }

    protected onMatchChanged(match: any) {
        if (this.props.match) { // TODO CC: aici as avea nevoie ca state-ul sa se reseteze doar pentru dev, nu si storybook (de aceea am pus verif dupa match)
            // reset to initialStates, no need to keep old values
            this.props.dispatchers.tree.setInReduxState(this.props.dispatchers.tree.getSlice().initialState);
            this.props.dispatchers.setInReduxState(this.props.dispatchers.getSlice().initialState);
        }

        super.onMatchChanged(match);

        if (this.props.match?.params.id === ADD) { // only on ADD                   
            this.props.dispatchers.getSettingsRootTypes();
        }
    }

    componentDidUpdate(prevProps: PropsFrom<SliceSettingsEntityEditorPage>) {
        super.componentDidUpdate(prevProps);
        const props = this.props;

        // edit entity, after load, when rootType arrives => set settingObject and getDescriptor
        // TODO CC: aici as avea nevoie ca state-ul sa se reseteze doar pentru dev, nu si storybook (de aceea am pus verif dupa match)
        if ((this.props.match && this.props.match.params.id !== ADD && prevProps.entity?.rootType !== props.entity?.rootType && props.entity?.rootType) || 
                (props.duplication && Object.keys(props.settingObject).length === 0 && props.entity)) {
            props.dispatchers.setInReduxState({ settingObject: JSON.parse(props.entity.json), rawJson: props.entity.json });
            props.dispatchers.getDescriptor(props.entity.rootType);
        }
        // settingObject changed -> update tree state & currentEditorTreeItemId
        if (prevProps.settingObject !== props.settingObject) {
            props.dispatchers.tree.setInReduxState({ root: props.settingObject });

            if (prevProps.settingObject.type !== props.settingObject.type) { // only if type changed, reset selection & expended items
                props.dispatchers.setInReduxState({ currentEditorTreeItemId: TREE_ROOT_ID });
                props.dispatchers.tree.selectItem(TREE_ROOT_ID);
                props.dispatchers.tree.expandCollapse({ id: TREE_ROOT_ID, isExpand: true });
            }
        }
    }

    componentWillUpdate(nextProps: PropsFrom<SliceSettingsEntityEditorPage>, nextState: StateFrom<SliceSettingsEntityEditorPage>, nextContext: any) {
        // if tab changed from treeEditor to form, trigger submit
        if (nextProps.location !== this.props.location && this.props.location?.pathname.endsWith("/treeEditor") && this.props.tree.selectedId) {
            this.props.dispatchers.triggerEditorSubmit({ itemId: this.props.tree.selectedId, prevent: false }, this.settingsEntityEditorFormSimple.current);
        }
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        if (this.props.entity?.id === undefined) {
            return { icon: "edit", content: _msg("SettingsEntityEditorPage.editor"), };
        }
        return super.getMainMenuItemProps();
    }

    private getSteps(): WizardStep[] {
        const props = this.props;

        return [{
            title: _msg("SettingsEntityEditorPage.typeSelection"),
            icon: "setting",
            render: () => <>
                <Search id='searchBar' data-cy='settingsSearchBar' input={{ icon: 'search' }} placeholder={_msg("SettingsEntityEditorPage.selectTypePlaceholder")}
                    showNoResults={false}
                    value={props.newSettingSearchedText}
                    onSearchChange={(event: any, { value }: SearchProps) => {
                        props.dispatchers.setInReduxState({ newSettingSearchedText: value, entity: { ...props.entity, rootType: undefined } })
                    }}
                />
                <SegmentGroup>
                    {this.props.newSettingAvailableTypes
                        .filter(type => this.props.newSettingSearchedText === "" ? true : type.toLowerCase().includes(this.props.newSettingSearchedText.toLowerCase()))
                        .sort((a, b) => a < b ? -1 : a > b ? 1 : 0)
                        .map(type => (
                            <Segment size="large" key={type} data-cy={"settingsSegment" + type}
                                className={props.entity.rootType === type ? "selectedItem" : undefined}
                                onClick={() => props.dispatchers.setInReduxState({ entity: { ...props.entity, rootType: type, enabled: true } })}>
                                <Icon name="setting" />{type}
                            </Segment>))}
                </SegmentGroup>
                <Message data-cy='settingsMsg' negative style={this.props.entity.rootType ? { display: "none" } : {}}>
                    <Message.Content>{_msg("SettingsEntityEditorPage.selectTypeMessage")}</Message.Content>
                </Message></>,
            onNextClick: () => {
                return this.props.entity.rootType;
            },
            nextDisabled: () => !AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.isAdmin
        },
        {
            title: _msg("SettingsEntityEditorPage.form"),
            icon: "setting",
            render: () => {
                return <EntityEditorFormSimple
                    ref={this.entityEditorForm} entity={props.entity} hideButtonBar
                    entityDescriptor={props.dispatchers.getSlice().entityDescriptor}
                    onSubmitHandler={(values) => {
                        props.dispatchers.setInReduxState({ entity: values });
                        if (props.descriptor?.name !== props.entity.rootType) {
                            props.dispatchers.getDescriptor(props.entity.rootType);
                        }
                    }}
                />
            },
            onNextClick: () => {
                this.entityEditorForm.current?.submit();
                return true;
            }
        },
        {
            title: _msg("SettingsEntityEditorPage.configuration"),
            description: props.entity.organization && organizationEntityDescriptor.toMiniString(props.entity.organization),
            icon: "edit",
            render: () => { return this.renderTreeEditor() },
            onPreviousClick: () => {
                this.props.tree.selectedId && this.props.dispatchers.triggerEditorSubmit({ itemId: this.props.tree.selectedId, prevent: false }, this.settingsEntityEditorFormSimple.current);
                return true;
            }
        }]
    };

    protected renderMain() {
        const props = this.props;
        if (props.entity && !props.entity.id) {
            // use EntityTablePage class because when the lable in the tree is too long, the EntityEditorPage
            // class would have forced a fixed width while this is flexible (enlarges)
            return <Container className="EntityTablePage_container" fluid>
                <Segment className="EntityTablePage_segment">
                    <WizardRRC id="settingsWizard"
                        steps={this.getSteps()}
                        onFinishClick={this.onSave}
                        onCancelClick={() => props.dispatchers.dispatch(push(props.dispatchers.getSlice().entityDescriptor.getEntityTableUrl()))} />
                </Segment>
            </Container>
        }
        return super.renderMain();
    }

    protected getExtraTabPanes() {
        const props = this.props;
        const { entityDescriptor } = props.dispatchers.getSlice();

        return props.entity?.id && [{
            routeProps: { path: "/treeEditor" },
            menuItemProps: { icon: "edit", content: _msg("SettingsEntityEditorPage.editor") },
            render: () =>
                <Container fluid>
                    <Segment className="EntityTablePage_segment">
                        <Segment className="buttonBar">
                            <Button primary type="submit" disabled={!AppMetaTempGlobals.appMetaInstance.getCurrentUser()?.isAdmin} onClick={this.onSave}>{_msg("general.submit")}</Button>
                            <Button as={Link} to={entityDescriptor.getEntityTableUrl()} secondary>{_msg("general.cancel")}</Button>
                        </Segment>
                        <Header as="h2" dividing>                            
                            {typeof entityDescriptor.icon === 'string' ? <Icon name={entityDescriptor.icon} /> : entityDescriptor.icon}
                            <Header.Content>
                                {entityDescriptor.toMiniString(props.entity)}
                                <Header.Subheader>{props.entity.organization && organizationEntityDescriptor.toMiniString(props.entity.organization)}</Header.Subheader>
                            </Header.Content>                            
                        </Header>     
                        {this.renderTreeEditor()}                   
                        {props.currentUser.isAdmin ? <Segment compact className="flex-container-row flex-center gap5">
                            <TextArea className="wh100" rows={5} value={props.rawJson} onChange={(ev, data) =>  props.dispatchers.setInReduxState({ rawJson: data.value as string })} />
                            <Button onClick={() => props.dispatchers.setInReduxState({ settingObject: JSON.parse(props.rawJson) })}>{_msg("general.apply")} {entityDescriptor.getField("json").getLabel()}</Button>
                        </Segment> : <></>  }  
                    </Segment>
                </Container>
        }];
    }

    private renderItem = (params: RenderItemParams) => {
        const props = this.props;

        let itemId = Utils.substringAfter(params.linearizedItem.itemId, Utils.defaultIdSeparator);

        const field = props.dispatchers.getField(itemId.split(Utils.defaultIdSeparator), props.descriptor);

        if (!field && itemId !== TREE_ROOT_ID) {
            return null;
        } 

        const object = itemId === TREE_ROOT_ID ? params.props.root : Utils.navigate(params.props.root, itemId);
        if (itemId === TREE_ROOT_ID) {
            return <><Label circular color="blue" >{(object.type as string)[0].toUpperCase()}</Label>{object.type}
                <Checkbox toggle className="SettingsEntityEditor_treeItem_floatRight"
                    checked={props.entity.enabled}
                    onChange={(event) => {
                        event.stopPropagation();
                        props.dispatchers.setInReduxState({ entity: { ...props.entity, enabled: !props.entity.enabled } });
                    }} /></>
        }
        const lastTokenId = Utils.substringAfter(itemId, Utils.defaultIdSeparator, true);
        if (itemId === lastTokenId && !props.descriptor?.fields?.map(field => field.name).includes(itemId)) {
            return <></>;
        }
        if (object instanceof Array) {
            return <>{lastTokenId}<Button icon className="SettingsEntityEditor_treeItem_floatRight" primary
                onClick={(event) => {
                    event.stopPropagation();
                    const values = (this.settingsEntityEditorFormSimple?.current?.formik?.current as any)?.values;
                    this.props.dispatchers.updateItem({ itemId: this.props.tree.selectedId!, values: values });
                    props.dispatchers.addTreeItem(params.linearizedItem.itemId);
                }}>
                <Icon name="add" /></Button></>
        }

        // else list item, get type from corresponding descriptor

        let fieldDescriptor = field?.descriptor;

        const subfields = Utils.navigate(this.props.settingObject, itemId, false);

        if (subfields) {
            const subtypes = getSubtypesFor(fieldDescriptor);
            const filteredSubtypes = subtypes.filter((subtype: any) => subtype.name === subfields.type);
            if (filteredSubtypes.length > 0) {
                fieldDescriptor = filteredSubtypes[0];
            }
        }

        return <><Label circular color="orange" className={'SettingsEntityEditor_treeItem_floatleft'}>{(fieldDescriptor?.name)[0].toUpperCase() + (parseInt(lastTokenId) + 1)}</Label>
            {(subfields && fieldDescriptor?.contributorsForLabel?.length > 0 ?
                (fieldDescriptor?.contributorsForLabel.map((c: string) => subfields[c]).join(', ') + ': ')
                : '') + fieldDescriptor?.name}
            <div className="flex-container-row SettingsEntityEditor_treeItem_floatRight">
                <Button icon
                    onClick={(event) => {
                        event.stopPropagation();
                        props.dispatchers.changeTreeItemIndexInList(params.linearizedItem.itemId, true);                      
                    }}>
                    <Icon name="arrow up" />
                </Button>
                <Button icon className="SettingsEntityEditor_treeItem_floatRight"
                    onClick={(event) => {
                        event.stopPropagation();
                        props.dispatchers.changeTreeItemIndexInList(params.linearizedItem.itemId, false);                       
                    }}>
                    <Icon name="arrow down" />
                </Button>
                <Button icon className="SettingsEntityEditor_treeItem_floatRight" negative
                    onClick={(event) => {
                        event.stopPropagation();
                        props.dispatchers.removeTreeItem(params.linearizedItem.itemId);
                    }}>
                    <Icon name="minus" />
                </Button>
            </div>

        </>;
    }

    private getStyleItemWrapperProps = ({ props, linearizedItem }: RenderItemParams) => {
        const ids = linearizedItem.itemId.split(Utils.defaultIdSeparator);

        const style = {};

        // in case we have a very long word (settings are such a word)
        // just force them to wrap if they are bigger than the allowed space
        Object.assign(style, { wordBreak: "break-word" })

        ids.length !== 1 && Object.assign(style, { paddingTop: "5px", paddingBottom: "5px" });

        ids.length === 1 && !this.props.entity.enabled && Object.assign(style, { background: "rgba(255, 0, 0, 0.10)" });
        return style;
    }

    private renderEditorPure() {
        const props = this.props;

        const treeSelectedId = props.tree?.selectedId;
        if (!treeSelectedId) {
            return null;
        }

        let itemId = Utils.substringAfter(treeSelectedId, Utils.defaultIdSeparator);
        const entity = itemId === TREE_ROOT_ID ? props.settingObject : Utils.navigate(props.settingObject, itemId);

        // get selected item descriptor; if list, no descriptor, else search in fieldDto
        let selectedDescriptor: any = undefined;
        if (!(entity instanceof Array)) {
            selectedDescriptor = itemId === TREE_ROOT_ID ? props.descriptor : this.props.dispatchers.getField(itemId.split(Utils.defaultIdSeparator), props.descriptor)?.descriptor;
        }

        // create dummy entityDescriptor
        const entityDescriptor: EntityDescriptor = new EntityDescriptor({
            name: selectedDescriptor ? selectedDescriptor?.name : "dummy",
            icon: "setting",
            miniFields: ["name"]
        });

        if (entity) {
            const subtypes = getSubtypesFor(selectedDescriptor);
            const filteredSubtypes = subtypes.filter((subtype: any) => subtype.name === entity.type);
            if (filteredSubtypes.length > 0) {
                selectedDescriptor = {...selectedDescriptor, ...{fields: selectedDescriptor.fields.concat(filteredSubtypes[0].fields)}};
            }
            subtypes.length > 0 && entityDescriptor.addFieldDescriptor(subtypeFieldEditor(subtypes, filteredSubtypes.length > 0, (type: string) => {
                const filtered = subtypes.filter((subtype: any) => subtype.name === type);
                if (filtered.length > 0) {
                    const selectedSubtype = filtered[0];
                    const fieldsToInit = selectedSubtype.fields.filter((field: SettingsFieldDescriptorDto) => field.type === "list");
                    const values: {[key: string]: any} = { type: type };
                    fieldsToInit.forEach((field: SettingsFieldDescriptorDto) => {
                        values[field.name] = [];
                    });
                    props.dispatchers.updateItem({ itemId: props.tree.selectedId!, values: values });
                }
                props.dispatchers.setInReduxState({currentEditorTreeItemId: props.tree.selectedId!});
                this.settingsEntityEditorFormSimple.current?.submit()
            }));
        }

        selectedDescriptor?.fields?.map((field: SettingsFieldDescriptorDto | null) => {
            if (field && field.type !== FIELD_LIST_TYPE) {
                // We need to copy the field object before assigning it a new type because of: 
                // TypeError: Cannot assign to read only property 'type'
                field = { ...field };

                // Before adding the field descriptor, try map the its type to the known field types 
                field.type = graphQlToFieldTypeMapping[field.type] as string || field.type;
                entityDescriptor.addFieldDescriptor(field);
            }
        });

        return (
            <Segment size="small" className="SettingsEntityEditorPage_editorPure">
                <SettingsEntityEditorFormSimple ref={this.settingsEntityEditorFormSimple}
                    entityDescriptor={entityDescriptor} entity={entity}
                    onSubmitHandler={(values) => this.props.dispatchers.editorSubmitHandler(treeSelectedId, values)} // testState5b -> testState5c
                />
            </Segment>)
    }

    private renderTreeEditor() {
        const props = this.props;
        (props.dispatchers.tree.getSlice() as SliceSettingsTree).rootType = props.entity.rootType;
        return props.descriptor && (
            <Grid padded columns='equal' >
                <Grid.Column key={1} >
                    <Tree {...props.tree} dispatchers={props.dispatchers.tree}
                        renderItemFunction={this.renderItem}
                        styleItemWrapperFunction={this.getStyleItemWrapperProps}
                        onSelectItem={(params: OnSelectParams) => { props.dispatchers.triggerEditorSubmit(params, this.settingsEntityEditorFormSimple.current) }}
                    />
                </Grid.Column>
                <Grid.Column key={2}>{this.renderEditorPure()}</Grid.Column>
            </Grid>);
    }
});

class SettingsEntityEditorFormSimple extends EntityEditorFormSimple {

    protected getScriptableUiId() {
        // We need a fixed id for this editor because we have different types of settings and entityDescriptor
        // would be changed on a click on a setting from tree => an error for changing ScriptableUi id
        return "SettingsEntityEditorFormSimple";
    }

    renderFieldEditor(field: string, formikProps: FormikProps<any>) {
        return <>
            {super.renderFieldEditor(field, formikProps)}
            <Grid.Column width={3} textAlign="center" className="EntityEditorPage_grid_row_column">
                <Form.Field key={"ov" + field} data-cy={"entityGridOv" + field}><label style={{ visibility: "hidden" }}>{_msg("SettingsEntityEditorPage.override")}</label><Checkbox /></Form.Field>
            </Grid.Column>
        </>
    }

    renderButtonBar(s: ScriptableUiCrudForm.Main, formikProps: FormikProps<any>) {
        return null;
    }

    renderHeaderEditor(formikProps: FormikProps<any>) {
        return <Grid.Row key="header" className="EntityEditorPage_grid_row">
            <Grid.Column className="EntityEditorPage_grid_header_column"></Grid.Column>
            <Grid.Column width={3} textAlign="center" className="EntityEditorPage_grid_header_column"><Form.Field label={_msg("SettingsEntityEditorPage.override")} /></Grid.Column>
        </Grid.Row>
    }
}

function getSubtypesFor(fd: FieldDescriptor) {
    if (!fd?.subtypes) {
        return [];
    }
    let subtypes: any[] = [];
    fd.subtypes.forEach((subtype: any) => {
        subtypes.push(subtype);
        subtypes = subtypes.concat(getSubtypesFor(subtype));
    });
    return subtypes;
}
"../../apollo-gen-foundation/SettingsEntityDescriptorDto""../../apollo-gen-foundation/settingsEntityService_getDescriptor""../../apollo-gen-foundation/settingsEntityService_getSettingsRootTypes""../../apollo-gen-foundation/SettingsFieldDescriptorDto""../../apolloClient""../../AppMetaTempGlobals""../../components/TreeNew/Tree""../../components/Wizard/Wizard""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityEditorFormSimple""../../entity_crud/EntityEditorPage""../../entity_crud/fieldRenderersEditors""../../entity_crud/FieldType""./queries""./settingsEntityDescriptor""../../reduxHelpers""../../utils/Utils""../../entity_crud/fieldEditors/FieldEditor"