import { AnyAction, createSlice, PayloadAction, Reducer, Slice } from '@reduxjs/toolkit';
import { Location } from "history";
import React from "react";
import { connect, ReactReduxContext } from "react-redux";
import { match } from "react-router";
import { TestState } from "./utils/TestState";
import { Utils } from "./utils/Utils";
import { AppMetaTempGlobals } from './AppMetaTempGlobals';
import { AppRouteProps, PrivateRoute, PrivateRouteProps } from '@crispico/foundation-react/reduxHelpers';
import { User } from '@crispico/foundation-react/AppMeta';

//#region  Area for type manipulation magic 

// Initial types, meant for CompMeta
type ExtractPayload<T> = T extends PayloadAction<infer R> ? R : any;
type ReducerFunction = (state: any, action: PayloadAction<any>) => any;
type ExtractSecondParam<T extends ReducerFunction> = Parameters<T>[1];
type ReducerFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends ReducerFunction ? K : never }[keyof T];
type ReducerFunctionProperties<T> = Pick<T, ReducerFunctionPropertyNames<T>>;

type ActionsCreators0<T> = {
    [K in keyof T]: T[K] extends ReducerFunction ? (p: ExtractPayload<ExtractSecondParam<T[K]>>) => PayloadAction : never;
}
export type ActionsCreators<T> = ActionsCreators0<ReducerFunctionProperties<T>>;

// 


// Other maybe reusable types; maybe the ones above may reuse this?

// This type is on purpose broken in 3 steps; for documentation purposes; I'd use only 2 steps; 
// I wouldn't use only 1 step, although it's possible; it would be harder to read than a RegEx
// e.g. ExcludeFieldsOfType<MyType, Function>; for MyType = { a: number, b: number, c: (param: number) => string }, we want to exclude all fields of type Function
type OmitFieldsOfType0<T, C> = { [K in keyof T]: T[K] extends C ? never : K }; // { a: a, b: b, c: never }
// some examples of indexing: MyType["a"] = number; MyType["a" | "b" | "c"] = number | (...) => ...]; NOTE: the result is an union; hence number = only once
type OmitFieldsOfType1<T> = T[keyof T]; // keyof T = "a" | "b" | "c"; the result = "a" | "b" | never; however never seems NOT to be included, being special; so result = "a" | "b"
export type OmitFieldsOfType<T, C> = Pick<T, OmitFieldsOfType1<OmitFieldsOfType0<T, C>>>; // cf. doc, extracts field + type IF the field (key) is among the param; in our case only a and b
export type OmitFieldsOfTypeFunction<T> = OmitFieldsOfType<T, Function>;

type IncludeOnlyFieldsOfType0<T, C> = { [K in keyof T]: T[K] extends C ? K : never }; // { a: a, b: b, c: never }
export type IncludeOnlyFieldsOfType<T, C> = Pick<T, OmitFieldsOfType1<IncludeOnlyFieldsOfType0<T, C>>>;
export type IncludeOnlyFieldsOfTypeFunction<T> = IncludeOnlyFieldsOfType<T, Function>;

//#endregion

export type DispatchFunction = (action: { type: string } | Function) => any;
export type PropsFromState<S> = S & {
    dispatch: DispatchFunction,
    match?: match<any>,
    location?: Location
};

export type Optional<T> = T | undefined | null;

export abstract class AbstractReducers<STATE> {

    setState(state: STATE, action: PayloadAction<Partial<STATE>>) {
        for (let k in action.payload) {
            (state as any)[k] = (action.payload as any)[k];
        }
    }
}

export abstract class AbstractThunksCreators<REDUCERS> {
    constructor(protected actionsCreators: ActionsCreators<REDUCERS>) { }
}

/**
 * @author Cristian Spiescu
 */
export abstract class CompMeta<REDUCERS = any, THUNKS_FACTORY = any, COMPONENT = any> {

    /**
     * Used to navigate from the root state. E.g. [ "a", "b" ]. So when the root state is received, we navigate "state.a.b".
     */
    protected keysFromRootState: string[];
    routeExpression!: string;
    extraReducers: any;
    protected slice!: Slice;
    componentMounted = false;
    protected nestedMetas: CompMeta<any, any>[] = [];
    protected _wrappedComponent: any;
    reducersInstance!: REDUCERS;
    protected thunksCreatorsInstance!: THUNKS_FACTORY;
    protected componentClass!: new (...args: any) => COMPONENT;

    /**
     * A failsafe for the nested scenario. There is a time period where 'createWrappedComponent()' shouldn't be called.
     */
    protected beingAddedAsNested = false;

    /**
     * Must be explicitly activated (w/ 'setForwardRef()' called in constructor). Not default for all.
     * I'm affraid that this may cause memory leaks? Or may lead to components
     * more difficult to garbage collect? Anyway: just a fear; I didn't properly investigate.
     */
    componentRef?: React.RefObject<any>;

    constructor(protected initialState: any, protected reducersClass: any, protected thunksCreatorsClass: any, keyInState: string) {
        // by default we consider this is used as a page; so only one level
        // if it will be used as nested, this will change
        this.keysFromRootState = [keyInState];
    }

    protected setForwardRef() {
        this.componentRef = React.createRef();
    }

    toString() {
        return "CompMeta {" + this.keysFromRootState + "}";
    }

    /**
     * Recursive function, so that we can copy functions from all the prototypes in the chain.
     */
    public static copyFunctionsFromPrototypes(dest: any, instance: any, crt: any): any {
        if (!crt) {
            throw new Error("Illegal state; iteration didn't halt on the prototype of Object for: " + instance);
        }
        const prot = Object.getPrototypeOf(crt);
        if (prot === Object.prototype) {
            // not interested in members from "Object"; let's halt here
            return dest;
        }

        Object.getOwnPropertyNames(prot).forEach(p => {
            if (p === "constructor" // skip the constructor
                || typeof prot[p] !== "function" // skip if not function; I think we're talking about static fields
                || p.startsWith("_")) { // skip if "_myFunction"; we immitate a private function
                return;
            }
            if (dest[p]) {
                // function already copied from a subclass; this is an ancestor function; skip it
                return;
            }
            // attach the function to "this"; normally the reducer don't use fields from it's class; but let's not forbit this / break the OOP contract
            dest[p] = prot[p].bind(instance);
        });
        return this.copyFunctionsFromPrototypes(dest, instance, prot);
    }

    public getOrCreateInitialState() {
        // needed in order to populate w/ initialStates from nested components
        this.getOrCreateSlice();
        return this.initialState;
    }

    /**
     * This creates the 'slice' object which has 2 iteresting items: 
     * 1) the 'actions'. It is given as param to 'createComponent()'. Each action is wrapped with logic powered by the 'immer' library, which allows
     *  us to write "mutative" logic. I.e. the state tree is actually proxified, and each modification triggers an "copy on write" mechanism that creates
     *  new instaces.
     * 2) the 'reducer'. We use it actually only when the component is used as a page, i.e. accesed from a route. In this case, from the route app
     *  we call 'contributeRootReducer()' which returns the 'reducer'. When this is used as a nested 'Meta', we don't use the reducer.
     */
    getOrCreateSlice() {
        if (!this.slice) {
            if (!this.reducersClass || !this.initialState) {
                throw new Error("Please provide a 'reducersClass', an 'initialState'");
            }

            this.nestedMetas.forEach(n => this.processNestedMeta(n));

            // as already mentioned: normally the reducer don't use fields from it's class; but let's not forbid this / break the OOP contract
            this.reducersInstance = new this.reducersClass();
            // we copy the functions from the instance (i.e. from it's prototype) into a normal object; this is
            // the format expected by 'createSlice()
            const copiedFunctions = CompMeta.copyFunctionsFromPrototypes({}, this.reducersInstance, this.reducersInstance);
            this.slice = createSlice({
                name: this.keysFromRootState.toString(),
                initialState: this.initialState,
                reducers: copiedFunctions,
                extraReducers: this.extraReducers
            } as any);
        }
        return this.slice;
    }

    /**
     * 1) 'nestedMeta.keysFromRootState' is prepended w/ the one from this. This is done before going deeper, i.e. creating the slice of 'nestedMeta'.
     * 
     * 2) 'initialState' is enriched w/ the one from 'nestedMeta'.
     * 
     * 3) 'extraReducers' is populated from 2 sources: a) 'extraReducers' from 'nestedMeta'. b) 'caseReducers' from 'nestedMeta.slice'.
     * 
     * Note that the slice of a nested meta IS created. We need it for b). We use it to get the 'caseReducers'. But the slice is not used (planned for optimization in #22374)! These 'caseReducers'
     * end up (wrapped) in 'extraReducers'. If this is a "root" meta, then when the slice is created, the guys from b) are used. Otherwise, if this is a nested
     * meta (i.e. multi level nesting / recursive) => the guys from b) which are in 'extraReducers' will end up in 'extraReducers' of the parent cf. a). 
     */
    protected processNestedMeta(nestedMeta: CompMeta<any, any>) {
        const key = nestedMeta.getKeyInState();
        this.initialState[key] = nestedMeta.initialState;
        // e.g. in 'this' we may have: [ 'a', 'b' ]. Nested may become: [ 'a', 'b', 'c' ]
        nestedMeta.keysFromRootState = [...this.keysFromRootState, key];


        // below we have 2 modifications to the original caseReducers
        const caseReducers = nestedMeta.getOrCreateSlice().caseReducers;
        if (!this.extraReducers) { // may be non-null if the user has specified some custom extraReducers
            this.extraReducers = {};
        }
        if (nestedMeta.extraReducers) {
            Object.assign(this.extraReducers, nestedMeta.extraReducers);
        }
        for (let k in caseReducers) {
            const caseReducer = caseReducers[k] as Reducer;
            // #1: the key will be something like: 'a,b/myAction' for an action called 'myAction'
            const actionType = nestedMeta.keysFromRootState.toString() + "/" + k;
            this.extraReducers[actionType] = (state: any, action: AnyAction) => {
                // #2: we wrap the original function, because we need to navigate deeper
                // e.g. keysFromRootState = Map, MyNestedComp, MyNestedNestedComp; reminder: the redux tools slice that is used is the one
                // for MapPageMeta. And then, all page metas are "combined" w/ "combineReducers()", which navigates the first level
                const localState = Utils.navigate(state, nestedMeta.keysFromRootState.slice(1));
                return caseReducer(localState, action);
            }
        }
        nestedMeta.beingAddedAsNested = false;
    }

    addNestedMeta<T extends CompMeta<any, any>>(nestedMeta: T) {
        if (this.slice) {
            throw new Error("'addNestedMeta()' called too late. This should be called from field initializers or constructors.");
        }
        nestedMeta.beingAddedAsNested = true;
        this.nestedMetas.push(nestedMeta);
        return nestedMeta as T;
    }

    protected connectStateToProps(state: any, ownProperties?: any) {
        const props: any = {};
        if (!this.reducersClass) {
            return props;
        }

        // particular case for the router; may change in the future
        // it's not very clear (for us) what exactly ownProperties means; we see, sometimes, additional props; such as: history, staticContext
        // the initial thought would have been to copy all ownProperties
        if (ownProperties && ownProperties.location) {
            props["location"] = ownProperties.location;
        }
        if (ownProperties && ownProperties.match) {
            props["match"] = ownProperties.match;
        }

        const slice = this.navigateToSlice(state);
        for (let key in this.initialState) {
            if (!slice.hasOwnProperty(key)) {
                continue;
            }
            props[key] = slice[key];
        }
        return props;
    }

    navigateToSlice(bigState: any) {
        return Utils.navigate(bigState, this.keysFromRootState);
    }

    /**
     * If nested, this is the last key.
     */
    getKeyInState() {
        return this.keysFromRootState[this.keysFromRootState.length - 1];
    }

    getActionsFactory(): ActionsCreators<REDUCERS> {
        return (this.reducersClass && this.getOrCreateSlice().actions) as any;
    }

    getThunksCreators(): THUNKS_FACTORY {
        if (!this.thunksCreatorsInstance) {
            this.thunksCreatorsInstance = new this.thunksCreatorsClass(this.getActionsFactory());
        }
        return this.thunksCreatorsInstance;
    }

    /**
     * Creates a HOC. A double HOC in fact. First HOC = connected (to Redux) component, via "connect()".
     * And second HOC (that wraps this) captures/updates the "mounted" state, and stores it in this instance.
     * 
     * This calls 'createComponent()' and passes 'actions' from the 'getOrCreateSlice()'.
     */
    protected createWrappedComponent(): any {
        if (this.beingAddedAsNested) {
            throw new Error(this + " is currently being added as a nested 'Meta', but the init hasn't finished. I.e. you called this method too early.");
        }

        let actionsFactory: any = this.getActionsFactory();
        let thunksCreators = this.thunksCreatorsClass && this.getThunksCreators();
        const ConnectedComponent = connect(this.connectStateToProps.bind(this), null, null, { forwardRef: true })(this.createComponent(actionsFactory, thunksCreators));
        const meta = this;

        // using a class component instead of a functional component because we want to set "mounted" before mount (i.e. constructor). This is because,
        // during mount, components may do redux stuff, hence the reducers should work. In a functional component, using 'useEffect()'
        // hook, the earliest notification is when the component has already been mounted. Which is too late for components that would
        // have ran reducers.
        class Wrapper extends React.Component {

            static contextType = ReactReduxContext;

            constructor(props: any) {
                super(props);
                // cf. comment above
                meta.componentMounted = true;
            }

            componentDidMount() {
                // however, this action should be emmited after the real mount; at least for the following reason:
                // if the test state contains a function, the component should have been mounted in order to access it
                if (meta.keysFromRootState.length === 1) {
                    this.context.store.dispatch({ type: "page-mounted" });
                }
            }

            componentWillUnmount() {
                meta.componentMounted = false;
            }

            render() {
                let newProps: any = this.props;
                if (meta.componentRef) {
                    newProps = { ...this.props, ref: meta.componentRef };
                }
                return React.createElement(ConnectedComponent, newProps);
            }

        }

        return Wrapper;
    }

    /**
     * As a getter so that it can be used directly in JSX.
     */
    get wrappedComponent(): new () => COMPONENT {
        if (!this._wrappedComponent) {
            this._wrappedComponent = this.createWrappedComponent();
        }
        return this._wrappedComponent;
    }

    createRoute(computeRoute: (props: PrivateRouteProps) => JSX.Element) {
        const route = this.routeExpression ? this.routeExpression : "/" + this.getKeyInState();
        return (<PrivateRoute key={route} path={route} component={this.createWrappedComponent()} computeRoute={computeRoute} />)
    }

    private getTestState(location: Location) {
        const stateName = location.hash.substring(TestState.TEST_STATE.length);
        const states = TestState.statesForAll.get(this);
        const testState = states && states[stateName];
        if (!testState) {
            throw new Error("For page: " + this + " trying to load a nonexistent test state: " + stateName);
        }
        if (testState.func) {
            // invoke later; because right now we are in the middle of reducing; and the function may want to do dispatch, which is illegal right now
            setTimeout(() => {
                if (!this.componentRef) {
                    // disabled for the moment; 
                    // throw new Error("You are using testState mode + function; hence you need to initialize 'componentRef' (by calling 'setForwardRef()') and make sure that 'createComponent()' returns a class component; not a functional component.");
                    testState.func!();
                } else {
                    testState.func!(this.componentRef.current);
                }
            });
        }
        return testState.state;
    }

    static isTestStateMode() {
        return AppMetaTempGlobals.history && // since the introduction of Storybook
            AppMetaTempGlobals.history.location.hash.startsWith(TestState.TEST_STATE);;
    }

    /**
     * Used only when the 'Meta' is used as a page, with route. Not for the case when it is used as a nested 'Meta' in another
     * 'Meta' (which may be a page or a component as well). 
     * 
     * Contributes the reducer of this slice. In fact the slice reducer is wrapped by another reducer with a bit of additional
     * logic: 
     * 
     * 1) if URL is in "testState" mode => the reducer is not invoked; the "preset" state is returned from the TestState registry.
     * 2) if the component is not mounted (i.e. sleeping) => the reducer is not invoked; the old state is returned.
     */
    contributeRootReducer(rootReducerCombination: any) {
        if (!this.reducersClass) {
            // no slice => the page doesn't use redux
            return;
        }
        rootReducerCombination[this.getKeyInState()] = (state: any, action: any) => {
            // at each action dispatch, this function gets called for all slices from the big state
            if (!this.componentMounted && state) {
                // if current page not mounted (visible) => don't invoke it's reducer; just return the previous value
                // however, do this only after the state has been initialized. So if state == undefined => we let the program flow. This will
                // initialize all the slices. I think this is correct; anyway: if we don't do this, redux complains.

                return this.initialState;

                // return state;
                // regarding the above:
                // some screens didn't expect that on a new display (e.g. for another SettingsEntity): some fields in state are already populated
                // 1) other screens might have the same behavior
                // 2) the old mechanism, to cache the state is not actually that valuable/used
            } else if ((action.type === "@@router/LOCATION_CHANGE" // this happens when we are already on the page; i.e. component already mounted; e.g. "/myPage" -> "/myPage#testState=3" or "/myPage#testState=0" to "/myPage#testState=1"
                || action.type === "page-mounted") // this happens when we just open the URL of our app; or we chage page; we emmit this action somewhere above; we need this, because at the moment where "LOCATION_CHANGE" happens, all comps are unmounted (at least for the 'initial display' scenario)
                && CompMeta.isTestStateMode()) { // we take the location this way to work in the 2 cases above

                return this.getTestState(AppMetaTempGlobals.history.location);
            } else {
                return this.getOrCreateSlice().reducer(state, action);
            }
        }

    }

    protected createComponent(ac: ActionsCreators<REDUCERS>, tc: THUNKS_FACTORY): any {
        return (props: any) => React.createElement(this.componentClass as any, { ...props, helper: this });
    }

}"./reduxHelpers""./AppMeta"