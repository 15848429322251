import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { createSliceFoundation, DispatchersFrom, getBaseImpures, getBaseReducers, PropsFrom, RootReducerForPages, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY } from "@crispico/foundation-react/utils/Utils";
import gql from "graphql-tag";
import lodash from 'lodash';
import React from "react";
import { Button } from "semantic-ui-react";
import { PopupWithHelpTooltip } from "../semanticUiReactExt";
import { DEFAULT_COMPOSED_FILTER } from "./BlocklyEditorTab";
import { ClientCustomQuery } from "./ClientCustomQuery";
import { addCustomQueryToSessionStorage, CustomQueryDefinition, CustomQueryDropdown, sliceCustomQueryDropdown } from "./CustomQueryDropdown";
import { Filter } from "./Filter";
import { FilterAsText, FilterBar, sliceFilterBar } from "./FilterBar";
import { sliceSortBar, Sort, SortAsText, SortBar } from "./SortBar";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { match } from "react-router-dom";
import { Location } from "history";

export enum CUSTOM_QUERY_BAR_MODE {
    TABLE, EDITOR
}

export const sliceCustomQueryBar = createSliceFoundation(class SliceCustomQueryBar {

    saveMutation = gql(`mutation q($params: SaveParams_LongInput) { 
        customQueryService_save(params: $params) { ${ID} }
    }`);

    initialState = {
        customQuery: undefined as ClientCustomQuery | undefined
    }

    nestedSlices = {
        sortBar: sliceSortBar,
        filterBar: sliceFilterBar,
        customQueryDropdown: sliceCustomQueryDropdown
    }

    reducers = {
        ...getBaseReducers<SliceCustomQueryBar>(this),

        async setSortsInCustomQuery(state: StateFrom<SliceCustomQueryBar>) {
            let customQueryDefinition = state.customQuery!.customQueryDefinitionObject;
            let newCustomQueryDefinition: CustomQueryDefinition = { filter: customQueryDefinition.filter, sorts: lodash.cloneDeep(state.sortBar.sorts) };
            state.customQuery!.customQueryDefinitionObject = newCustomQueryDefinition;
            state.customQuery!.dirty = true;
            state.customQuery!.fromCrudSettings = false;
            addCustomQueryToSessionStorage(lodash.cloneDeep(state.customQuery!));
        },

        setFilterInCustomQuery(state: StateFrom<SliceCustomQueryBar>, filter: Filter) {
            let customQueryDefinition = state.customQuery!.customQueryDefinitionObject;
            let newCustomQueryDefinition: CustomQueryDefinition = { filter: filter, sorts: customQueryDefinition.sorts };
            state.customQuery!.customQueryDefinitionObject = newCustomQueryDefinition;
            state.customQuery!.dirty = true;
            state.customQuery!.fromCrudSettings = false;
            addCustomQueryToSessionStorage(lodash.cloneDeep(state.customQuery!));
        },

        updateCustomQuery(state: StateFrom<SliceCustomQueryBar>, customQuery: ClientCustomQuery) {
            state.customQuery = customQuery;
            let customQueryDefinition = customQuery.customQueryDefinitionObject;
            state.sortBar.sorts = customQueryDefinition.sorts ? customQueryDefinition.sorts : [];
            addCustomQueryToSessionStorage(customQuery);
        },

        apply(state: StateFrom<SliceCustomQueryBar>, customQuery: ClientCustomQuery) {
            let newCustomQueryDefinition: CustomQueryDefinition = lodash.cloneDeep(customQuery.customQueryDefinitionObject);
            state.sortBar.sorts = newCustomQueryDefinition.sorts
        },
    }

    impures = {
        ...getBaseImpures<SliceCustomQueryBar>(this),

        addSortFromColumnHeader(field: string) {
            const sortIndex = this.getState().sortBar.sorts.findIndex((s, index) => {
                return s.field === field;
            });
            if (sortIndex === -1) {
                let sorts = lodash.cloneDeep(this.getState().sortBar.sorts);
                if (this.getState().sortBar.sorts.length > 0) {
                    sorts[this.getState().sortBar.sorts.length - 1] = { field: field, direction: 'ASC' };
                    this.getDispatchers().sortBar.setInReduxState({ sorts: sorts })
                } else {
                    sorts.push({ field: field, direction: 'ASC' });
                    this.getDispatchers().sortBar.setInReduxState({ sorts: sorts })
                }
            } else {
                if (this.getState().sortBar.sorts[sortIndex].direction === 'ASC') {
                    this.getDispatchers().sortBar.toggleDirection(sortIndex)
                } else {
                    this.getDispatchers().sortBar.deleteSort(sortIndex, false);
                }
            }
            this.getDispatchers().setSortsInCustomQuery();
        },
    }
});

export interface CustomQueryBarProps {
    screen: string,
    entityName: string,
    applyColumnConfigFromCustomQuery?: (cq: ClientCustomQuery) => void,
    mode: CUSTOM_QUERY_BAR_MODE,
    sortDisabled?: boolean,
    onChangeSort?: () => void,
    onChangeFilter?: () => void,
    onChangeSortDirection?: () => void
}

export class CustomQueryBar extends React.Component<PropsFrom<typeof sliceCustomQueryBar> & CustomQueryBarProps> {

    componentDidUpdate(prevProps: PropsFrom<typeof sliceCustomQueryBar> & CustomQueryBarProps, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps && !lodash.isEqual(prevProps?.customQuery?.customQueryDefinitionObject.filter, this.props.customQuery?.customQueryDefinitionObject.filter)) {
            this.props.onChangeFilter && this.props.onChangeFilter();
        }
        if (prevProps && lodash.differenceBy(this.props.customQuery?.customQueryDefinitionObject.sorts, prevProps?.customQuery?.customQueryDefinitionObject.sorts!, "field").length > 0) {
            this.props.onChangeSort && this.props.onChangeSort();
        }
        if (prevProps && !lodash.isEqual(prevProps?.customQuery?.customQueryDefinitionObject.sorts, this.props.customQuery?.customQueryDefinitionObject.sorts) && lodash.differenceBy(this.props.customQuery?.customQueryDefinitionObject.sorts, prevProps?.customQuery?.customQueryDefinitionObject.sorts!, "field").length == 0) {
            this.props.onChangeSortDirection && this.props.onChangeSortDirection();
        }
    }

    private renderBlockly(filter?: Filter) {
        return <span>
            {!this.props.sortDisabled ? <div className="CustomQueryBar_div"><SortBar entityName={this.props.entityName} {...this.props.sortBar} dispatchers={this.props.dispatchers.sortBar} setSortsInCustomQuery={this.props.dispatchers.setSortsInCustomQuery} /></div> : null}
            <div className="CustomQueryBar_div"><FilterBar entityName={this.props.entityName} rootFilter={filter} {...this.props.filterBar} dispatchers={this.props.dispatchers.filterBar} setFilterInCustomQuery={this.props.dispatchers.setFilterInCustomQuery} /></div>
        </span>
    }

    private renderText(filter?: Filter) {
        return <span>
            {!this.props.sortDisabled ? <>
                <SortAsText entityName={this.props.entityName} sorts={this.props.sortBar.sorts} />
                <div className="tiny-margin-right" style={{display: "inline-block"}} />
            </> : null}
            <FilterAsText filter={filter} ed={entityDescriptors[this.props.entityName]} />
        </span>;
    }

    render() {
        const filter = this.props.customQuery?.customQueryDefinitionObject.filter;
        return <>
            <>{_msg("CustomQuery.label") + ":"}&nbsp;&nbsp;</>
            {this.props.mode === CUSTOM_QUERY_BAR_MODE.TABLE 
                ? <><CustomQueryDropdown {...this.props} customQuery={this.props.customQuery} updateCustomQuery={this.props.dispatchers.updateCustomQuery} apply={this.props.dispatchers.apply} {...this.props.customQueryDropdown} dispatchers={this.props.dispatchers.customQueryDropdown} mode={this.props.mode} /><span className="tiny-margin-right" /></> 
                : null}
            {this.props.mode === CUSTOM_QUERY_BAR_MODE.EDITOR ? this.renderBlockly(filter) : null}
            <PopupWithHelpTooltip tooltip={_msg("Filter.infoText")} />&nbsp;
            {this.renderText(filter)}
            {this.props.mode === CUSTOM_QUERY_BAR_MODE.TABLE 
                ? !lodash.isEqualWith(this.props.customQuery, entityDescriptors[this.props.entityName].getDefaultCustomQuery()) 
                    ? <><span className="tiny-margin-right" /><Button negative disabled={!AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY)} icon="remove" data-testid="CustomQueryBar_delete" size="mini" onClick={() => this.props.dispatchers.updateCustomQuery(entityDescriptors[this.props.entityName].getDefaultCustomQuery())} /></> 
                    : null 
                : null}
                <span><FilterBar hide={true} {...this.props.filterBar} dispatchers={this.props.dispatchers.filterBar} entityName={this.props.entityName} setFilterInCustomQuery={this.props.dispatchers.setFilterInCustomQuery} rootFilter={filter} /></span>
        </>   
    }
}"../../AppMetaTempGlobals""../../entity_crud/entityCrudConstants""../../reduxHelpers""../../utils/Utils""../../AppMeta"