import { createReducer, EnhancedStore, Store } from "@reduxjs/toolkit";
import React from "react";
import { connect, ReactReduxContext, ReactReduxContextValue } from "react-redux";
import { Route, RouteProps, Redirect, RouteComponentProps } from "react-router-dom";
import { DispatchersFrom, SliceFoundation } from ".";
import { Utils } from "../utils/Utils";
import { RootReducerForPages } from "./RootReducerForPages";
import { AppMeta, BigState } from "@crispico/foundation-react";
import lodash from "lodash";
import { User } from "@crispico/foundation-react/AppMeta";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { timeStamp } from "console";

export type AppRouteProps = RouteProps & {
    permission?: string,
    
    /**
     * Will always be rendered in a modal. A modal Modal or a modal Drawer
     */
    routeIsModal?: boolean,

    /**
     * Used by entity table + editor. If a modal route for e.g. Employee editor is ordered, and main page is Employee table => 
     * will open as Drawer. Otherwise, if main page is e.g. Organization table => will open as Modal.
     */
    routeEntityName?: string

    /**
     * Not used a lot; but I preferred to have it typed here, instead of using dynamic props.
     */
    homePageType?: "normal" | "personal";
};

export class ConnectedPageInfo {
    routeProps?: AppRouteProps;
    mapBigStateToProps?: (bigState: any, props: any) => void;

    constructor(public slice: SliceFoundation, public wrappedComponentClass: any, public sliceName?: string, public defaultProps?: any, public privateRoute?: boolean) {
    }
}

/**
 * We use this internally to have a bit of type checking, but not too much. Basically it represents the same 
 * thing as SliceFoundation.
 * 
 * UPDATE: not necessary to have 2 types any more; try to get rid of this
 */
interface SliceInternal {
    initialState: any;
    reducers?: { [key: string]: Function };
    impures?: { [key: string]: Function };
    nestedSlices?: { [key: string]: SliceInternal };
    onBeforeMergeByConnectedPageHelper?: Function;

    // if exists => the slice is not a slice; it's a holder that knows how to create a slice
    // in this class, we call this function to replace the holder w/ the actual slice
    getActualSlice?: () => SliceInternal;
}

/**
 * Note: all the recursive logic related to "nested" is in this class; except the one for 
 * the initialState, which is calculated at slice creation.
 */
export class ConnectedPageHelper<SLICE = any> {
    static tempEnableSecurity = false;

    // from ConnectedPageInfo
    slice!: SliceInternal;
    protected wrappedComponentClass: any;
    sliceName!: string;
    protected defaultProps?: any;
    protected privateRoute?: boolean;
    routeProps?: RouteProps;
    mapBigStateToProps?: (bigState: any, props: any) => void;

    componentClass: any;
    reducer!: Function;
    protected rootReducer!: RootReducerForPages;
    dispatchers!: DispatchersFrom<SLICE>;
    initialStateMerged: any;

    /**
     * Must be explicitly activated (w/ 'setForwardRef()' called in constructor). Not default for all.
     * I'm affraid that this may cause memory leaks? Or may lead to components
     * more difficult to garbage collect? Anyway: just a fear; I didn't properly investigate.
     */
    componentRef?: React.RefObject<any>;

    constructor(info: ConnectedPageInfo) {
        if (!info.sliceName) { throw new Error("'sliceName' is null-ish. Please provide a value. 'sliceName' can be null only in Storybook mode, which is not the case.") }
        Object.assign(this, info);
    }

    initReducer() {
        if (this.reducer) { throw new Error("This function = 'initReducer()' cannot be called twice.") }

        if (this.slice.getActualSlice) {
            this.slice = this.slice.getActualSlice();
        }

        const mergedReducers = this.createMergedReducers({}, this.sliceName, [], this.slice, {});
        this.initialStateMerged = this.createInitialStateMerged(this.slice);
        this.reducer = createReducer(this.initialStateMerged, mergedReducers);
    }

    initHOCs(rootReducer: RootReducerForPages) {
        if (this.rootReducer) { throw new Error("This function = 'initHOCs()' cannot be called twice.") }

        this.rootReducer = rootReducer;
        const that = this;

        this.initReducer();
        this.initRouteProps();

        // HOC1: the connected component, powered by redux-react
        const ConnectedComponentClass = connect(this.mapStateToProps.bind(this), null, null, { forwardRef: true })(this.wrappedComponentClass);

        // HOC2: needed to inform RootReducerForPages of the mounted/unmounted state
        this.componentClass = class extends React.Component {

            static contextType = ReactReduxContext;

            constructor(props: any, context: ReactReduxContextValue) {
                super(props);
                if (!that.dispatchers) {
                    // this if, because maybe the component is unmounted/mounted again somehow
                    that.dispatchers = that.createMergedActionDispatchers(context.store);
                }
                that.rootReducer.onHelperMounted(that);

                // dispatching a dummy action, to force the invocation of the reducer,
                // which will "bootstrap" the corresponding state
                context.store.dispatch({ type: "page-mounted" });
            }

            componentWillUnmount() {
                that.rootReducer.onHelperUnmounted(that);
                // CS: I think the code below was from "sliceFoundation v1", which by dispatching a dummy
                // action, would have cleared automatically the sleeping keys. It's curious that w/ sliceFoundation v2,
                // we discovered after months and months that the sleeping keys were not removed. E.g. open editor, close editor
                // (state remains), reopen editor for another entity => initial render w/ old state + rerender w/ newer states
                // (loading, loaded, etc.). It's also curious that actionRemoveSleepingKeysFromRootState() was used only for 
                // ConnectedComponentInSimpleComponent.
//                // analogue as for mount,
//                // which will clear the portion of state corresponding to this component
//                setTimeout(() => this.context.store.dispatch({ type: "page-unmounted" }));
                setTimeout(() => this.context.store.dispatch(RootReducerForPages.actionRemoveSleepingKeysFromRootState([that.sliceName])));
            }

            render = () => {
                let newProps: any = { ...that.defaultProps, ...this.props, rootReducerForPages: that.rootReducer } // defaultProps may be undefined, but ... works OK for undefined
                if (that.componentRef) {
                    newProps.ref = that.componentRef;
                }
                return React.createElement(ConnectedComponentClass, newProps);
            }
        }

    }

    getRoute(computeRoute: (props:PrivateRouteProps) => JSX.Element) {
        return <PrivateRoute {...this.routeProps} privateRoute={this.privateRoute} component={this.componentClass} computeRoute={computeRoute}/>
    }

    protected mapStateToProps(state: any, ownProperties?: any) {
        const props: any = { dispatchers: this.dispatchers,
            currentUser: (state as BigState).AppContainer?.initializationsForClient?.currentUser,            
            currentOrganization: (state as BigState).AppContainer?.initializationsForClient?.currentOrganization,
            currentOrganizationToFilterBy: global.currentOrganizationToFilterBy };
        const stateSlice = state[this.sliceName];
        for (let key in this.initialStateMerged) {
            if (!stateSlice.hasOwnProperty(key)) {
                continue;
            }
            props[key] = stateSlice[key];
        }

        // particular case for the router; may change in the future
        // it's not very clear (for us) what exactly ownProperties means; we see, sometimes, additional props; such as: history, staticContext
        // the initial thought would have been to copy all ownProperties
        if (ownProperties && ownProperties.location) {
            props["location"] = ownProperties.location;
        }
        if (ownProperties && ownProperties.match) {
            props["match"] = ownProperties.match;
        }
        
        this.mapBigStateToProps?.(state, props);

        this.setPathInProps(this.sliceName, props, this.slice);

        return props;
    }

    protected setPathInProps(path: string, props: any, slice: SliceInternal) {
        props["__path"] = path;
        for (const child in slice.nestedSlices) {
            props[child] = { ...props[child] };
            this.setPathInProps(path + Utils.defaultIdSeparator + child, props[child], slice.nestedSlices[child]);
        }
    }

    protected getActionPrefix(prefix: string, path: Array<string>, suffix: string) {
        let mid = path.join("/");
        if (path.length > 0) {
            mid += "/";
        } // else for "" or we don't need 
        return prefix + "/" + mid + suffix;
    }

    protected createInitialStateMerged(slice: SliceFoundation) {
        const result = slice.initialState ? lodash.cloneDeep(slice.initialState) : {};
        for (let subName in slice.nestedSlices) {
            result[subName] = this.createInitialStateMerged(slice.nestedSlices[subName]);
        }
        return result;
    }

    protected createMergedReducers(destination: any, prefix: string, path: Array<string>, slice: SliceInternal, thisForReducers: any) {
        slice.onBeforeMergeByConnectedPageHelper?.();
        // this is "this" for reducers; i.e. used to acces neighbor functions
        for (let reducer in slice.reducers) {
            const f = slice.reducers![reducer];
            destination[this.getActionPrefix(prefix, path, reducer)] = function (state: any, action: any) {
                const s = Utils.navigate(state, path);
                f.apply(thisForReducers, [s, action.payload]);
            }
            thisForReducers[reducer] = f;
        }
        // this replaces the default function that throws an exception
        thisForReducers.getReducers = () => thisForReducers;

        for (let subName in slice.nestedSlices) {
            thisForReducers[subName] = {};
            if (slice.nestedSlices[subName].getActualSlice) {
                slice.nestedSlices[subName] = slice.nestedSlices[subName].getActualSlice!();
            }
            this.createMergedReducers(destination, prefix, [...path, subName], slice.nestedSlices[subName], thisForReducers[subName]);
        }
        return destination;
    }

    createMergedActionDispatchers(store: EnhancedStore) {
        return this.createMergedActionDispatchersInternal(null, [this.sliceName], this.slice, store);
    }

    // note: the recursion w/ path is slightly different/simpler compared to createMergedReducers(); 
    // I'm not sure this simplified version would work there as well
    protected createMergedActionDispatchersInternal(destination: any, path: Array<string>, slice: SliceInternal, store: EnhancedStore) {
        // this is "this" for impure dispatchers; i.e. used to access neighbor functions
        const result: any = destination || {};
        for (let reducer in slice.reducers) {
            result[reducer] = (p: any) => store.dispatch({ type: path.join("/") + "/" + reducer, payload: p })
        }

        for (let impureFunction in slice.impures) {
            const f = slice.impures![impureFunction];
            result[impureFunction] = f;
        }

        // if we want to dispatch normal actions, e.g. from other libs, such as redux-router
        result.dispatch = store.dispatch;

        result.getState = () => {
            return Utils.navigate(store.getState(), path);
        }

        // this replaces the default function that throws an exception
        result.getDispatchers = () => result;

        for (let subName in slice.nestedSlices) {
            result[subName] = this.createMergedActionDispatchersInternal(result[subName], [...path, subName], slice.nestedSlices[subName], store);
        }
        return result;
    }

    protected initRouteProps() {
        if (!this.routeProps) {
            this.routeProps = {};
        }

        if (!this.routeProps.path) {
            this.routeProps.path = "/" + this.sliceName;
        }
        (this.routeProps as any).key = this.routeProps.path;
    }

}

type ConnectedComponentInSimpleComponentProps = {
    info: ConnectedPageInfo,
    [key: string]: any
}

export class ConnectedComponentInSimpleComponent extends React.Component<ConnectedComponentInSimpleComponentProps> {

    static contextType = RootReducerForPages.Context;

    protected helper: ConnectedPageHelper;
    protected store!: Store;

    constructor(props: ConnectedComponentInSimpleComponentProps, context: React.ContextType<typeof RootReducerForPages.Context>) {
        super(props);
        this.helper = new ConnectedPageHelper(props.info);
        this.helper.initHOCs(context);
    }

    componentWillUnmount() {
        // deffer the call; the component is not yet unmounted; changing the state here will trigger a new
        // render() (I guess?) and a mapPropsToState() (I'm sure): which is bad
        setTimeout(() => this.store.dispatch(RootReducerForPages.actionRemoveSleepingKeysFromRootState([this.helper.sliceName])));
    }

    getDispatchers<SLICE>(): DispatchersFrom<SLICE> {
        return this.helper.dispatchers;
    }

    render() {
        return <>
            <ReactReduxContext.Consumer>
                {value => {
                    if (!this.store) { this.store = value.store; }
                    return null;
                }}
            </ReactReduxContext.Consumer>
            <this.helper.componentClass {...this.props} />
        </>;
    }
}

export type PrivateRouteProps = {
    computeRoute: (props: PrivateRouteProps) => JSX.Element,
    privateRoute?: boolean
} & AppRouteProps;
export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    return props.computeRoute.call(null, props);
};
"./..""../AppMeta""../CompMeta"