import { EntityDescriptor } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

export class DashboardCalculateForRecordsWidgetEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({name: "DashboardCalculateForRecordsWidget"});
    }

    protected customize() {
       this.addTabDescriptor({ oneToManyEntityName: "DashboardCalculateForRecordsValue", oneToManyOppositeField: "widget" })
    }
}

export class DashboardCalculateForRecordsValueEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({name: "DashboardCalculateForRecordsValue"});
    }

    protected customize() {
        this.addFieldDescriptor({ name: "records", type: FieldType.string, prepareValue: (value:string) => value.replace(/,(?=[^\s])/g, ", ") });
    }
}
"../..""../../entity_crud/FieldType"