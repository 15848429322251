import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ChainItem, FieldNameContentAssist, FieldNameContentAssistRRC, getMessageForField } from "@crispico/foundation-react/components/fieldNameContentAssist/FieldNameContentAssist";
import { SelectExt, SelectExtOption, SelectExtProps } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { FIELDS_READ } from "@crispico/foundation-react/utils/Utils";
import { HW, ScriptableUiHighlightWrapper, WithHW, castWithHw } from "@famiprog-foundation/scriptable-ui";
import { TestsAreDemoCheat } from "@famiprog-foundation/tests-are-demo";
import React from "react";
import { Button, Icon, Menu, Modal } from "semantic-ui-react";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "./FieldEditor";

export type EntityFieldsFieldEditorProps = FieldEditorNotUsableStandAloneProps & Partial<SelectExtProps> & {
    hideOptions?: boolean;
};

export class EntityFieldsFieldEditor<P extends EntityFieldsFieldEditorProps = EntityFieldsFieldEditorProps> extends FieldEditor<string, P> {

    fieldNameContentAssistRef = React.createRef<FieldNameContentAssist>();

    dropdownRef = React.createRef<any>();

    state = {
        contextMenuPosition: false as boolean | [number, number],
        fieldNameContentAssistOpen: false,
        selectionError: false,
        entityName: undefined as string | undefined,
        inputValue: ""
    }

    /**
     * Needed cf. the glitch described in `FieldDescriptor.castAdditionalFieldEditorProps()`.
     */
    constructor(props: P) {
        super(props);

        // We need to close some modals in this editor, where is necessary
        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                original.setFieldValue(value);
                if (this.state.contextMenuPosition) {
                    this.setState({ contextMenuPosition: false });
                }
                if (this.state.fieldNameContentAssistOpen) {
                    this.setState({ fieldNameContentAssistOpen: false });
                }
            }
        }));
    }

    componentDidUpdate(prevProps: P) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '')
        }
    }

    protected setComposedField = (composedField: string, value: any, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        if (!value.includes(composedField)) {
            s.setFieldValue(hw, (value.length > 1 && this.props.fieldDescriptor.allowMultiple ? value + ',' : '') + composedField)
        }
    }

    protected onSubmit = (options: any[], chain: ChainItem[], s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        if (chain.length === 0) {
            this.setState({ selectionError: true })
        } else {
            const composedField = chain.map(c => c.field).join('.')
            this.setComposedField(composedField, this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values), s, hw);
        }
    }

    protected addAll = (s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        s.setFieldValue(hw, this.getValueFromOptions(this.getOptions()))
    }

    removeAll = (s?: WithHW<ScriptableUiFieldEditor.Main>, hw?: ScriptableUiHighlightWrapper) => {
        if (s && hw) {
            s.setFieldValue(hw, this.getValueFromOptions([]));
            return;
        }
        // Kept this for old Tad for column config that uses getObjectViaCheatWaitable
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.getValueFromOptions([]))
    }

    protected getItemsFromValue(value: any): any[] {
        return value ? value.split(',') : [];
    }

    protected getValueFromOptions(items: SelectExtOption[]): any {
        return items.map(i => i.value).join(',');
    }

    protected getDropdownOptionFromItem(field: any, entityName: string): SelectExtOption {
        return { value: field, label: getMessageForField(field, entityName) }
    }

    protected getEntityName() {
        return this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
    }

    protected getOptions() {
        const entityName = this.getEntityName();
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        const options = [] as { value: string, label: string }[];

        for (const field of Object.keys(fields)) {
            if (this.props.fieldDescriptor.showOnlyAuditableFields && EntityDescriptorForServerUtils.getFieldId(entityName, field) === undefined) {
                continue;
            }
            if (this.props.fieldDescriptor.showOnlyComposedFields && !fields[field].typeIsEntity()) {
                continue;
            }
            options.push({ value: field, label: fields[field].getLabel() });
        }

        return options;
    }

    protected getDropdownProps() {
        const entityName = this.getEntityName();
        const entityDescriptor = entityDescriptors[entityName];
        if (this.state.entityName && this.state.entityName !== entityName) {
            // entityName has changed, but componentDidUpdate is called after this! so the reset done also here
            this.setState({ entityName: entityName });
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, '');
        }
        let selected: SelectExtOption[] = []
        let options: SelectExtOption[] = []
        if (entityDescriptor) {
            const fields = entityDescriptor.getAuthorizedFields(FIELDS_READ);
            options = this.getOptions()
            const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values)
            const items = this.getItemsFromValue(value)
            selected = value ? items.map((item: any) => {
                const option = this.getDropdownOptionFromItem(item, entityName);
                if (!fields[item]) {
                    options.push(option)
                }
                return option
            }) : []
        }
        return { entityName, selected, options, inputValue: this.state.inputValue };
    }

    getMenuOptions(s: ScriptableUiFieldEditor.Main) {
        return [
            <HW id="addAll" children={hw => <Menu.Item disabled={!this.props.fieldDescriptor.allowMultiple} onClick={() => this.addAll(castWithHw(s), hw)}><Icon name='add circle' /> {_msg('ColumnConfigEntityEditorPage.addAll')}</Menu.Item>} />,
            <HW id="removeAll" children={hw => <Menu.Item onClick={() => this.removeAll(castWithHw(s), hw)}><Icon name='remove circle' /> {_msg('ColumnConfigEntityEditorPage.removeAll')}</Menu.Item>} />,
            <Menu.Item disabled={this.props.fieldDescriptor.noComposedFields} onClick={() => this.setState({ fieldNameContentAssistOpen: true })}><Icon name='list layout' /> {_msg('form.composedField')}</Menu.Item>
        ];
    }

    renderModals(entityName: string, s: ScriptableUiFieldEditor.Main) {
        return <>
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.state.contextMenuPosition} onClose={() => this.setState({ contextMenuPosition: false })}>
                {this.state.contextMenuPosition && <Menu vertical className="wh100">{this.getMenuOptions(s)}</Menu>}
            </ModalExt>
            <ModalExt canChangeDimension open={this.state.fieldNameContentAssistOpen} onClose={() => this.setState({ fieldNameContentAssistOpen: false })} maxWidth={580}>
                <Modal.Header>{_msg('form.composedField.selection')}</Modal.Header>
                <Modal.Content>
                    <FieldNameContentAssistRRC id='fieldNameContentAssist' ref={this.fieldNameContentAssistRef} rootEntity={entityName} includeScalarFields={false} field='' />
                    <ModalExt open={this.state.selectionError} onClose={() => this.setState({ selectionError: false })} maxWidth={400}>
                        <Modal.Header>{_msg('form.composedField.selection.error.header')}</Modal.Header>
                        <Modal.Content>
                            <p>{_msg('form.composedField.selection.error.message')}</p>
                            <Button centered onClick={() => this.setState({ selectionError: false })}>{_msg('general.ok')}</Button>
                        </Modal.Content>
                    </ModalExt>
                </Modal.Content>
                <Modal.Actions>
                    <HW id="addComposedField" children={hw => <Button className='FilterForm_applyButton' color='green' onClick={() => this.onSubmit(this.fieldNameContentAssistRef.current?.getOptions()!, this.fieldNameContentAssistRef.current?.getChain()!, castWithHw(s), hw)}>{_msg('general.ok')}</Button>} />
                    <Button className='FilterForm_applyButton' color='red' onClick={() => this.setState({ fieldNameContentAssistOpen: false })}>{_msg('general.cancel')}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

    renderButtons() {
        return !this.props.hideOptions && !this.props.fieldDescriptor.showOnlyComposedFields && <Button primary icon="bars" floated='right' onClick={e => { e.preventDefault(); this.setState({ contextMenuPosition: [e.clientX, e.clientY] }) }} />;
    }

    protected getAdditionalProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return { };
    }
    
    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const { selected, options, inputValue } = this.getDropdownProps();
        const { ...additionalProps } = this.getAdditionalProps(s, hw);
        return <SelectExt options={options} defaultValue={selected} onSelectedOptionsChange={(selected: SelectExtOption[]) => s.setFieldValue(hw, this.getValueFromOptions(selected))}
            isMulti={this.props.fieldDescriptor.allowMultiple} appendDoneMenuEntry={true}
            closeOnKeys={this.props.fieldDescriptor.allowMultiple? ["ArrowUp", "ArrowDown"] : ["Enter", "ArrowUp", "ArrowDown"]}
            inputValue={inputValue} onInputChange={(inputValue) => this.setState({ inputValue })}
            {...this.props}
            {...additionalProps}
        />;
    }

    renderMain() {
        return <div className='flex-container-row flex-grow-shrink-no-overflow'>
            <div className='flex-grow-shrink-no-overflow'>
                {super.render()}
            </div>
            <div style={{ flexGrow: 0 }}>{this.renderButtons()}</div>
            <TestsAreDemoCheat objectToPublish={this} />
        </div>
    }

    render() {
        const { entityName } = this.getDropdownProps();
        return <this.scriptableUiClass id={this.getScriptableUiId()} implementation={this.scriptableUiImpl}>{s => 
            <>
                {this.renderMain()}
                {this.renderModals(entityName, s as any)}
            </>}
        </this.scriptableUiClass>
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadRemoveAll = () => {
        this.removeAll();
    }

    tadAddOption = (value: string) => {
        let selectedOption = this.getOptions().filter((option: { value: string, label: string }) => option.label.toLowerCase().includes(value.toLowerCase()));
        let previousValues = this.props.formikProps.values[this.props.fieldDescriptor.name];
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, { columns: [...previousValues?.columns, ...this.getValueFromOptions(selectedOption)?.columns] });
    }
}"../../components/ModalExt/ModalExt""../../components/fieldNameContentAssist/FieldNameContentAssist""../../components/selectExt/SelectExt""../entityCrudConstants""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils""../../utils/Utils"