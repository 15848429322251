/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregateFunctionType {
  AVG = "AVG",
  COUNT = "COUNT",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export enum AuthenticationStatus {
  CANNOT_RELOGIN = "CANNOT_RELOGIN",
  FAILURE = "FAILURE",
  FAILURE_TWO_MANY_FAILED_LOGINS = "FAILURE_TWO_MANY_FAILED_LOGINS",
  SUCCESS = "SUCCESS",
  SUCCESS_WITH_EXPIRED_PASSWORD = "SUCCESS_WITH_EXPIRED_PASSWORD",
}

export enum Severity {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export interface AggregateFunctionInput {
  field?: string | null;
  type?: AggregateFunctionType | null;
}

export interface CustomFindByFilterParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  bypassOrganizationFilter?: boolean | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  hideIgnoredCodes: boolean;
  hideRead: boolean;
  onlyRead: boolean;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
  userId: any;
}

export interface FilterInput {
  cast?: string | null;
  field?: string | null;
  filters?: (FilterInput | null)[] | null;
  operator?: string | null;
  timezone?: string | null;
  value?: string | null;
}

export interface FindByFilterParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  bypassOrganizationFilter?: boolean | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
}

export interface LoginParamsInput {
  data?: any | null;
  loginType?: string | null;
  password: string;
  rememberMe: boolean;
  username: string;
}

export interface LogoutParamsInput {
  data?: any | null;
  oktaUserId?: string | null;
}

export interface SaveParams_LongInput {
  duplicateFromId?: any | null;
  fieldsAndValues?: any | null;
  id?: any | null;
}

export interface SortInput {
  direction?: string | null;
  field?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
