import { getFieldLastUpdateDate, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { AuditEntriesPopup, AuditEntriesPopupRRC } from "@crispico/foundation-react/pages/Audit/AuditEntriesPopup";
import moment from "moment";
import React from "react";
import { Dropdown, DropdownProps, Grid, Header, Icon, Popup, Segment, SemanticWIDTHS, SemanticWIDTHSNUMBER } from "semantic-ui-react";
import { WidgetConfig, WidgetProps } from "../../WidgetWrapper";

const DEFAULT_WIDTH_BETWEEN_COLUMNS = 3;

export type MultipleFieldsWidgetConfig = {
    entityName: string,
    fields: string,
    nbColumns: number,
    widthBetweenColumns: SemanticWIDTHS,
    fontSize?: string,
    fieldLabelFontSize?: string,
} & WidgetConfig;

export type MultipleFielsdWidgetProps = WidgetProps<MultipleFieldsWidgetConfig>;

export class MultipleFieldsWidget extends React.Component<MultipleFielsdWidgetProps, { auditPopup: boolean }> {
    refAuditEntriesPopup = React.createRef<AuditEntriesPopup>();

    constructor(props: MultipleFielsdWidgetProps) {
        super(props);
        this.state = { auditPopup: false }
    }

    protected createButtonForAuditEntries(entityName: string, fields: string[], entityId: number) {
        if (Utils.isNullOrEmpty(this.props.entityForAttachedDashboard) || Utils.isNullOrEmpty(this.props.widgetConfig.entityName)) {
            return null;
        }

        const triggerIcon = <div id={this.props.id} className="MultipleFieldsWidget_rightCornerIcon">
            <Icon link name="chart line" size="large" onClick={(e: any) => fields.length == 1 ? this.refAuditEntriesPopup.current!.open(entityName, fields[0] as string, entityId, [e.clientX, e.clientY]) : null} />
        </div>

        return fields.length == 1 ?
            <>{triggerIcon}</>
            : <Popup
                popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                open={this.state.auditPopup}
                trigger={triggerIcon}
                onOpen={() => this.setState({ auditPopup: true })}
                content={<Dropdown className="MultipleFieldsWidget_multipleFieldsDropdown" search selection searchInput={{ autoFocus: true }}
                    options={fields.map(field => {
                        let fd = entityDescriptors[this.props.widgetConfig.entityName]?.getField(field);
                        if (fd) {
                            return ({ key: field, value: field, text: entityDescriptors[this.props.widgetConfig.entityName]?.getField(field).getLabel() })
                        }
                        return ({ key: field, value: field, text: field })
                    })}
                    onChange={(e: any, props: DropdownProps) => {
                        if (e.type == 'click') {
                            this.refAuditEntriesPopup.current!.open(entityName, props.value as string, entityId, [document.getElementById(this.props.id)?.getBoundingClientRect().x!, document.getElementById(this.props.id)?.getBoundingClientRect().y!])
                        }
                        this.setState({ auditPopup: false });
                    }}
                />}
                header={<h5>{_msg("general.show") + " " + _msg("Audit.label.plural")}</h5>}
                on='click' position='bottom right'
            />
    }

    protected getFieldDisplayedValue(field: string) {
        const props = this.props;
        try {
            let lastUpdateDate;
            let fd;
            let displayedValue: any = _msg("general.error");
            let muLabel: string | undefined;
            if (props.entityForAttachedDashboard && props.widgetConfig.entityName) {
                fd = entityDescriptors[props.widgetConfig.entityName]?.getField(field);
                lastUpdateDate = getFieldLastUpdateDate(props.entityForAttachedDashboard, field);
            }

            if (fd) {
                displayedValue = (fd.renderField(props.entityForAttachedDashboard) as any);
                if (Utils.isNullOrEmpty(displayedValue)) {
                    displayedValue = _msg("general.notAvailable");
                } 
                muLabel = fd.getMeasurementUnitLabel();
            }

            return <div key={field}><Segment basic className="no-padding-margin">
                <div className="MultipleFieldsWidget_valueContainer">
                    <div style={{ fontSize: props.widgetConfig.fieldLabelFontSize + "px" }}>{fd?.getLabel()}</div>
                    <div className="MultipleFieldsWidget_displayedValue" style={{ fontSize: props.widgetConfig.fontSize + "px" }}> {displayedValue}</div>
                </div>
                {lastUpdateDate ? <Popup position="bottom center"
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<Header size="tiny" className="no-margin white-space-normal" color='grey' style={{ fontWeight: 'normal' }}>{moment(lastUpdateDate).fromNow()}</Header>}
                    content={moment(lastUpdateDate).format(Utils.dateTimeFormat)}>
                </Popup> : null}
            </Segment>
            </div>
        } catch (e) {
            return <div style={{ fontSize: props.widgetConfig.fontSize + "px" }}>{_msg("general.error")}</div>
        }
    }

    render() {
        const props = this.props;
        const fields = props.widgetConfig.fields.split(',');
        const copyFields = [...fields];
        const gridRows = [];
        const width: SemanticWIDTHS = props.widgetConfig.widthBetweenColumns as number > 16 || props.widgetConfig.widthBetweenColumns as number < 1 ? DEFAULT_WIDTH_BETWEEN_COLUMNS : props.widgetConfig.widthBetweenColumns;
        while (!Utils.isNullOrEmpty(copyFields)) {
            let fieldsForRow = copyFields.splice(0, copyFields.length >= props.widgetConfig.nbColumns ? props.widgetConfig.nbColumns : copyFields.length);
            gridRows.push(<Grid.Row className="MultipleFieldsWidget_gridRow">
                {fieldsForRow.map(field => {
                    return <Grid.Column width={width}>
                        {this.getFieldDisplayedValue(field)}
                    </Grid.Column>
                })}
            </Grid.Row>)
        }

        return <Grid className="wh100 MultipleFieldsWidget_grid no-margin">
            {gridRows}
            {this.createButtonForAuditEntries(props.widgetConfig.entityName, fields, props.entityForAttachedDashboard?.id)}
            <AuditEntriesPopupRRC id="auditEntriesPopup" ref={this.refAuditEntriesPopup} />
        </Grid>
    }
}
"../../../../..""../../../../../entity_crud/entityCrudConstants""../../../../Audit/AuditEntriesPopup"