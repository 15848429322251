import React, { ReactElement, ReactNode } from "react";
import { Icon, Segment, SegmentGroup } from "semantic-ui-react";
import { Reducers, RRCProps, State } from "../../reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "../../utils/Utils";

export type RenderItemParams = {
    props: Props;
    linearizedItem: LinearizedItem;
};

export interface OnSelectParams {
    itemId: string;
    prevent: boolean;
}

export interface LinearizedItem {
    // when we added the search logic, we broke the correct values for "index", because of the inserts that are made.
    // since this wasn't used, we "disabled" it. If needed, it can be reenabled. In this case, after _linearize(), a new
    // /**
    //  * Not used by the tree. But may be convenient by users to navigate up/down in the tree.
    //  */
    // index: number;
    itemId: string;
    indent: number;
    expanded: Expanded;
}

export enum Expanded { COLLAPSED, EXPANDED, LEAF };

export type PropsNotFromState = {
    root: any;
    initialExpandedIds?: { [key: string]: boolean; };
    hasChildrenFunction?: (item: any) => boolean;
    getChildrenFunction?: (item: any) => { localId: string, item: any; }[];

    renderItemFunction?: (params: RenderItemParams) => ReactNode;
    renderMainElementFunction?: (params: {
        mainProps: any;
        mainChildren: ReactElement[];
        linearizedItems: LinearizedItem[]
    }) => ReactElement

    styleItemWrapperFunction?: (props: RenderItemParams) => any;
    onSelectItem?: (params: OnSelectParams) => void;
    onHoverItem?: (params: OnSelectParams) => void;
    onExpandCollapseItem?: (params: OnSelectParams) => void;
    onSelectedIdChanged?: (params: string | undefined) => void;
    onSearchExpressionModified?: (value: string) => void;
    onLinearizeItems?: (linearizedItems: LinearizedItem[]) => void;
};

export class TreeState extends State {
    expandedIds = {} as { [key: string]: boolean; };
    linearizedItems = [] as Array<LinearizedItem>;
    hoveredId = undefined as string | undefined;
    selectedId = undefined as string | undefined;
    searchExpression = "";
}

export type Props = PropsNotFromState & RRCProps<TreeState, TreeReducers>;

export interface AdditionalParamsForReducers {
    NON_SERIALIZABLE_hasChildrenFunction?: (item: any) => boolean;
    NON_SERIALIZABLE_getChildrenFunction?: (item: any) => { localId: string, item: any; }[];
}

export class TreeReducers<S extends TreeState = TreeState> extends Reducers<S> {

    /**
     * Should be overridden. The current impl returns always `true`, which is not convenient.
     */
    protected _hasChildren(item: any, additional: AdditionalParamsForReducers) {
        return additional.NON_SERIALIZABLE_hasChildrenFunction ? additional.NON_SERIALIZABLE_hasChildrenFunction(item) : true;
    }

    /**
     * May be overridden if needed (if the current impl is not suitable).
     */
    protected _getChildren(item: any, additional: AdditionalParamsForReducers): { localId: string, item: any; }[] {
        return additional.NON_SERIALIZABLE_getChildrenFunction ? additional.NON_SERIALIZABLE_getChildrenFunction(item) : Object.keys(item).map(key => ({ localId: key, item: item[key] }));
    }

    protected _isExpanded(linearizedItem: LinearizedItem) {
        if (this.s.searchExpression !== "") {
            return true;
        } else {
            return this.s.expandedIds[linearizedItem.itemId];
        }
    }

    expandCollapse(root: any, id: string, isExpand: boolean, additional: AdditionalParamsForReducers) {
        if (isExpand) {
            this.s.expandedIds[id] = true;
        } else {
            delete this.s.expandedIds[id];
        }
        this.linearize(root, additional);
    }

    linearize(root: any, additional: AdditionalParamsForReducers) {
        this.s.linearizedItems = [];
        this._linearize(root, root, "", -1, false, [false], additional);

        // reset selection/hover if not valid any more on the new data
        if (this.s.hoveredId && !this.s.linearizedItems.find(item => item.itemId === this.s.hoveredId)) {
            this.s.hoveredId = undefined;
        }
        if (this.s.selectedId && !this.s.linearizedItems.find(item => item.itemId === this.s.selectedId)) {
            this.s.selectedId = undefined;
        }
    }

    protected _matchesSearchExpression(currentItem: any): boolean {
        return false;
    }

    protected _linearize(root: any, currentItem: any, currentId: string, indent: number, parentMatchesSearchExpression: boolean, returnAtLeastOneChildMatchesSearchExpression: [boolean], additional: AdditionalParamsForReducers) {
        if (!currentId) {
            // is root
            this._getChildren(currentItem, additional)?.forEach(itemWithLocalId => this._linearize(root, itemWithLocalId.item, itemWithLocalId.localId, indent + 1, false, [false], additional));
            return;
        }

        const hasChildren = this._hasChildren(currentItem, additional);
        const linearizedItem: LinearizedItem = { indent, itemId: currentId, expanded: Expanded.LEAF, /* index: this.s.linearizedItems!.length */ };
        const thisMatchesSearchExpression = this.s.searchExpression === "" // i.e. nothing searched, so we want to add the current node
            || this._matchesSearchExpression(currentItem); // i.e. we have a search expression; let's see if the current node matches it
        // we'll be interested to know if one of the children matched; the result will be here. That's why it's an array, to allow
        // the children put a value here. We didn't use the normal return value, because maybe we'll use it later for something else
        const atLeastOneChildMatchesSearchExpression = [false] as [boolean];

        const sizeBeforeProcessingChildren = this.s.linearizedItems.length;
        if (hasChildren) {
            if (this._isExpanded(linearizedItem)) {
                linearizedItem.expanded = Expanded.EXPANDED;
                this._getChildren(currentItem, additional)?.forEach(itemWithLocalId => this._linearize(root, itemWithLocalId.item,
                    currentId + Utils.defaultIdSeparator + itemWithLocalId.localId, indent + 1, parentMatchesSearchExpression || thisMatchesSearchExpression, atLeastOneChildMatchesSearchExpression, additional));
            } else {
                linearizedItem.expanded = Expanded.COLLAPSED;
            }
        }

        if (parentMatchesSearchExpression || thisMatchesSearchExpression || atLeastOneChildMatchesSearchExpression[0]) {
            // e.g. was 10, so [9] was the last one. [10], [11] are children. So we insert at position 10
            // if no children => [9] is still the last one. So this acts as an insert
            this.s.linearizedItems.splice(sizeBeforeProcessingChildren, 0, linearizedItem);
        }
        returnAtLeastOneChildMatchesSearchExpression[0] = returnAtLeastOneChildMatchesSearchExpression[0] || thisMatchesSearchExpression || atLeastOneChildMatchesSearchExpression[0]
    }

    selectItem(p: string) {
        if (this.s.selectedId !== p) {
            this.s.selectedId = p;
        } else {
            // TODO CC: de discutat
            // LA: maybe to deselect; corresponding test (Tree.test.ts) needs to be edited accordingly
            // state.selectedId = undefined;
        }
    }

    reveal(root: any, ids: string[], expandIds: boolean, collapseOthers: boolean, toggle: boolean, additional: AdditionalParamsForReducers) {
        if (toggle && ids.length === 1 && this.s.expandedIds[ids[0]]) {
            this.s.expandedIds[ids[0]] = false;
            this.linearize(root, additional);
            return;
        }

        if (collapseOthers) {
            this.s.expandedIds = {} as any;
        }
        for (let id of ids) {
            let first = true;
            while (true) {
                if (!first || expandIds) {
                    this.s.expandedIds[id] = true;
                }
                const nextId = Utils.substringBefore(id, Utils.defaultIdSeparator, true);
                if (nextId === id) {
                    break; // i.e. no more separator
                } else {
                    id = nextId;
                };
                first = false;
            }
        }
        this.linearize(root, additional);
    }

    collapseAll(root: any, additional: AdditionalParamsForReducers) {
        this.s.expandedIds = {} as any;
        this.linearize(root, additional);
    }

    modifySearchExpression(originalRoot: any, searchExpression: string, additional: AdditionalParamsForReducers) {
        this.s.searchExpression = searchExpression;
        this.linearize(originalRoot, additional);
    }
}

// TODO the "search" logic has been migrated here; but no example was provided. And maybe when we'll do this, we'll need to do additional small adjustments.
// TODO we need to provide a default config; i.e. a way to use TreeRRC w/o needing to override _hasChildren(), etc. Initially we were thinking that each
// user should create a subclass to provide functions such as _has/_getChildren(), renderItem(), etc. But we changed our mind; we want to recommend to provide
// a modified model/root to be used w/ the defaults. E.g. `renderItemFunction()` was reintroduced.
export class Tree<T extends Props = Props> extends React.Component<T> {

    protected rootJustChanged = false;
    protected firstRender = true;

    paddingLeftBase = 10;
    paddingLeftFactor = 15;
    hoverColor = "rgba(0, 0, 0, 0.03)";
    selectedColor = "rgba(0, 0, 0, 0.15)";
    collapsedIcon = "plus square outline";
    expandedIcon = "minus square outline";

    constructor(props: T) {
        super(props);

        if (!props) {
            // because is instantiated as a normal object in the tests, passing null props (as marker)
            return;
        }
        // we need to invoke here, because shouldComponentUpdate() is not invoked for the first render
        this.shouldComponentUpdateInternal(this.props, true);
    }

    /**
     * Props may be either the current props (which is also the default), or next props.
     */
    protected getRoot(props: T = this.props) {
        return props.root;
    }

    getSearchExpression() {
        return this.props.s.searchExpression;
    }

    protected getAdditionalParamsForReducers(): AdditionalParamsForReducers {
        return {
            NON_SERIALIZABLE_hasChildrenFunction: this.props.hasChildrenFunction,
            NON_SERIALIZABLE_getChildrenFunction: this.props.getChildrenFunction
        }
    }

    modifySearchExpression(searchExpression: string) {
        this.props.r.modifySearchExpression(this.props.root, searchExpression, this.getAdditionalParamsForReducers());
        // this is not 100% correct. I don't think we have a guarantee that on next redraw cycle, redux did its magic.
        // The correct solution would have been to use an <Observer> in the render function. If we have issues => to do this.
        setTimeout(() => this.props.onSearchExpressionModified?.(this.props.s.searchExpression));
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps?: Readonly<T>, prevState?: Readonly<{}>, snapshot?: any): void {
        if (this.props.initialExpandedIds !== prevProps?.initialExpandedIds) {
            this.setInitialExpandedIds(this.props.initialExpandedIds || {});
            this.props.r.linearize(this.getRoot(), this.getAdditionalParamsForReducers());
        }
        if (this.props.s.linearizedItems !== prevProps?.s.linearizedItems) {
            this.props.onLinearizeItems?.(this.props.s.linearizedItems);
        }
    }

    setInitialExpandedIds(expandedIds: {}) {
        this.props.r.setInReduxState({ expandedIds: expandedIds });
    }

    setInitialSelectedId(value: string | undefined) {
        this.props.r.setInReduxState({ selectedId: value });
    }

    navigateToItem(index: number) {
        const nextObjectId = this.props.s.linearizedItems[index]?.itemId;
        return nextObjectId && Utils.navigate(this.getRoot(), nextObjectId);
    }

    shouldComponentUpdate(nextProps: T) {
        return this.shouldComponentUpdateInternal(nextProps, false);
    }

    protected shouldComponentUpdateInternal(nextProps: T, firstRender: boolean) {
        if (!firstRender) {
            if (nextProps.root === this.props.root) {
                this.rootJustChanged = false;

                return true;
            } // else => root was changed, so continue
        }

        // program arrives here if first render or if root was changed
        // so we dispatch an redux action which will linearize the new root
        this.props.r.linearize(this.getRoot(nextProps), this.getAdditionalParamsForReducers());

        /** 
         * Initially we had this logic at "componentDidUpdate()"; more precisely "useEffect()" hook, as this was a functional component. BUT:
         * Right now the root is not in sync w/ linearizedItems; if a render() is attempted, ids from linearizedItems may point to
         * objects that don't exist any more. And e.g. the user provided renderItem function will fail. Hence we want to prevent
         * a render right now. The dispatch above will trigger a state change hence this connected component will be notified, and
         * this method will be called again. See also the comment in "render()".
         */
        this.rootJustChanged = !firstRender; // for firstRender, this is true

        return false;
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>): ReactElement {
        return this.props.renderMainElementFunction
            ? this.props.renderMainElementFunction({ mainProps, mainChildren, linearizedItems: this.props.s.linearizedItems })
            : React.createElement(SegmentGroup, mainProps, mainChildren);
    }

    protected createItemWrapperProps(linearizedItem: LinearizedItem) {
        const props = this.props;

        return {
            className: linearizedItem.itemId === props.s.selectedId ? "selectedItem" : undefined,
            key: linearizedItem.itemId, "data-testid": "Tree_item_" + linearizedItem.itemId, name: linearizedItem.itemId,
            onClick: (e: any) => {
                if (e.target.id !== 'expandCollapseIcon' && props.s.selectedId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onSelectItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.r.selectItem(linearizedItem.itemId);
                    if (props.onSelectedIdChanged) {
                        props.onSelectedIdChanged(linearizedItem.itemId);
                    }
                }
            },
            onMouseOver: () => {
                if (props.s.hoveredId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onHoverItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.r.setInReduxState({ hoveredId: linearizedItem.itemId });
                }
            },
            style: Object.assign({
                display: "flex",
                alignItems: "center",
                paddingLeft: (this.paddingLeftBase + linearizedItem.indent * this.paddingLeftFactor) + "px",
                backgroundColor: linearizedItem.itemId === props.s.selectedId ? this.selectedColor : linearizedItem.itemId === props.s.hoveredId ? this.hoverColor : undefined
            }, props.styleItemWrapperFunction?.call(null, { props, linearizedItem }))
        };
    }

    protected getChildrenIcon(linearizedItem: LinearizedItem): any {
        switch (linearizedItem.expanded) {
            case Expanded.LEAF: return null;
            case Expanded.COLLAPSED: return this.collapsedIcon;
            case Expanded.EXPANDED: return this.expandedIcon;
        }
    }

    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any) {
        const props = this.props;
        const icon = this.getChildrenIcon(linearizedItem);

        return this.renderItemWrapperInternal({ ...itemWrapperProps }, icon && <Icon key="icon" data-testid="Tree_expandCollapseIcon" link size="large" name={icon} id="expandCollapseIcon"
            onClick={() => {
                const params = { itemId: linearizedItem.itemId, prevent: false };
                props.onExpandCollapseItem?.call(null, params);
                if (params.prevent) {
                    return;
                }
                props.r.expandCollapse(this.getRoot(), linearizedItem.itemId, linearizedItem.expanded === Expanded.COLLAPSED, this.getAdditionalParamsForReducers());
            }}
        />, this.renderItem({ props, linearizedItem }));
    }

    protected renderItemWrapperInternal(props: any, ...children: any) {
        return React.createElement(Segment, props, ...children);
    }

    protected renderItem(params: RenderItemParams): ReactNode {
        // try to call the provided function
        const result = params.props.renderItemFunction?.call(null, params);
        if (result) {
            return result;
        }

        // if not available: this is the default impl
        const ids = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        return ids[ids.length - 1];
    }

    render(): ReactNode {
        if (this.firstRender) {
            // first render always happens, regardless of shouldComponentUpdate(); we want to disable this first render
            // we discovered an issue w/ TreeMenu. On user change towards an user w/ different language (and "search" containing something) =>
            // the tree is first rendered with the linearizedItems saved in the state, based on the old root. The new root + search => root = []
            // and for the first render, we'll look w/ the linearized items from before in an empty root. Because the new linearized items that
            // were just calculated, were not yet dispatched as the props of this object. This will happen on the next render cycle.
            this.firstRender = false;
            return null;
        }

        if (this.rootJustChanged) {
            /** 
             * At the moment of writing, if shouldComponentUpdate() returns false => render() is not called. However the docs state that
             * in the future, the result of shouldComponentUpdate() may be taken as a hint; not as a guarantee, and hence render() may still
             * be called. And hence the code will arrive here. Note: that this is also true w/ the functional components / hooks version (i.e. React.memo()).
             * 
             * If this will happen, the only solution I see right now is to "memoize" (or simpler: just cache) the render function; i.e. if the code gets here => 
             * return the previous result.
             */
            throw new Error("An illegal state was detected. This was anticipated, so please follow the instructions and update the code.");
        }
        const props = this.props;

        const mainChildren = props.s.linearizedItems.map((linearizedItem) => this.renderItemWrapper(linearizedItem, this.createItemWrapperProps(linearizedItem)));
        // TODO CS: solutie pt ancore cypress
        return this.renderMain({ "data-cy": "tree" }, mainChildren);
        // return this.renderMain({ "data-cy": that.getKeyInState() }, mainChildren);
    }
}

// commented, because currently the user MUST create a subclass. Maybe in the future we'll tune a bit the defaults,
// e.g. hasChildren, add props.itemRenderer, so that people can use the component directly
// export const TreeRRC = ReduxReusableComponents.connectRRC(TreeState, TreeReducers, Tree);
