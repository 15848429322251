import { StatePartialFrom } from "@crispico/foundation-react/reduxHelpers";
import { sliceEntityTablePageAudit } from "./auditEntityDescriptor";
import { Direction } from "./AuditedFieldDetails/AuditedFieldDetails";

export const entities = [
    { index: 107, entityName: "Employee", entityindex: -3, version: 1, date: "2020-05-16T08:00:00Z", username: "user1", sourceCode: null, fields: [ "id", "firstName", "hireDate", "lastName", "stillEmployed", "department" ], oldValues :[ "-3", "Erling", "2020-03-01", "Kohler", "t", "-3" ], newValues: [ "" ], action: "DELETE"},
    { index: 106, entityName: "Employee", entityindex: -8, version: 1, date: "2020-03-20T13:00:00Z", username: "user2", sourceCode: null, fields: [ "lastName" ], oldValues :[ "Schowalter" ], newValues: [ "Stracke" ], action: "UPDATE"},
    { index: 105, entityName: "Employee", entityindex: -3, version: 1, date: "2020-03-16T09:00:00Z", username: "user1", sourceCode: null, fields: [ "hireDate", "department" ], oldValues: [ "2019-09-16", "-4" ], newValues: [ "2020-03-01", "-3" ], action: "UPDATE" },
    { index: 104, entityName: "Employee", entityindex: -2, version: 1, date: "2020-03-15T10:00:00Z", username: "user2", sourceCode: null, fields: [ "stillEmployed" ], oldValues: [ "t" ], newValues: [ "f" ], action: "UPDATE" },
    { index: 103, entityName: "Employee", entityindex: -8, version: 0, date: "2020-02-01T07:30:00Z", username: "admin", sourceCode: null, fields: [ "id", "firstName", "hireDate", "lastName", "stillEmployed", "department" ], oldValues: [""], newValues: [ "-8", "Elenora", "2020-01-14", "Schowalter", "t", "-4" ], action: "ADD" },
    { index: 102, entityName: "Employee", entityindex: -5, version: 0, date: "2020-02-01T07:20:00Z", username: "admin", sourceCode: null, fields: [ "id", "firstName", "hireDate", "lastName", "stillEmployed", "department" ], oldValues: [""], newValues: [ "-5", "Clemmie", "2017-06-01", "O'Conner", "t", "-4" ], action: "ADD" },
    { index: 101, entityName: "Employee", entityindex: -3, version: 0, date: "2020-02-01T07:10:00Z", username: "admin", sourceCode: null, fields: [ "id", "firstName", "hireDate", "lastName", "stillEmployed", "department" ], oldValues: [""], newValues: [ "-3", "Erling", "2019-09-16", "Kohler", "t", "-4" ], action: "ADD" },
    { index: 100, entityName: "Employee", entityindex: -2, version: 0, date: "2020-02-01T07:00:00Z", username: "admin", sourceCode: null, fields: [ "id", "firstName", "hireDate", "lastName", "stillEmployed", "department" ], oldValues: [""], newValues: [ "-2", "Dimitri", "2018-08-07", "Friesen", "t", "-1"], action: "ADD" },
    { index: 5, entityName: "Department", entityindex: -3, version: 1, date: "2020-04-06T09:00:00Z", username: "user1", sourceCode: null, fields: [ "id", "name" ], oldValues: ["-3", "Managers"], newValues: [ ""], action: "DELETE" },
    { index: 4, entityName: "Department", entityindex: -3, version: 1, date: "2020-04-05T09:00:00Z", username: "user1", sourceCode: null, fields: [ "name" ], oldValues: ["IT Managers"], newValues: [ "Managers"], action: "UPDATE" },
    { index: 3, entityName: "Department", entityindex: -4, version: 0, date: "2020-01-03T06:02:00Z", username: "admin", sourceCode: null, fields: [ "id", "name" ], oldValues: [""], newValues: [ "-4", "Programmers"], action: "ADD" },
    { index: 2, entityName: "Department", entityindex: -3, version: 0, date: "2020-01-03T06:01:00Z", username: "admin", sourceCode: null, fields: [ "id", "name" ], oldValues: [""], newValues: [ "-3", "IT Managers"], action: "ADD" },
    { index: 1, entityName: "Department", entityindex: -1, version: 0, date: "2020-01-03T06:00:00Z", username: "admin", sourceCode: null, fields: [ "id", "name" ], oldValues: [""], newValues: [ "-1", "Sales"], action: "ADD" },
];

export const secondaryPopupValues = { 
    currentOldValues: { index: -4, name: "Programmers", version: 0 }, 
    previousOldValues: null, 
    currentNewValues: { index: -3, name: "Managers", version: 1 }, 
    previousNewValues: { index: -3, name: "IT Managers", date: "2020-04-05T09:00:00Z", version: 1 } 
} 

export const columns = [{name: "entityName", width: 120},{name: "entityId",width: 120},{ name: "username", width: 120},{name: "date",width: 120},{name: "version",width: 120},{name: "action",width: 120},{name: "fields",width: 120}];

const openLabelChangedPopup = "";
const entity104 = entities.filter(x => x.index === 104)[0];
const entity105 = entities.filter(x => x.index === 105)[0];
const entity106 = entities.filter(x => x.index === 106)[0];

// @ts-ignore
export const testState1:StatePartialFrom<typeof sliceEntityTablePageAudit> = { totalCount: entities.length, loaded: entities.length, tableSimple: {entities: entities}, auditedFieldDetails: { openForSelector: "", entity: {}, fieldName: "", openLabelChangedPopup } };
// @ts-ignore
export const testState2:StatePartialFrom<typeof sliceEntityTablePageAudit> = { ...testState1, auditedFieldDetails: { openForSelector: "fieldColumn-104-Employee-stillEmployed", entity: entity104, fieldName: "stillEmployed", openLabelChangedPopup } };
// @ts-ignore
export const testState3:StatePartialFrom<typeof sliceEntityTablePageAudit> = { ...testState1, auditedFieldDetails: { openForSelector: "fieldColumn-105-Employee-hireDate", entity: entity105, fieldName: "hireDate", openLabelChangedPopup } };
// @ts-ignore
export const testState4:StatePartialFrom<typeof sliceEntityTablePageAudit> = { ...testState1, auditedFieldDetails: { openForSelector: "fieldColumn-106-Employee-lastName", entity: entity106, fieldName: "lastName", openLabelChangedPopup } };
// @ts-ignore
export const testState5:StatePartialFrom<typeof sliceEntityTablePageAudit> = { ...testState1, auditedFieldDetails: { openForSelector: "fieldColumn-105-Employee-department", entity: entity105, fieldName: "department", openLabelChangedPopup, secondaryPopupValues} };
// @ts-ignore
export const testState6:StatePartialFrom<typeof sliceEntityTablePageAudit> = { ...testState5, auditedFieldDetails: { ...testState5.auditedFieldDetails, openLabelChangedPopup: Direction.TO } };

export const apolloMock = {
    auditService_findByFilter: { results: entities, totalCount: entities.length }
};"../../reduxHelpers"