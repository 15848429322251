
import { apolloClient, apolloClientHolder, EntityDescriptor } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/apollo-gen-foundation/Filter";
import { Organization } from "@crispico/foundation-react/AppMeta";
import { SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import gql from "graphql-tag";
import _ from "lodash";
import React, { ReactElement } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import Measure from "react-measure";
import 'react-resizable/css/styles.css';
import { Button, ButtonProps, Icon, Modal, Segment } from "semantic-ui-react";
import { v4 as uuid } from 'uuid';
import { organizationEntityDescriptor } from "../../SettingsEntity/settingsEntityDescriptor";
import { DashboardMode } from "../DashboardContants";
import { Dashboard, DashboardConfig } from "../DashboardEntityDescriptor";
import { DashboardWidgetFactories } from "../DashboardWidgetFactory";
import { DashboardWidgetWizard } from "./DashboardWidgetWizard";
import { WidgetWrapper } from "./WidgetWrapper";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Wizard } from "@crispico/foundation-react/components/Wizard/Wizard";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const dashboardTabTestids = createTestids("DashboardTab", {
    add: "", viewEditMode: "", wizardMoveConfirmation: ""
});

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export enum DashboardMaxRows {
    NORMAL = 200, FIT_HEIGHT = 20, ENTITY = 800
}

export enum DashboardMaxColumns {
    DESKTOP = 36, MOBILE = 1
}

export enum DashboardRowHeight {
    NORMAL = 30, ENTITY = 10
}

// TODO RM32015/1: When this was migrated, we didn't have time to apply correct OOP principles.
// i.e. other components access (read, write) the state directly. This should happen through
// functions exposed by the component
export class DashboardTabState extends State {
    layouts: Layouts = {};
    cols: { [breakpoint: string]: number } = {
        lg: DashboardMaxColumns.DESKTOP, md: DashboardMaxColumns.DESKTOP, sm: DashboardMaxColumns.DESKTOP,
        xs: DashboardMaxColumns.MOBILE, xxs: DashboardMaxColumns.MOBILE
    };
    rowHeight = DashboardRowHeight.NORMAL;
    currentBreakpoint = "lg";
    compactType: "vertical" | "horizontal" | null | undefined = "vertical";
    mode = DashboardMode.VIEW;
    savedConfig?: DashboardConfig;
    widgetWrapperConfigs: { [widgetId: string]: { type: string, widgetConfig: any } } = {};
    dirty = false;
    /**
     * null => component not yet mounted; needed for save when the tab hasn't been opened yet.
     * false => component mounted, but layoutChanged() not yet called.
     * true => the "parasite" layoutChanged() that happens one time after each mount has happened. We need this flag for a correct dirty state.
     */
    layoutInit: boolean | null = false;

    // wizard
    wizardOpen = false;
    errorOpen = false;
    editorOpen?: string;
    widgetActionOrType?: string;
    // values from wizard/form
    values: any = {};
    deleteModalOpen?: string;

    // TODO RM32015/2: I think they shouldn't stay here; they belong to the wizard. When it dies, this component
    // doesn't need them any more
    wizardCopyMoveDashboardId?: number;
    wizardCopyMoveWidgetsAll: SelectExtOption[] = [];
    wizardCopyMoveWidgetsSelected: SelectExtOption[] = [];
    wizardMoveConfirmation = false;
    refresh = false;
}

export class DashboardTabReducers<S extends DashboardTabState = DashboardTabState> extends Reducers<S> {
    layoutChange(layouts: Layouts) {
        if (!_.isEqualWith(layouts, this.s.layouts)) {
            if (this.s.layoutInit) {
                this.s.dirty = true;
            } else {
                this.s.layoutInit = true;
            }
            this.s.layouts = layouts;
        }
    }

    removeItem(uid: string) {
        let newLayouts = this.s.layouts;
        Object.keys(this.s.layouts).forEach(k => {
            newLayouts[k] = newLayouts[k].filter((l: Layout) => l.i !== uid);
        })
        this.s.layouts = newLayouts;
        delete this.s.widgetWrapperConfigs[uid];
        this.s.dirty = true;
    }

    addItem(dashboardEntity: any, type?: string, config?: any) {
        const [valid, values] = type ? [true, config] : parseWidgetConfig(this.s.widgetActionOrType!, this.s.values, dashboardEntity);
        this.s.dirty = true;
        if (!valid) {
            this.s.errorOpen = true;
            return;
        }
        const uid = uuid();
        const newLayouts = this.s.layouts;
        Object.keys(this.s.layouts).forEach(k => {
            newLayouts[k].push({
                x: (this.s.layouts[k].length) % (this.s.cols[k] || 12),
                y: Infinity,
                w: 3,
                h: 5,
                i: uid
            });
        });
        this.s.widgetWrapperConfigs = { ...this.s.widgetWrapperConfigs, ...{ [uid]: { type: type ? type : this.s.widgetActionOrType!, widgetConfig: values } } };
        this.s.layouts = newLayouts;
        this.s.widgetActionOrType = undefined;
        this.s.wizardOpen = false;
        this.s.values = {};
    }

    editItem(uid: string, dashboardEntity: any) {
        const [valid, values] = parseWidgetConfig(this.s.widgetWrapperConfigs[uid].type, this.s.values, dashboardEntity);
        this.s.dirty = true;
        if (!valid) {
            this.s.errorOpen = true;
            return;
        }
        this.s.widgetWrapperConfigs[uid].widgetConfig = values;
        this.s.values = {};
        this.s.editorOpen = undefined;
    }

    onResize(height: number | undefined, fitHeight: boolean) {
        if (height && fitHeight) {
            this.s.rowHeight = Math.floor((height - DashboardMaxRows.FIT_HEIGHT.valueOf() * 5) / (DashboardMaxRows.FIT_HEIGHT));
        }
    }
}

export type DashboardTabProps = RRCProps<DashboardTabState, DashboardTabReducers> & {
    editable?: boolean,
    dashboardEntity: Dashboard,
    entityForAttachedDashboard?: any,
    buttons?: any,
    zeroTrainingMode?: boolean,
    dataExplorerFilter?: Filter,
    dataExplorerEntityDescriptor?: EntityDescriptor,
    currentOrganization?: Organization,
    currentOrganizationToFilterBy?: Organization,
    refresh?: () => void,
};

export type RenderExpandedDashboardParams = { key: string | number, org?: Organization, isEntityDashboard: boolean, mobileMode: boolean };

export class DashboardTab extends React.Component<DashboardTabProps> {

    wizardRef: React.RefObject<Wizard>;

    constructor(props: DashboardTabProps) {
        super(props);
        this.wizardRef = React.createRef<Wizard>();
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: DashboardTabProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: DashboardTabProps) {
        const props = this.props;
        if (prevProps && props.dashboardEntity?.id !== prevProps.dashboardEntity?.id) {
            props.r.setInReduxState({ layoutInit: false });
        }
        if (props.dashboardEntity && props.dashboardEntity.id !== prevProps?.dashboardEntity?.id) {
            const config = props.dashboardEntity.config;
            props.r.setInReduxState({
                widgetWrapperConfigs: config.widgetWrapperConfigs,
                layouts: config.layouts,
                layoutInit: false
            });
        }
    }

    selectWidgetType(widgetActionOrType: string) {
        this.props.r.setInReduxState({ widgetActionOrType });
        this.wizardRef.current?.props.r.setInReduxState({ currentStepIndex: 1 });
    }

    async copyWidgets() {
        const props = this.props;
        const widgetWrapperConfigs = props.s.widgetWrapperConfigs;
        const titles = Object.keys(widgetWrapperConfigs).map(key => widgetWrapperConfigs[key].widgetConfig.title).filter(title => title && title !== "");
        const widgets = this.props.s.wizardCopyMoveWidgetsSelected;
        widgets.forEach(widget => {
            let title = widget.config.widgetConfig.title;
            if (!title || title === "") {
                // skip for empty title
            } else {
                let counter = 0;
                while (titles.includes(title)) {
                    title = widget.config.widgetConfig.title + " (" + (++counter) + ")";
                }
                if (counter) {
                    titles.push(title);
                }
            }
            const widgetConfig = { ...widget.config.widgetConfig, ...{ title } };
            props.r.addItem(this.props.dashboardEntity, widget.config.type, widgetConfig);
        });
        props.r.setInReduxState({ wizardOpen: false, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] });
    }

    async moveWidgets() {
        const props = this.props;
        props.r.setInReduxState({ wizardMoveConfirmation: false });
        if (!props.dashboardEntity.id) {
            return;
        }
        const mutation = gql(`mutation m($id: Long!, $dashboardId: Long!, $widgetsToDelete: [String]) {
            dashboardService_saveAndDeleteWidgets(id: $id, dashboardId: $dashboardId, widgetsToDelete: $widgetsToDelete) { ${ID} }
        }`)
        await apolloClient.mutate({
            mutation: mutation,
            variables: {
                id: props.dashboardEntity.id,
                dashboardId: props.s.wizardCopyMoveDashboardId,
                widgetsToDelete: props.s.wizardCopyMoveWidgetsSelected.map(widget => widget.value)
            }
        });
        props.r.setInReduxState({ wizardOpen: false, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] });
        if (!props.refresh) {
            return;
        }
        props.refresh();
    }

    async selectDashboard(dashboardId?: number) {
        const props = this.props;
        if (!dashboardId) {
            props.r.setInReduxState({ wizardCopyMoveDashboardId: undefined, wizardCopyMoveWidgetsAll: [], wizardCopyMoveWidgetsSelected: [] });
            return;
        }
        const name = "dashboardService_findById";
        const query = gql(`query ($id: Long) {
            ${name}(id: $id) {
                configJson
            }
        }`);
        const configJson = (await apolloClientHolder.apolloClient.query({ query: query, variables: { id: dashboardId } })).data[name].configJson;
        let config = { layouts: {}, widgetWrapperConfigs: {} as any };
        if (configJson) {
            config = JSON.parse(configJson);
        }
        const options = Object.keys(config.widgetWrapperConfigs).map(id => {
            const title = config.widgetWrapperConfigs[id].widgetConfig.title as string;
            if (title) {
                return { value: id, label: title, config: config.widgetWrapperConfigs[id] };
            } else {
                return { value: id, label: id, config: config.widgetWrapperConfigs[id] };
            }
        }).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        props.r.setInReduxState({ wizardCopyMoveDashboardId: dashboardId, wizardCopyMoveWidgetsAll: options, wizardCopyMoveWidgetsSelected: [] });
    }

    renderTopBar() {
        const props = this.props;
        return <>
            {props.editable && <Segment className="less-padding" style={{ margin: "0 5px" }} data-cy="Dashboard.toolbar">
            {props.s.mode === DashboardMode.VIEW ?
                <Button data-testid="dashboardViewEditMode" onClick={e => props.r.setInReduxState({ mode: DashboardMode.EDIT })}>
                    <Icon name='edit' /> {_msg('Dashboard.mode.edit')}</Button> :
                <Button data-testid="dashboardViewEditMode" onClick={e => props.r.setInReduxState({ mode: DashboardMode.VIEW })}>
                    <Icon name='eye' /> {_msg('Dashboard.mode.view')}</Button>}
            {props.s.mode === DashboardMode.EDIT ? <>
                <Button data-testid="dashboardAdd" icon='add' label={_msg('Dashboard.button.add')}
                    onClick={e => this.props.r.setInReduxState({ wizardOpen: true })} data-cy="Dashboard.widget.wizard.add" />
                <div className="EntityTablePage_barDivider" />
                {props.buttons}
            </> : null}
            </Segment>}
        </>;
    }

    renderModals() {
        const props = this.props;
        return <>
            <ModalExt open={props.s.wizardOpen} onClose={() => props.r.setInReduxState({ wizardOpen: false })} data-cy="Dashboard.widget.wizard.description" style={{ width: '65vw' }}>
                <Modal.Header>{_msg('Dashboard.widget.wizard.addWidget')}</Modal.Header>
                <Modal.Content><Modal.Description>
                    <DashboardWidgetWizard dashboardTabRef={{ current: this }} wizardRef={this.wizardRef} />
                </Modal.Description></Modal.Content>
            </ModalExt>
            <ModalExt open={props.s.errorOpen} onClose={() => props.r.setInReduxState({ errorOpen: false })} size="small">
                <Modal.Header>{_msg('Dashboard.widget.wizard.finish.error.header')}</Modal.Header>
                <Modal.Content><Modal.Description>
                    <p>{_msg('Dashboard.widget.wizard.finish.error.content')}</p>
                </Modal.Description></Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.r.setInReduxState({ errorOpen: false })}>{_msg('general.ok')}</Button>
                </Modal.Actions>
            </ModalExt>
            <ModalExt open={props.s.wizardMoveConfirmation} size={"small"}>
                <Modal.Header>{_msg('Dashboard.wizard.confirmation.move.header')}</Modal.Header>
                <Modal.Content><Modal.Description>
                    <p>{_msg('Dashboard.wizard.confirmation.move.content')}</p>
                </Modal.Description></Modal.Content>
                <Modal.Actions>
                    <Button data-testid="dashboardWizardMoveConfirmation" positive onClick={() => this.moveWidgets()}>{_msg('general.ok')}</Button>
                    <Button negative onClick={() => props.r.setInReduxState({ wizardMoveConfirmation: false })}>{_msg('general.cancel')}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

    protected shouldDisplayCurrentOrganization(org: Organization, currentOrg?: Organization, currentOrganizationToFilterBy?:Organization) {
        return !currentOrg && !currentOrganizationToFilterBy || (org!.qualifiedName!.startsWith(currentOrganizationToFilterBy ? currentOrganizationToFilterBy.qualifiedName : currentOrg!.qualifiedName));
    }

    protected renderOrganizationTitleBar(org: Organization) {
        return organizationEntityDescriptor ? <h1 className="no-margin" id={org.qualifiedName}><Icon name={organizationEntityDescriptor.icon as any} />{organizationEntityDescriptor.toMiniString(org)}</h1> : null;
    }

    protected renderExpandedDashboard({ isEntityDashboard, ...params }: RenderExpandedDashboardParams): ReactElement | null {
        const props = this.props;
        return <React.Fragment key={params.key}>
            {isEntityDashboard ? null : this.renderOrganizationTitleBar(this.props.currentOrganizationToFilterBy || {id: -1, name: _msg("general.all"), qualifiedName: _msg("general.all")})}
            <ResponsiveReactGridLayout maxRows={isEntityDashboard ? DashboardMaxRows.ENTITY.valueOf() : !params.mobileMode && props.dashboardEntity.fitHeight ? DashboardMaxRows.FIT_HEIGHT.valueOf() : DashboardMaxRows.NORMAL.valueOf()}
                cols={props.s.cols} rowHeight={isEntityDashboard ? DashboardRowHeight.ENTITY : props.s.rowHeight}
                isDraggable={props.s.mode === DashboardMode.EDIT && !props.s.wizardOpen && props.s.editorOpen === undefined && !props.s.errorOpen}
                isResizable={props.s.mode === DashboardMode.EDIT && !props.s.wizardOpen && props.s.editorOpen === undefined && !props.s.errorOpen}
                layouts={props.s.layouts} onLayoutChange={(layout, layouts) => props.r.layoutChange(layouts)} onBreakpointChange={(newBreakpoint) => this.props.r.setInReduxState({ currentBreakpoint: newBreakpoint })}
                measureBeforeMount={false} compactType={props.s.compactType} preventCollision={false} margin={[5, 5]}
            >
                {Object.keys(props.s.widgetWrapperConfigs).map(id => {
                    const typeAndWidgetConfig = props.s.widgetWrapperConfigs[id];
                    const factory = DashboardWidgetFactories.INSTANCE.widgets[typeAndWidgetConfig.type];
                    if (!factory) {
                        throw new Error("Illegal widget type: " + typeAndWidgetConfig.type);
                    }
                    const orgFromFilter = global.currentOrganizationToFilterBy ? ("." + global.currentOrganizationToFilterBy.id) : "";
                    // TODO RM32015/3: the key should contain only the org + widget id; not it has more info; 
                    // explanation of the original author: the component didn't work that well; a patch (w/o properly understanding)
                    // the root issue, was to use a richer unique key
                    // I think that a possible cause may be:
                    // in the past, this was using sliceFoundation, which kept state "alive" also for "dead" components (i.e. that were destroyed)
                    // usually this implied additional operations in the state, e.g. for cleanup, which were error prone
                    return <div key={id} data-testid={id} role="widget" className="DashboardWidget">
                        <WidgetWrapper id={id} dashboardTabRef={{ current: this }} widgetId={props.dashboardEntity.id + "." + id + "." + params.key + orgFromFilter} mode={props.s.mode} factory={factory} widgetConfig={typeAndWidgetConfig.widgetConfig} organization={this.props.currentOrganizationToFilterBy}
                            editorOpen={props.s.editorOpen} dashboardEntity={props.dashboardEntity} dataExplorerFilter={props.dataExplorerFilter} dataExplorerEntityDescriptor={props.dataExplorerEntityDescriptor}
                            entityForAttachedDashboard={props.entityForAttachedDashboard} zeroTrainingMode={props.zeroTrainingMode} deleteModalOpen={props.s.deleteModalOpen} refresh={props.s.refresh}
                        />
                    </div>;
                })}
            </ResponsiveReactGridLayout>
        </React.Fragment>
    }

    render() {
        const props = this.props;
        if (!props.dashboardEntity) {
            return <p>{_msg("general.loading")}</p>;
        }
        const mobileMode = props.s.currentBreakpoint === "xs" || props.s.currentBreakpoint === "xxs" ? true : false;
        const isEntityDashboard = props.dashboardEntity.forEditor;
        return (<><Measure bounds onResize={contentRect => this.props.r.onResize(contentRect.bounds?.height, props.dashboardEntity.fitHeight && props.entityForAttachedDashboard)}>
            {({ measureRef }) => { return (
                <div data-cy="Dashboard.page" style={{ "width" : props.dashboardEntity.width || "1200px" }} data-testid={props.dashboardEntity.id} className={"flex-container Dashboard_EntityDashboardWrapper"}>
                    {this.renderTopBar()}
                    <div data-cy="Dashboard.layout" role="dashboard" ref={measureRef} style={{ height: isEntityDashboard ? '100%' : 'calc(100% - 65px)' }}>
                        {this.renderExpandedDashboard({ key: 0, isEntityDashboard, mobileMode })}
                    </div>
                    {this.renderModals()}
                </div>
            ); }}
        </Measure></>);
    }
}

export const DashboardTabRRC = ReduxReusableComponents.connectRRC(DashboardTabState, DashboardTabReducers, DashboardTab);

export function createButtonForWidget(buttonProps: ButtonProps, onClick: () => any) {
    return <Button size="tiny" hidden className='DashboardWidgetButton' {...buttonProps} onClick={onClick} />;
}

function parseWidgetConfig(type: string, widgetConfig: any, dashboardEntity: Dashboard) {
    const fields = DashboardWidgetFactories.INSTANCE.widgets[type].getEntityDescriptor(widgetConfig, dashboardEntity).fields;
    let values: any = {};
    let valid = true;
    Object.keys(fields).forEach(f => {
        if (fields[f].optional !== true && !widgetConfig[f]) {
            valid = false;
        } else {
            values[f] = fields[f].json && widgetConfig[f] ? JSON.parse(widgetConfig[f]) : widgetConfig[f];
        }
    });
    delete values['uid'];
    return [valid, values];
}
"../../..""../../../apollo-gen-foundation/Filter""../../../AppMeta""../../../components/selectExt/SelectExt""../../../components/ModalExt/ModalExt""../../../entity_crud/entityCrudConstants""../../../reduxReusableComponents/ReduxReusableComponents""../../../components/Wizard/Wizard"