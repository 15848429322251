import { FilterOperators } from "@crispico/foundation-gwt-js";
import { QueryOptions } from "apollo-client";
import { push } from "connected-react-router";
import gql from "graphql-tag";
import { default as lodash, uniqueId } from "lodash";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { BreadcrumbSectionProps, Button, Checkbox, Dimmer, Divider, Icon, Input, Loader, Menu, MenuItemProps, Message, Modal, Segment, SemanticShorthandCollection } from "semantic-ui-react";
import { apolloClient, apolloClientHolder, ApolloContext, CatchedGraphQLError } from "../apolloClient";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { ColumnConfigDropdown, COLUMN_CONFIG_DROPDOWN_MODE, getColumnConfigForClient, sliceColumnConfigDropdown } from "../components/ColumnConfig/ColumnConfigDropdown";
import { ColumnConfigDropdownSource, ColumnConfigSource } from "../components/ColumnConfig/dataStructures";
import { DEFAULT_COMPOSED_FILTER } from "../components/CustomQuery/BlocklyEditorTab";
import { ClientCustomQuery } from "../components/CustomQuery/ClientCustomQuery";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "../components/CustomQuery/CustomQueryBar";
import { CustomQueryDefinition } from "../components/CustomQuery/CustomQueryDropdown";
import { Filter } from "../components/CustomQuery/Filter";
import { Sort } from "../components/CustomQuery/SortBar";
import { FileExportButtonRRC, FileExportButton, FILE_EXPORTER_MODE } from "../components/fileExportButton/FileExportButton";
import { FileImportButtonRRC, FileImportButton, FILE_IMPORTER_MODE } from "../components/fileImportButton/FileImportButton"
import { ModalExt, Severity } from "../components/ModalExt/ModalExt";
import { TabbedPageProps, TabRouterPane } from "../components/TabbedPage/TabbedPage";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, SliceFoundation, StateFrom } from "../reduxHelpers";
import { TestUtils } from "../utils/TestUtils";
import { DELETE_ALL_FILTERED_ROWS_MENU_ENTRY, ENTITY, ENT_AUDIT, ENT_DELETE, ENT_READ, ENT_TABLE, FIELDS_WRITE, Utils } from "../utils/Utils";
import { AbstractCrudPage, SliceAbstractCrudPage, sliceAbstractCrudPageOnlyForExtension } from "./AbstractCrudPage";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { EDITOR_PAGE_ICON, entityDescriptors, getOrganizationFilter, ID, TABLE_PAGE_ICON } from "./entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { ADD, DUPLICATE } from "./EntityEditorPage";
import { ColumnDefinition, EntityTableCommonProps, EntityTableSimpleRRC, EntityTableSimpleProps, EntityTableSimple, ITableActionParamForRun } from "./EntityTableSimple";
import { FindByFilterParams } from "./FindByFilterParams";
import { ShareLinkLogic } from "./ShareLinkLogic";
import { Dashboard } from "../pages/dashboard/DashboardEntityDescriptor";
import Interweave from "interweave";
import { AggregateFunctionInput } from "../apollo-gen-foundation/globalTypes";
import { RefreshButtonRRC } from "../components/RefreshButton/RefreshButton";
import { CsvExporter, CsvExporterRRC } from "../components/csvExporter/CsvExporter";
import { EntityType } from "../components/multiCsvEditor/MultiCsvEditor";
import _ from "lodash";
import { Optional } from "../CompMeta";
import { v4 as uuid } from 'uuid';
import { EntityEditorInline } from "./EntityEditorInline";
import { EntityDescriptorForServerUtils } from "../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { TableProps } from "fixed-data-table-2";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import { ScriptableUiImpl } from "@famiprog-foundation/scriptable-ui";

export const INDEX = "_index";

export enum EntityTableMode {
    NORMAL, CARDS
}

let HistoryCompare: any;
let sliceHistoryCompare: SliceFoundation;

init();

async function init() {
    const historyComparePage = await import("../pages/HistoryCompare/HistoryCompare");
    HistoryCompare = historyComparePage.HistoryCompare;
    sliceHistoryCompare = historyComparePage.sliceHistoryCompare;
}

export type EntityTableOptions = {
    /**
     * Number of entitites to load in bulk; needed for infinite scroll/progressive loading
     * default: 100
     */
    bulkSize: number,
    /**
     * Tells when to start loading another bulk of entities.
     * If we want to load when the last row is visible in table, then value = 0.
     * If we want to load before reaching the last row, then value > x (x = number of rows)
     * default: 50
     */
    rowOffsetStartLoadingBulk: number,
    /**
     * If true, table counts and displays the number of total entities.
     * If false, tables displays only loaded number of entities.
     * default: true
     */
    countMode: boolean,
}

export class SliceEntityTablePage extends SliceAbstractCrudPage {

    _options: EntityTableOptions = { bulkSize: 100, rowOffsetStartLoadingBulk: 50, countMode: true };

    loadParamType = "FindByFilterParamsInput";

    getGraphQlIdType() {
        return this.entityDescriptor && this.entityDescriptor.graphQlIdType ? this.entityDescriptor.graphQlIdType : CrudGlobalSettings.INSTANCE.defaultGraphQlIdType;
    }

    get options(): EntityTableOptions {
        return this._options;
    }

    setOptions(value: Partial<EntityTableOptions>): SliceEntityTablePage {
        this._options = Object.assign(this._options, value);
        return this;
    }

    nestedSlices = {
        customQueryBar: sliceCustomQueryBar,
        // customQueryBarDashboard: sliceCustomQueryBar,
        columnConfigDropdown: sliceColumnConfigDropdown
    }

    initialState = {
        ...sliceAbstractCrudPageOnlyForExtension.initialState,
        mostRecentStartIndex: -1, // needed for loading entities in bulks
        loaded: -1, // number of loaded entities
        totalCount: -1,
        initialLoaded: 0,
        initialTotalCount: 0,
        totalAdjustment: 0,
        /**
         * When new data arrives from the server, if it contains records that are already displayed: we'll discard them.
         * This may happen if the user has kept the screen open e.g. 2 minutes, he scrolls and meanwhile somebody else has
         * added records that will "shift" the current records below. This is more likely to happen when we display data
         * sorted by date/desc, and meanwhile new records have been added. But in theory may happen for all types of filters.
         */
        loadedIds: {} as any,
        confirmDeleteEntity: false as boolean,

        /**
         * Heads up! The primary info exists as the prop "oneToMany" of the component. We save it in the state, i.e. create a 
         * bit of duplication because
         * otherwise it would mean a lot of modifications (i.e. all methods should somehow receive the props of the comp).
         * This is not a very good practice. Is almost like a global var in our case.
         */
        oneToManyModeCachedField: undefined as string | undefined,
        oneToManyModeCachedId: undefined as any,
        oneToManyModeEntityDescriptorName: undefined as string | undefined,
        shareLinkResult: { link: undefined, error: false } as { link: string | undefined, error: boolean },
        goToEditIfSingleEntity: false,
        compactBarModal: false as boolean | [number, number],
        compactBarMenuModal: false as boolean | [number, number],
        confirmDeleteByFilter: undefined as number | undefined,
        confirmDeleteByFilterInput: "",
        openModalExportAsCsv: false,
        refreshUUID: undefined as Optional<string>,
        actionParam: undefined as ITableActionParamForRun | undefined
    }

    reducers = {
        ...sliceAbstractCrudPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceEntityTablePage>(this),

        applyColumnConfigFromCustomQuery(state: StateFrom<SliceEntityTablePage>, customQuery: ClientCustomQuery) {
            if (customQuery.preferredColumnConfig) {
                this.getSlice().nestedSlices.columnConfigDropdown.reducers.updateColumnConfig(state.columnConfigDropdown, getColumnConfigForClient(customQuery.preferredColumnConfig))
                state.columnConfigDropdown.dropdownOpened = false;
                state.columnConfigDropdown.search = '';
            }
        },

        closeCompactBarMenuModal(state: StateFrom<SliceEntityTablePage>) {
            state.compactBarMenuModal = false;
        }
    }

    impures = {
        ...sliceAbstractCrudPageOnlyForExtension.impures,
        ...getBaseImpures<SliceEntityTablePage>(this),

        _eliminateDisabledCustomQueries(customQueries: Array<ClientCustomQuery>): Array<ClientCustomQuery> {
            return customQueries.filter(cq => cq.enabled)
        },

        /**
         * Meant to be overridden if needed.
         */
        adjustFilterBeforeLoad(filter: Filter): Filter {
            return filter;
        },

        /**
        * Meant to be overridden if needed.
        */
        adjustSortsBeforeLoad(sorts: Sort[]): Sort[] {
            return sorts;
        },

        getFilterForLoad() {
            let customQueryDefinition = this.getState().customQueryBar.customQuery ? this.getState().customQueryBar.customQuery!.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };
            let filter: Filter = customQueryDefinition.filter;

            filter = Filter.eliminateDisabledFilters(filter)!; // root filter shouldn't be disabled; so it always exists

            filter = filter ? filter : Filter.createComposed(FilterOperators.forComposedFilter.and, []);
            const oneToManyModeField = this.getState().oneToManyModeCachedField;
            const oneToManyModeCachedId = this.getState().oneToManyModeCachedId;
            if (oneToManyModeField) {
                if (filter.operator !== FilterOperators.forComposedFilter.and.value) {
                    filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);
                }
                this.addOneToManyModeFilters(filter.filters!, oneToManyModeField, entityDescriptors[this.getState().oneToManyModeEntityDescriptorName!], oneToManyModeCachedId);
            }

            const orgFilter = getOrganizationFilter(this.getSlice().entityDescriptor, global.currentOrganizationToFilterBy);
            if (orgFilter) {
                if (filter.operator !== FilterOperators.forComposedFilter.and.value) {
                    filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);
                }
                filter.filters?.push(orgFilter);
            }

            return filter;
        },

        addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
            filters!.push(Filter.create(oneToManyModeField, FilterOperators.forNumber.equals, oneToManyModeCachedId));
        },

        async invokeLoadQuery(options: QueryOptions<FindByFilterParams>) {
            return await apolloClientHolder.apolloClient.query(options);
        },

        getFieldsToRequest(fieldsToRequest: string[]) {
            return CrudGlobalSettings.INSTANCE.fieldId + " " + this.getSlice().entityDescriptor.getGraphQlFieldsToRequest(fieldsToRequest);
        },

        getLoadOperationName(ed: EntityDescriptor) {
            return `${lodash.lowerFirst(ed.name)}Service_findByFilter`;
        },

        getLoadQueryParams() {
            const ed = this.getSlice().entityDescriptor;
            const loadOperationName = this.getLoadOperationName(ed);
            const columns = this.getState().columnConfigDropdown.columnConfig?.configObject.columns;
            let fieldsToRequest: string[] = [];
            if (!columns) {
                fieldsToRequest = ed.getDefaultColumnConfig().configObject.columns!.map(column => column.name);
            } else {
                // only request the fields that are selected; including composed fields
                columns.forEach(column => fieldsToRequest.push(column.name));
            }
            const fieldsToRequestStr = this.getFieldsToRequest(fieldsToRequest);
            return {
                loadOperationName,
                loadQuery: gql(`query q($params: ${this.getSlice().loadParamType}) { 
                    ${loadOperationName}(params: $params) {
                        results { ${fieldsToRequestStr} } totalCount
                    }
                }`)
            };
        },

        getCustomQueryDefinitionForLoad(): CustomQueryDefinition {
            let customQueryDefinition = this.getState().customQueryBar.customQuery ? this.getState().customQueryBar.customQuery!.customQueryDefinitionObject : { sorts: [], filter: DEFAULT_COMPOSED_FILTER };

            let { sorts } = customQueryDefinition;
            sorts = this.adjustSortsBeforeLoad(sorts);

            let filter = this.getFilterForLoad();
            filter = this.adjustFilterBeforeLoad(filter);

            return { filter, sorts };
        },

        getAggregateFunctions(): AggregateFunctionInput[] | null {
            return null;
        },

        async loadAttachedDashboards() {
            if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, "Dashboard"])) && AppMetaTempGlobals.appMetaInstance.dashboardsAvailable && this.getSlice().entityDescriptor.hasAttachedDashboards) {
                const query = gql(`query q($id: Long, $forEditor: Boolean, $entityName: String) { 
                    dashboardService_attachedDashboards(id: $id, forEditor: $forEditor, entityName: $entityName) {
                        id name configJson icon forEditor width
                    }
                }`);
                const attachedDashboards = (await apolloClient.query({ query: query, variables: { forEditor: false, entityName: this.getSlice().entityDescriptor.name }, context: { showSpinner: false } })).data["dashboardService_attachedDashboards"];
                this.getDispatchers().setInReduxState({ attachedDashboards: attachedDashboards || [] });
            }
        },

        applyTableConfig(filter: Filter, sorts?: Array<Sort>) {
            const entityName = this.getSlice().entityDescriptor.name;

            const cq = {} as ClientCustomQuery;
            cq.customQueryDefinitionObject = {} as CustomQueryDefinition;
            cq.customQueryDefinitionObject.filter = filter;
            cq.customQueryDefinitionObject.sorts = [];
            cq.id = -2;
            cq.screen = entityName;
            cq.name = _msg("entityCrud.table.shared.cq");
            cq.dirty = true;
            cq.fromCrudSettings = false;

            if (sorts) {
                this.getDispatchers().customQueryBar.sortBar.setInReduxState({ sorts: sorts });
                cq.customQueryDefinitionObject.sorts = sorts;
            }

            this.getDispatchers().customQueryBar.updateCustomQuery(cq);
        },

        applyContextMenu(params: { displayAsCards?: boolean }) {
            const cc = _.cloneDeep(this.getDispatchers().columnConfigDropdown.getState().columnConfig!);
            if (params.displayAsCards !== undefined) {
                cc.displayAsCards = params.displayAsCards;
                cc.dirty = true;
            }
            this.getDispatchers().columnConfigDropdown.setInReduxState({ columnConfig: cc });
        },

        applyRefreshButton(params: { refreshRate?: number, displayAsCards?: boolean }) {

            if (params.refreshRate !== undefined && params.refreshRate !== this.getDispatchers().columnConfigDropdown.getState().columnConfig?.autoRefreshInterval) {
                const cc = _.cloneDeep(this.getDispatchers().columnConfigDropdown.getState().columnConfig!);
                cc.autoRefreshInterval = params.refreshRate;
                cc.dirty = true;
                this.getDispatchers().columnConfigDropdown.setInReduxState({ columnConfig: cc });
            }
        }
    }

}

/**
 * As it's name suggests, this INSTANCE is provided for convenience for extension. In normal operation,
 * a new INSTANCE is created per entity.
 */
export const sliceEntityTablePageOnlyForExtension: SliceEntityTablePage = createSliceFoundation(class extends SliceEntityTablePage { get entityDescriptor(): EntityDescriptor { throw new Error("This instance is only an utility for extension; it cannot be used.") } }, true);

export type EntityTablePageProps = TabbedPageProps & EntityTableCommonProps & PropsFrom<SliceEntityTablePage> & {
    hideActionsBar?: boolean,
    hideActionsCell?: boolean,
    screen?: string,
    oneToManyMode?: OneToManyMode,
    pageHeaderClassName?: string,
    itemsHidedFromCell?: string[];
};

type OneToManyMode = {
    field: string,
    entity: any,
    entityDescriptor: EntityDescriptor,
    entityField?: string,
    sort?: Sort | Sort[],
    filter?: Filter
};

export const entityTablePageTestids = FoundationUtils.createTestids("EntityTablePage", {
    tableMenu: "", openModal: "",
});

export namespace ScriptableUiEntityTablePage {
    export class Main extends ScriptableUi<Main> {
        getComponentName() { return "ScriptableUiEntityTablePage.Main" };
    }
}

export class EntityTablePage<P extends EntityTablePageProps = EntityTablePageProps> extends AbstractCrudPage<P> {

    tableSimpleClass = EntityTableSimpleRRC;

    entityTableSimpleRef = React.createRef<EntityTableSimple<EntityTableSimpleProps>>();
    csvExporterRef = React.createRef<CsvExporter>();

    shareLinkLogic = new ShareLinkLogic();
    protected historyCompareConnectedPageInfo: ConnectedPageInfo | undefined = undefined;

    static defaultProps = {
        showDtoCrudButtons: true,
        showImportButton: true,
        showExportButton: true
    }

    scriptableUiImpl: ScriptableUiImpl<ScriptableUiEntityTablePage.Main> = {
    }

    constructor(props: P) {
        super(props);
        this.provideActionsForRow = this.provideActionsForRow.bind(this);
        this.provideActionsForCell = this.provideActionsForCell.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onDoubleClickItem = this.onDoubleClickItem.bind(this);
        this.goToEditor = this.goToEditor.bind(this);
        this.onCloseEntityEditorInline = this.onCloseEntityEditorInline.bind(this);

        if (props.dispatchers.getSlice().canAddAuditTabs()) {
            this.historyCompareConnectedPageInfo = new ConnectedPageInfo(sliceHistoryCompare, HistoryCompare, "historyCompare_table_" + props.dispatchers.getSlice().entityDescriptor.name);
        }
    }

    protected getScriptableUiId() {
        // see FieldEditor.getScriptableUiId() for a potential case where we might have issues
        return (this.props.oneToManyMode ? "oneToMany" : "") + this.props.dispatchers.getSlice().entityDescriptor.name;
    }

    protected async resetStateBeforeRefresh() {
        await this.props.dispatchers.setInReduxState({ refreshUUID: uuid(), mostRecentStartIndex: -1, totalCount: -1, loaded: -1, initialLoaded: 0, initialTotalCount: 0, totalAdjustment: 0, loadedIds: {} });
        this.entityTableSimpleRef.current?.setEntities([]);
        this.entityTableSimpleRef.current?.setSelected(undefined);
    }

    /**
     * Overridable method, used to decide if the `initializeCC` method will be called at refresh of the table.
     * The current implementation doesn't seem clean. Because of the constraints of sliceFoundation and/or
     * improvable design. In theory we shouldn t have multiple calls to cc init. We expose this as a workaround,
     * because in at least one case, the double init is not good.
     */
    protected needToInitializeCC(): boolean {
        return !this.props.columnConfigDropdown.columnConfig;
    }

    async refresh() {
        if (TestUtils.storybookMode) {
            return;
        }
        // field layout
        if (this.needToInitializeCC()) {
            await this.props.dispatchers.columnConfigDropdown.initializeCC(this.props.dispatchers.getSlice().entityDescriptor, ColumnConfigDropdownSource.TABLE);
        }
        await this.resetStateBeforeRefresh();

        await this.loadEntities({ startIndex: 0, pageSize: this.props.dispatchers.getSlice().options.bulkSize, countMode: this.props.dispatchers.getSlice().options.countMode });
    }

    addIndexOnEntity(entities: any[]) {
        let count = this.entityTableSimpleRef.current?.getEntities().length || 0;
        for (const entity of entities) {
            entity[INDEX] = count++;
        }
    }

    adjustEntities(entities: any[]): any[] {
        this.addIndexOnEntity(entities);
        return entities;
    }

    async loadEntities(p: { startIndex: number, pageSize: number, countMode?: boolean }) {
        const state = this.props.dispatchers.getState();

        if (TestUtils.storybookMode) {
            return;
        }

        if (state.mostRecentStartIndex >= p.startIndex) {
            return;
        }

        this.props.dispatchers.setInReduxState({ mostRecentStartIndex: p.startIndex });

        const { filter, sorts } = this.props.dispatchers.getCustomQueryDefinitionForLoad();
        const aggregateFunctions = this.props.dispatchers.getAggregateFunctions();

        const loadQueryParams = this.props.dispatchers.getLoadQueryParams();
        const uuid: Optional<string> = this.props.refreshUUID;
        const { data } = (await this.props.dispatchers.invokeLoadQuery({
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    this.props.dispatchers.setInReduxState({ loaded: 0, totalCount: 0 });
                    return true;
                },
                showSpinner: false
            },
            query: loadQueryParams.loadQuery,
            variables: FindByFilterParams.create().startIndex(p.startIndex).pageSize(p.pageSize).filter(filter).sorts(sorts)
                .countMode(p.countMode).aggregateFunctions(aggregateFunctions)
        }));

        const result = data[loadQueryParams.loadOperationName];
        if (uuid !== this.props.refreshUUID) {
            // it means an old refresh was triggered and his answer arrived later than the latest refresh
            // so we reject the result received from it
            // case: 1/ init refresh; 2/ refresh because CQ changed -> need to keep 2/ and reject 1/
            return;
        }
        let initialLoaded = state.initialLoaded;
        let entities = this.entityTableSimpleRef.current?.getEntities() || [];
        let totalAdjustment = state.totalAdjustment;

        if (result.results.length !== 0) {
            initialLoaded = state.initialLoaded + result.results.length;
            this.props.dispatchers.setInReduxState({ initialLoaded });

            const loadedIds: any = { ...state.loadedIds };
            for (let i = 0; i < result.results.length; i++) {
                const id = this.props.dispatchers.getSlice().getId(result.results[i]);
                if (loadedIds[id]) {
                    /* 
                    * discard; @see doc of loadedIds
                    * delete does not affect the structure of the array as it happens at splice, the line is replaced with undefined
                    */
                    delete result.results[i];
                } else {
                    loadedIds[id] = true;
                }
            }
            //filter is needed to remove undefined records that may occur after deleting lines from the array in the above code.
            const newEntities = this.adjustEntities(result.results.filter((x: any) => x));

            entities = this.entityTableSimpleRef.current ? this.entityTableSimpleRef.current.getEntities().concat(newEntities) : [];
            totalAdjustment = entities.length - initialLoaded;
            this.entityTableSimpleRef.current?.setEntities(entities);
            this.props.dispatchers.setInReduxState({ loadedIds, loaded: entities.length, totalAdjustment });
        } else {
            if (state.loaded === -1) {
                this.props.dispatchers.setInReduxState({ loaded: 0 });
            }
        }

        if (!this.props.dispatchers.getSlice().options.countMode) {
            this.props.dispatchers.setInReduxState({ totalCount: entities.length });
        } else {
            let initialTotalCount = state.initialTotalCount;
            if (p.countMode && state.totalCount === -1) {
                initialTotalCount = result.totalCount;
                this.props.dispatchers.setInReduxState({ initialTotalCount })
            }
            this.props.dispatchers.setInReduxState({ totalCount: initialTotalCount + totalAdjustment });
        }

        return result;
    }

    async deleteEntity(entityDescriptorName: string, id: number, location: any) {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
            return;
        }

        const removeOperationName = `${lodash.lowerFirst(entityDescriptorName)}Service_deleteById`;
        const removeMutation = gql(`mutation deleteEntity($id: ${this.props.dispatchers.getSlice().getGraphQlIdType()}){${removeOperationName}(id: $id)}`);

        await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } })

        this.refresh();
    }

    async prepareDeleteEntitiesByFilter() {
        this.props.dispatchers.closeCompactBarMenuModal();
        if (this.props.dispatchers.getSlice().options.countMode) {
            await this.loadEntities({ startIndex: 0, pageSize: -1, countMode: true });
        }
        this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: this.props.totalCount >= 0 ? this.props.totalCount : undefined });
    }

    async confirmDeleteEntitiesByFilter() {
        const input = this.props.dispatchers.getSlice().options.countMode ? _msg("entityCrud.table.deleteFilteredRows.type", String(this.props.confirmDeleteByFilter)) : _msg("entityCrud.table.deleteFilteredRows.type.countMode");
        if (this.props.confirmDeleteByFilterInput === input) {
            const name = `${lodash.lowerFirst(this.props.dispatchers.getSlice().entityDescriptor.name)}Service_deleteByFilter`;
            const mutation = gql(`mutation deleteByFilter($params: FindByFilterParamsInput){${name}(params: $params)}`);
            await apolloClient.mutate({ mutation: mutation, variables: FindByFilterParams.create().filter(Filter.eliminateDisabledFilters(this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter!)) });
            this.refresh();
        }
        this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" });
    }

    getNextEntities(entity: any) {
        setTimeout(async (startIndex: number) => {
            if (this.props.initialLoaded >= this.props.dispatchers.getSlice().options.rowOffsetStartLoadingBulk && entity[INDEX] >= this.props.loaded - this.props.dispatchers.getSlice().options.rowOffsetStartLoadingBulk) {
                const bulkSize = this.props.dispatchers.getSlice().options.bulkSize;
                if (startIndex >= bulkSize) {
                    this.loadEntities({ startIndex: startIndex, pageSize: this.props.dispatchers.getSlice().options.bulkSize });
                }
            }
        }, undefined, this.props.initialLoaded);
    }

    getEntityAt(index: number): any | undefined {
        if (index >= this.props.loaded) {
            return undefined;
        }
        this.getNextEntities(this.entityTableSimpleRef.current?.getEntities()[index]);

        return this.entityTableSimpleRef.current?.getEntities()[index];
    }

    componentDidMount() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        // This was added in order to work the hideActionsBar case, 
        // otherwise because CustomQueryDropdown.componentDidMount isn't executed, the refresh/loadEntities will not be called
        // The CQ mechaism must be refactored!
        if (this.props.hideActionsBar) {
            !this.props.columnConfigDropdown.columnConfig && this.props.dispatchers.columnConfigDropdown.initializeCC(entityDescriptor, ColumnConfigDropdownSource.TABLE);
            !this.props.customQueryBar.customQuery && this.props.dispatchers.customQueryBar.customQueryDropdown.initializeFromSessionStorage(entityDescriptor.name, this.props.customQueryBar.customQuery, this.props.dispatchers.customQueryBar.updateCustomQuery);
        }

        this.props.dispatchers.loadAttachedDashboards();

        // in the past this was done in "onMatchChanged()"; I don't see any reason to happen there (opposed to an editor page, 
        // where this is interesting, because the ID may change). And having it here also covers the cases for embedded mode
        this.componentDidUpdateInternal();
    }

    protected getTitle(): string | { icon: JSX.Element | string; title: JSX.Element | string; } {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return { icon: entityDescriptor.icon, title: entityDescriptor.getLabel() + " [" + _msg("entityCrud.editor.table") + "]" };
    }

    protected goToEditor(url: string) {
        this.props.dispatchers.dispatch(push(url));
    }

    protected onDoubleClickItem(entity: any) {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        this.goToEditor(entityDescriptor.getEntityEditorUrl(entity.id));
    }

    protected onSelectItem(entityId: any) {
        this.props.onSelectItem?.(entityId);
    }

    protected getMainRoutePath(): string {
        // don't show table tab if embededMode and no extra tabs available      
        if (this.props.embeddedMode && this.getExtraTabPanes().length === 0) { return "" };
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return entityDescriptor.getEntityTableUrl();
    }

    protected getMainPaneSubPath() {
        return "table";
    }

    protected getMainMenuItemProps(): string | MenuItemProps {
        return { icon: TABLE_PAGE_ICON, content: _msg("entityCrud.editor.table") };
    }

    protected adjustCustomQueryForOneToManyMode() {
        let entityDescriptor = this.props.dispatchers.getSlice().entityDescriptor;
        let customQuery = _.cloneDeep(entityDescriptor.getDefaultCustomQuery());

        if (this.props.oneToManyMode && customQuery) {
            customQuery.screen = (entityDescriptor.name + "_oneToManyTable");
            customQuery.customQueryDefinitionObject.filter = this.props.oneToManyMode.filter || entityDescriptor.defaultFilter || Filter.createComposedForClient(FilterOperators.forComposedFilter.and, []);

            let sorts = this.props.oneToManyMode.sort || entityDescriptor.defaultSort || [];

            customQuery.customQueryDefinitionObject.sorts = !Array.isArray(sorts) ? [sorts] : sorts;

            this.props.dispatchers.customQueryBar.updateCustomQuery(customQuery);
        }
    }

    /**
     * This is needed when we want to call it from componentDidMount also.
     * Added for extension use.
     */
    protected componentDidUpdateInternal(prevProps?: P) {
        const { props } = this;

        super.componentDidUpdateInternal(prevProps);

        this.onLocationUpdate();

        if (!lodash.isEqualWith(props.customQueryBar.customQuery, prevProps?.customQueryBar.customQuery)
            || !lodash.isEqualWith(props.columnConfigDropdown.columnConfig, prevProps?.columnConfigDropdown.columnConfig)) {
            props.dispatchers.setInReduxState({ compactBarModal: false });
        }

        /** 
         * if we need to open the editor in case of a single entity
         * 1/ check to see if entities have been loaded (loaded >= 0)
         * 2/ reset `go to editor` param so this if isn't executed on next load
         * 3/ if single entity available -> open editor
        */
        if (props.goToEditIfSingleEntity && !lodash.isEqual(prevProps?.loaded, props.loaded) && props.loaded >= 0) {
            props.dispatchers.setInReduxState({ goToEditIfSingleEntity: false });
            const entities = this.entityTableSimpleRef.current?.getEntities();
            if (entities?.length === 1) {
                this.goToEditor(props.dispatchers.getSlice().entityDescriptor.getEntityEditorUrl(entities[0].id));
            }
        }

        /**
         * Refresh triggers zone; need to be sure we call `refresh()` only once. 
         */

        /**
         * To make this shorter, we can consider that we hava an async race condition. 1) Either the entity arrives first and then later comes the CQ. 
         * Or 2) first comes the CQ and then arrives the entity. 1) happens I enter the editor/form and then click on the tab w/ this class in "one-to-many mode".
         * And 2) happens if I write directly the URL of that tab.
         * So the mechanism is simple: if A arrived => is B already arrived? If yes => GO. And vice versa. 
         */
        const oneToManyModeChanged = props.oneToManyMode && props.oneToManyMode.entity?.[ID] && props.oneToManyMode.entity?.[ID] !== prevProps?.oneToManyMode?.entity?.[ID];
        const customQueryChanged = !lodash.isEqual(props.customQueryBar.customQuery?.customQueryDefinitionObject, prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject);

        let shouldRefresh = false;
        if (customQueryChanged && (!props.oneToManyMode || props.oneToManyMode.entity?.[ID] !== undefined) // A just arrive; is "oneToManyMode" disabled or B present? 
            || oneToManyModeChanged && props.customQueryBar.customQuery?.customQueryDefinitionObject) { // B just arrive; is A present?

            // if just changed (from undefined to something, or from something to something else)
            // OR we didn't store yet the value in the cache (so that the code can know that we are in one-to-many mode) ...
            if (props.oneToManyMode
                && (oneToManyModeChanged || !props.oneToManyModeCachedField)) {

                // ... then store it and call "refresh()" w/ "setTimeout" to let it propagate; so that the code in "refresh()" see this
                props.dispatchers.setInReduxState({
                    oneToManyModeCachedField: props.oneToManyMode.field, oneToManyModeEntityDescriptorName: props.oneToManyMode.entityDescriptor.name,
                    oneToManyModeCachedId: props.oneToManyMode.entity?.[props.oneToManyMode.entityField ? props.oneToManyMode.entityField : ID]
                });

                this.adjustCustomQueryForOneToManyMode();

                setTimeout(() => this.refresh());
                // prevent the normal "refresh()"
                return;
            } // else, even if oneToMany mode, we don't need the delay, because the "oneToManyModeCached" is already populated
            if (!(props.location && props.location.search.length > 0)) {
                this.refresh();
                return;
            }
        } else if (props.columnConfigDropdown.columnConfig?.configObject.columns && prevProps?.columnConfigDropdown.columnConfig?.configObject.columns // we had a CC and now we also have a CC
            && lodash.differenceBy(props.columnConfigDropdown.columnConfig.configObject.columns, prevProps.columnConfigDropdown.columnConfig.configObject.columns, "name").length) { // and are there cols in the new CC that are NOT in the old CC?
            shouldRefresh = true;
        }
        if (prevProps && !prevProps.goToEditIfSingleEntity && props.goToEditIfSingleEntity
            || prevProps && !lodash.isEqual(prevProps?.currentOrganizationToFilterBy, props.currentOrganizationToFilterBy)
            || prevProps?.location?.pathname != props.location?.pathname) {
            shouldRefresh = true;
        }

        if (shouldRefresh && props.location?.pathname === this.getMainRoutePath() + "/" + this.getMainPaneSubPath()) {
            // refresh only if the route points to the table, otherwise no need to refresh
            // e.g. if user is on Dashboard tab -> no refresh; when he goes to the table tab (e.g. location path changes) -> refresh
            this.refresh();
        }
    }

    protected onLocationUpdate() {
        const props = this.props;
        if (props.location && props.location.search.length > 0) {
            const urlSearchParams = new URLSearchParams(props.location.search);
            if (urlSearchParams.has("cqf") || urlSearchParams.has("cqs")) {
                const shareLink = this.shareLinkLogic.deserialize(urlSearchParams);
                if (shareLink.goToEditIfSingleEntity) {
                    props.dispatchers.setInReduxState({ goToEditIfSingleEntity: true });
                }
                props.dispatchers.applyTableConfig(shareLink.filter, shareLink.sorts);
            }
            AppMetaTempGlobals.history.replace(props.location.pathname);
        }
    }

    protected onMatchChanged(match: any) {
        this.onLocationUpdate();
    }

    protected shareLink = async () => {
        const props = this.props;
        const link = this.shareLinkLogic.createLink(true, props.dispatchers.getSlice().entityDescriptor, props.customQueryBar.customQuery?.customQueryDefinitionObject.filter,
            props.customQueryBar.sortBar.sorts);
        let error = false;
        try {
            await navigator.clipboard.writeText(link);
        } catch (e) {
            error = true;
        }
        this.props.dispatchers.setInReduxState({ shareLinkResult: { link, error } });
    }

    protected getBreadcrumbSections(): SemanticShorthandCollection<BreadcrumbSectionProps> {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return [
            { key: 'Home', content: <><Icon name="home" /><Link to="/">{_msg("HomePage.title")}</Link></> },
            { key: entityDescriptor.name, content: <>{entityDescriptor.getIcon()}{entityDescriptor.getLabel(true)}</> },
        ];
    }

    protected renderConfirmDeleteByFilterModal() {
        return <ModalExt closeIcon={true} open={this.props.confirmDeleteByFilter !== undefined} onClose={() => this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: undefined })} >
            <Modal.Header><Icon name="warning sign" color="red" /> {_msg("entityCrud.table.danger")}</Modal.Header>
            <Modal.Content>
                <Interweave content={
                    this.props.dispatchers.getSlice().options.countMode
                        ? _msg("entityCrud.table.deleteFilteredRows.message", String(this.props.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type", String(this.props.confirmDeleteByFilter)))
                        : _msg("entityCrud.table.deleteFilteredRows.message.countMode", String(this.props.confirmDeleteByFilter), _msg("entityCrud.table.deleteFilteredRows.type.countMode"))
                } />
                <p></p>
                <Input fluid autoFocus value={this.props.confirmDeleteByFilterInput}
                    onChange={(data) => this.props.dispatchers.setInReduxState({ confirmDeleteByFilterInput: data.target.value })} />
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.confirmDeleteEntitiesByFilter()}>{_msg("general.delete")}</Button>
                <Button negative onClick={() => this.props.dispatchers.setInReduxState({ confirmDeleteByFilter: undefined, confirmDeleteByFilterInput: "" })}>{_msg("general.cancel")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    protected getOneToManyModeAddParams() {
        const miniFields = this.props.oneToManyMode!.entityDescriptor.miniFields;
        const miniEntity = lodash.pick(this.props.oneToManyMode?.entity, (miniFields.includes("id") ? [] : ["id"]).concat(miniFields));
        return "?" + this.props.oneToManyMode?.field + "=" + JSON.stringify(miniEntity);
    }

    protected fileExporterRef = React.createRef<FileExportButton>();
    protected fileImporterRef = React.createRef<FileImportButton>();

    protected renderCompactBarMenuModal() {
        const entityDescriptor = this.props.dispatchers.getSlice().entityDescriptor;
        let columns: Array<ColumnDefinition> = [];
        if (this.props.columnConfigDropdown.columnConfig) {
            columns = this.props.columnConfigDropdown.columnConfig.configObject.columns!;
        }
        const hasDelete = AppMetaTempGlobals.appMetaInstance.hasPermission(DELETE_ALL_FILTERED_ROWS_MENU_ENTRY);
        const addItem = this.props.oneToManyMode ? <Menu.Item key="add" onClick={() => {
            this.props.dispatchers.closeCompactBarMenuModal();
            this.props.oneToManyMode && this.props.dispatchers.dispatch(push({ pathname: entityDescriptor.getEntityEditorUrl(ADD + "/edit"), search: this.getOneToManyModeAddParams() }));
        }} icon="plus" content={_msg("entityCrud.table.add")} /> : <Menu.Item as={NavLink} key="add" to={entityDescriptor.getEntityEditorUrl(ADD)}
            onClick={this.props.dispatchers.closeCompactBarMenuModal} icon="plus" content={_msg("entityCrud.table.add")}
        />;
        const deleteAllFilteredRowsItem = hasDelete ?
            <Menu.Item key="deleteAllFilteredRows" className="EntityTablePage_menu_deleteByFilter" icon="remove" onClick={() => this.prepareDeleteEntitiesByFilter()} content={_msg("entityCrud.table.deleteFilteredRows")} />
            : null
        const shareLinkItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showShareLinkButton ?
            <Menu.Item key="shareLink" onClick={async () => { await this.shareLink(); this.props.dispatchers.closeCompactBarMenuModal() }} icon="share alternate" content={_msg("entityCrud.table.shareLink")} />
            : null
        const fileImportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showImportButton ?
            <FileImportButtonRRC id="fli" ref={this.fileImporterRef} key="fileImport" mode={FILE_IMPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} refresh={() => this.refresh()} />
            : null
        const fileExportItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showExportButton ?
            <FileExportButtonRRC id="fle" ref={this.fileExporterRef} key="fileExport" mode={FILE_EXPORTER_MODE.MENU_ITEM} entityName={entityDescriptor.name} customQueryDefinition={this.props.dispatchers.getCustomQueryDefinitionForLoad()} columns={columns} />
            : null
        const fileExportAsCsvItem = AppMetaTempGlobals.appMetaInstance.showCrudButtons.showExportAsCsvButton ?
            // TODO CSR: aici cheia e necesara, caci obiectul sta intr-o lista; remind despre mesaje; de survolat bine prin toate fisierele
            <Menu.Item data-testid={entityTablePageTestids.openModal} key="exportAsCsv" icon="download" onClick={() => {
                this.props.dispatchers.setInReduxState({ openModalExportAsCsv: true, compactBarMenuModal: false })
            }} content={_msg("CsvExporter.exportAsCsv.label")} />
            : null
        const menuItems = [addItem, deleteAllFilteredRowsItem, shareLinkItem, fileImportItem, fileExportItem, fileExportAsCsvItem];
        return <Menu vertical className="wh100" borderless>
            {menuItems.filter((item: any) => item && !this.props.itemsHidedFromCell?.includes(item.key))}
            <Divider className="no-padding-margin" />
            <div className="flex-center EntityTablePage_compactMenu_additional">
                <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("ColumnConfig.displayAsCards.label")}</div>
                <Checkbox checked={this.props.columnConfigDropdown.columnConfig?.displayAsCards} onChange={(e, data) =>
                    this.props.dispatchers.applyContextMenu({ displayAsCards: data.checked })
                } />
            </div>
        </Menu>;
    }

    protected renderRefreshButton() {
        return <RefreshButtonRRC id={uniqueId("refreshButton-")} automaticRefresh={true}
            refresh={() => this.refresh()}
            refreshRate={this.props.columnConfigDropdown.columnConfig?.autoRefreshInterval}
            onChange={(params: { refreshRate?: number, displayAsCards?: boolean }) => this.props.dispatchers.applyRefreshButton(params)}
            openColumnConfig={() => this.props.dispatchers.columnConfigDropdown.openEditor(false, ColumnConfigSource.EDIT)}
        />
    }

    protected renderColumnConfigDropdown() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        return <ColumnConfigDropdown key="columnConfig" entityDescriptor={entityDescriptor} {...this.props.columnConfigDropdown}
            dispatchers={this.props.dispatchers.columnConfigDropdown} source={ColumnConfigDropdownSource.TABLE} showSearchAndOptions={true}
            mode={COLUMN_CONFIG_DROPDOWN_MODE.TABLE_COMPACT} />
    }

    /**
     * We can change the default method for applying preferred CC from a custom query by sending
     * an applyColumnConfigFromCustomQuery function as parameter
     */
    protected renderCustomQueryBar(applyColumnConfigFromCustomQuery?: (cq: ClientCustomQuery) => void) {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        return <CustomQueryBar key="customQueryBar" applyColumnConfigFromCustomQuery={applyColumnConfigFromCustomQuery ? applyColumnConfigFromCustomQuery : this.props.dispatchers.applyColumnConfigFromCustomQuery}
            entityName={entityDescriptor.name} screen={this.props.screen ? this.props.screen! : entityDescriptor.name} {...this.props.customQueryBar}
            dispatchers={this.props.dispatchers.customQueryBar} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />;
    }

    protected renderCompactBar() {
        return <Segment className="less-margin-top-bottom EntityTablePage_bar" >
            <Button primary icon="bars" data-testid={entityTablePageTestids.tableMenu} onClick={(e) => {
                this.props.dispatchers.setInReduxState({ compactBarMenuModal: [e.clientX, e.clientY] })
            }} />
            {this.renderRefreshButton()}
            {this.renderColumnConfigDropdown()}
            <span className="tiny-margin-right" />
            {this.renderCustomQueryBar()}
            <span className="tiny-margin-right" />
            {this.preRenderButtons({}).map((button: any, i) => {
                let key = button?.element?.key;
                if (!key) {
                    key = button?.props?.key;
                }
                if (key) {
                    if (button.element) {
                        return button.element;
                    }
                    if (!button.props.key) {
                        button.props.key = i;
                    }
                    return React.createElement(button.elementType, button.props);
                } else {
                    return null;
                }
            })}
            <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={this.props.compactBarMenuModal} onClose={() => this.props.dispatchers.closeCompactBarMenuModal()}>
                {this.renderCompactBarMenuModal()}
            </ModalExt>
            {this.renderConfirmDeleteByFilterModal()}
        </Segment>;
    }

    protected renderShareLinkModal() {
        return <ModalExt severity={this.props.shareLinkResult.error ? Severity.WARNING : Severity.INFO}
            open={this.props.shareLinkResult.link !== undefined}
            onClose={() => this.props.dispatchers.setInReduxState({ shareLinkResult: { link: undefined, error: false } })}>
            <Modal.Content>
                <Modal.Description>
                    {this.props.shareLinkResult.error ? <Message warning>{_msg("entityCrud.table.copiedToClipboard.error")}</Message>
                        : <p>{_msg("entityCrud.table.copiedToClipboard.main")}</p>}
                    <Message data-testid="shareLinkResult">{this.props.shareLinkResult.link}</Message>
                    {this.props.shareLinkResult.link && this.props.shareLinkResult.link.length > 2000 ? <p>{_msg("entityCrud.table.copiedToClipboard.limit")}</p> : null}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.props.dispatchers.setInReduxState({ shareLinkResult: { link: undefined, error: false } })} primary>{_msg("general.ok")}</Button>
            </Modal.Actions>
        </ModalExt>
    }

    prepareEntityForExport(): EntityType {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const columns = Object.keys(entityDescriptor.fields)


        return {
            [0]: {
                entityName: entityDescriptor.name, rows: [], columns: columns!
            }
        }
    }

    protected renderExportAsCsvModal() {
        const { filter, sorts } = this.props.dispatchers.getCustomQueryDefinitionForLoad();

        const loadQueryParams = this.props.dispatchers.getLoadQueryParams();
        return <ModalExt severity={Severity.INFO} className="EntityTablePage_exportAsCsvModal" size='fullscreen' closeIcon={true}
            open={this.props.openModalExportAsCsv}
            onClose={() => this.props.dispatchers.setInReduxState({ openModalExportAsCsv: false })}>
            <Modal.Header>{_msg("CsvExporter.exportAsCsv.label")}</Modal.Header>
            <Modal.Content>
                <CsvExporterRRC id="flecsv" csvText="" entities={this.prepareEntityForExport()}
                    ref={this.csvExporterRef} filter={filter} sorts={sorts} loadQueryParams={loadQueryParams} />
            </Modal.Content>
        </ModalExt>
    }

    protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        return [
            {
                icon: EDITOR_PAGE_ICON,
                label: _msg("entityCrud.table.edit"),
                run: (param) => {
                    this.goToEditor(entityDescriptor.getEntityEditorUrl(param.selection[0].id));
                }
            },
            {
                icon: "clone",
                label: _msg('dto_crud.duplicate'),
                run: (param) => {
                    this.props.dispatchers.dispatch(push({
                        pathname: entityDescriptor.getEntityEditorUrl(ADD) + "/edit", search: DUPLICATE + "=" + param.selection[0].id
                    }));
                }
            },
            {
                icon: "remove",
                label: _msg("entityCrud.table.delete"),
                run: (param) => {
                    this.props.dispatchers.setInReduxState({ confirmDeleteEntity: true });
                }
            }
        ];
    }

    protected isEditableCell(initialFormikValues: any, fieldDescriptor?: FieldDescriptor): boolean {
        return initialFormikValues && fieldDescriptor && !fieldDescriptor.clientOnly;
    }

    protected hasPermissionForField(entityDescriptorName: string, fieldName: string) {
        return AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, entityDescriptorName, FIELDS_WRITE, fieldName]));
    }

    protected checkPermissionsToEditField(entityDescriptorName: string, fieldName: string) {
        // If we have a custom field, permission will be set for its id, not on name and we will need to
        // get id from settings
        let customFieldId: number | undefined = EntityDescriptorForServerUtils.getFieldId(entityDescriptorName, fieldName);

        return this.hasPermissionForField(entityDescriptorName, fieldName) || (customFieldId && this.hasPermissionForField(entityDescriptorName, customFieldId.toString()));
    }

    protected canRenderEditOption(parentEntityDescriptor: EntityDescriptor, fieldDescriptor?: FieldDescriptor) {
        return fieldDescriptor && this.checkPermissionsToEditField(parentEntityDescriptor.name, fieldDescriptor.name);
    }

    protected provideActionsForCell(actionParam: ITableActionParamForRun): IAction[] {
        let { initialFormikValues, fieldDescriptor, parentEntityDescriptor, fieldLabel } = this.getParamsForEntityEditorInline(actionParam.selection[0], actionParam.currentField || "");

        let isEditableCell = this.isEditableCell(initialFormikValues, fieldDescriptor);
        let renderEditOption = this.canRenderEditOption(parentEntityDescriptor, fieldDescriptor);

        if (!renderEditOption) {
            return [];
        }

        return [{
            icon: EDITOR_PAGE_ICON,
            isDisabled: (param) => !isEditableCell,
            label: isEditableCell ? _msg("entityCrud.inlineEditor.edit.enabled", fieldLabel) : _msg("entityCrud.inlineEditor.edit.disabled", fieldLabel),
            run: (param) => {
                if (param.eventPoint) {
                    this.props.dispatchers.setInReduxState({
                        actionParam: {
                            ...(param as ITableActionParamForRun),
                            eventPoint: param.eventPoint
                        }
                    });
                }
            }
        }];
    }

    /**
     * It's not obvious to me why this works; I mean which prop that triggers a rerender
     * makes this to be invoked as well. Initially I wanted to inject in the TableSimple this
     * prop, to make sure this gets called.
     */
    protected renderFooter() {
        return <span>{_msg(this.props.dispatchers.getSlice().options.countMode ? "entityCrud.table.totalCount" : "entityCrud.table.loadedCount")} <b data-testid="recordsCount">{this.props.totalCount === -1 ? _msg("general.loading") : this.props.totalCount}</b>.&nbsp;</span>;
    }

    /**
     * Can be overrided to set tableProps to table simple
    */
    protected getTableProps(): Partial<TableProps> | undefined {
        return undefined;
    }

    protected renderPane(entityDescriptor: EntityDescriptor, columns: Array<ColumnDefinition>) {
        const displayAsCards = this.props.columnConfigDropdown.columnConfig?.displayAsCards;
        return <>
            <this.tableSimpleClass id={uniqueId('entityTableSimple-')} ref={this.entityTableSimpleRef} columns={columns}
                entityDescriptor={entityDescriptor} parentProps={this.props} renderFooter={this.renderFooter} mode={displayAsCards ? EntityTableMode.CARDS : EntityTableMode.NORMAL}
                onSelectItem={(entityId: any) => this.onSelectItem(entityId)}
                onDoubleClickItem={this.onDoubleClickItem}
                provideActionsForRow={!this.props.hideActionsCell ? this.provideActionsForRow : undefined}
                provideActionsForCell={this.provideActionsForCell}
                screen={this.props.screen ? this.props.screen! : entityDescriptor.name}
                onColumnMoved={(event: any) => this.props.dispatchers.columnConfigDropdown.updateColumnOrder(event)}
                onColumnResized={(width: number, name: string) => this.props.dispatchers.columnConfigDropdown.updateColumnSize({ width: width, name: name })}
                compactBar={this.props.hideActionsBar ? null : this.renderCompactBar()} getEntityAt={(index: number) => this.getEntityAt(index)}
                getNextEntities={(entity: any) => this.getNextEntities(entity)} tableProps={this.getTableProps()}
            />
            {this.renderShareLinkModal()}
            {this.renderExportAsCsvModal()}
        </>;
    }

    protected renderAttachedDashboard(dashboard: Dashboard) {
        const entityDescriptor = this.props.dispatchers.getSlice().entityDescriptor;
        return <>
            {/* <Segment className="less-margin-top-bottom EntityTablePage_bar" style={{ margin: "0 5px", width: "calc(100% - 10px)" }}>
                <CustomQueryBar key="customQueryBar" className="tiny-margin-right" applyColumnConfigFromCustomQuery={this.props.dispatchers.applyColumnConfigFromCustomQuery}
                    entityDescriptor={entityDescriptor.name} screen={this.props.screen ? this.props.screen! : entityDescriptor.name} {...this.props.customQueryBarDashboard}
                    dispatchers={this.props.dispatchers.customQueryBarDashboard} mode={CUSTOM_QUERY_BAR_MODE.TABLE} />
            </Segment> */}
            {super.renderAttachedDashboard(dashboard, { dataExplorerFilter: {}, dataExplorerEntityDescriptor: entityDescriptor })}
        </>;
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        let historyCompareTab: TabRouterPane | null = null;
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_AUDIT, this.props.dispatchers.getSlice().entityDescriptor.name]), false)) {
            if (this.historyCompareConnectedPageInfo && this.props.attachedDashboards !== undefined) {
                historyCompareTab = {
                    routeProps: { path: "/historyCompare" },
                    menuItemProps: { icon: "chart line", content: _msg("HistoryCompare.title") },
                    render: () => <ConnectedComponentInSimpleComponent info={this.historyCompareConnectedPageInfo!} entityName={this.props.dispatchers.getSlice().entityDescriptor.name}
                        editor={this} expand={true} filter={this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter} sorts={this.props.customQueryBar.customQuery?.customQueryDefinitionObject.sorts} />
                }
            }
        }

        if (super.getExtraTabPanes().length > 0) {
            // null is added for dashboardsTabs to change the order of tabs and be displayed first
            return [...super.getExtraTabPanes()!, null].concat(historyCompareTab ? [historyCompareTab] : []);
        }

        return this.props.oneToManyMode ? [] : historyCompareTab ? [historyCompareTab] : [];
    }

    protected renderConfirmDeleteEntity() {
        const props = this.props;
        return (
            <ModalExt
                severity={Severity.INFO}
                open={props.confirmDeleteEntity}
                header={_msg("dto_crud.deleteConfirmation.header", this.entityTableSimpleRef.current?.getSelected(), props.dispatchers.getSlice().entityDescriptor.getLabel())}
                content={_msg("dto_crud.deleteConfirmation")}
                onClose={() => props.dispatchers.setInReduxState({ confirmDeleteEntity: false })}
                actions={[
                    <Button key="close" onClick={() => props.dispatchers.setInReduxState({ confirmDeleteEntity: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={() => {
                        if (!this.entityTableSimpleRef.current?.getSelected()) { return; }
                        this.deleteEntity(props.dispatchers.getSlice().entityDescriptor.name, this.entityTableSimpleRef.current?.getSelected(), props.location);
                        props.dispatchers.setInReduxState({ confirmDeleteEntity: false });
                    }}>{_msg("general.ok")}</Button>
                ]}
            />
        );
    }

    protected getParamsForEntityEditorInline(entity: any, currentFieldName: string) {
        const { entityDescriptor } = this.props.dispatchers.getSlice();

        let fieldLabel = entityDescriptor.getComposedFieldLabel(entityDescriptor.getFieldDescriptorChain(currentFieldName));

        let initialFormikValues = entity;

        if (currentFieldName && currentFieldName.includes(".")) {
            let parentFieldNames = currentFieldName.split(".");
            parentFieldNames.splice(parentFieldNames.length - 1);
            initialFormikValues = Utils.navigate(entity, parentFieldNames, false, ".");
        }

        let fieldDescriptor = undefined;
        let parentEntityDescriptor = entityDescriptor;

        if (currentFieldName) {
            fieldDescriptor = entityDescriptor.getField(currentFieldName);
            let parentFieldDescriptor = entityDescriptor.getParentField(currentFieldName);
            parentEntityDescriptor = parentFieldDescriptor ? entityDescriptors[parentFieldDescriptor.type] : entityDescriptor;
        }

        return { initialFormikValues, fieldDescriptor, parentEntityDescriptor, fieldLabel };
    }

    protected onCloseEntityEditorInline(withRefresh?: boolean) {
        this.props.dispatchers.setInReduxState({
            actionParam: undefined
        });

        if (withRefresh) {
            this.refresh();
        }
    }

    renderTableDimmer() {
        return <Dimmer inverted active={this.props.loaded === -1} page={true}><Loader size='large'>{_msg("general.loading")}</Loader></Dimmer>
    }

    renderTableSimple() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        let columns: Array<ColumnDefinition> = [];
        if (this.props.columnConfigDropdown.columnConfig) {
            columns = this.props.columnConfigDropdown.columnConfig.configObject.columns!;
        }

        let { initialFormikValues, fieldDescriptor, parentEntityDescriptor } =
            this.getParamsForEntityEditorInline(this.props.actionParam?.selection[0], this.props.actionParam?.currentField || "");

        return <>
            {this.renderTableDimmer()}
            {this.renderPane(entityDescriptor, columns)}
            {this.renderConfirmDeleteEntity()}
            {this.props.actionParam && <EntityEditorInline editorPosition={this.props.actionParam.eventPoint}
                fieldDescriptor={fieldDescriptor} entityDescriptor={parentEntityDescriptor} goToFullEditor={this.goToEditor}
                entity={initialFormikValues} scriptableUiId={"entityEditorInline_" + this.getScriptableUiId()} onClose={this.onCloseEntityEditorInline} />}
        </>;
    }

    renderMain() {
        const tableSimple = this.renderTableSimple();
        return <div className="EntityTablePage_embbeded">{tableSimple}</div>;
    }

    hasPermission(permission: string) {
        return AppMetaTempGlobals.appMetaInstance.hasPermission(permission);
    }

    render() {
        const { entityDescriptor } = this.props.dispatchers.getSlice();
        const permission = Utils.pipeJoin([ENT_TABLE, entityDescriptor.name]);
        if (!this.hasPermission(permission)) {
            return AppMetaTempGlobals.appMetaInstance.getRedirectToError(permission, this.props.location);
        }
        return <ScriptableUiEntityTablePage.Main id={this.getScriptableUiId()} implementation={this.scriptableUiImpl}>
            {s => <>
                {super.render()}
            </>}
        </ScriptableUiEntityTablePage.Main>;
    }
}
