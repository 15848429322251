import { FormikProps } from "formik";
import { EntityEditorFormSimple, EntityEditorFormSimpleProps, ScriptableUiCrudForm } from "./EntityEditorFormSimple";
import { EntityEditorPage } from "./EntityEditorPage";

export interface CrudFormInEditorProps extends EntityEditorFormSimpleProps {
    editor: EntityEditorPage
}

/**
 * This is a `CrudForm` that is contained by `CrudEditor`. Hence `CrudForm`
 * knows its parent editor, so that it can act accordingly.
 */
export class CrudFormInEditor extends EntityEditorFormSimple<CrudFormInEditorProps> {

    renderFormikChild(s: ScriptableUiCrudForm.Main, formikProps: FormikProps<any>, duplicateFromId?: boolean) {
        return this.props.editor.props.duplication ? super.renderFormikChild(s, formikProps, true) : super.renderFormikChild(s, formikProps)
    }
}