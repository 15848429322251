import { StatePartialFrom } from "../reduxHelpers";
import { SliceEntityTablePage } from "./EntityTablePage";
import { jd } from "../utils/Utils";

// CS: many states were used by the files that were deleted (actually moved to _disabled-stories-and-tests-during-migration-to-RRC)
// however there are still some states used by stories that are still active. I didn't perform a cleaning
const jobs = [
    {
        id: -200,
        name: "Web Developer"
    },
    {
        id: -201,
        name: "Technical Specialist"
    },
    {
        id: -202,
        name: "Network Administrator"
    },
    {
        id: -203,
        name: "Data Entry"
    },
    {
        id: -204,
        name: "CTO"
    },
    {
        id: -205,
        name: "CIO"
    }
];

export const departments = [
    { id: -1, name: "Managers", comment: "Some comment with <b>bold</b> and <i>italic</i>" },
    { id: -2, name: "Programmers", comment: "<h1>A header</h1><p>And some normal text</p>" },
    { id: -3, name: "Accounting" },
    { id: -4, name: "Operations" }
];

export const employees = [
    {
        id: -100,
        firstName: 'John',
        lastName: 'Deere',
        birthDate: jd("1980-02-01", "YYYY-MM-DD"),
        stillEmployed: true,
        color: "#F5A623",
        jobs: jobs.filter(x => [-200, -201].includes(x.id)),
        department: departments[1],
    },
    {
        id: -101,
        firstName: 'John',
        lastName: 'Doe',
        birthDate: jd("1991-10-21", "YYYY-MM-DD"),
        stillEmployed: false,
        color: undefined,
        department: departments[1],
    },
    {
        id: -102,
        firstName: 'Jane',
        lastName: 'Sullivan',
        birthDate: jd("2003-07-03", "YYYY-MM-DD"),
        stillEmployed: true,
        color: "#4A90E2",
        department: departments[0],
        jobs: jobs.filter(x => [-200].includes(x.id)),
    },
    {
        id: -103,
        firstName: "Erling",
        lastName: "Kohler",
        birthDate: jd("1990-03-01", "YYYY-MM-DD"),
        department: departments[0],
        stillEmployed: true,
        jobs: jobs.filter(x => [-200, -203].includes(x.id)),
    },
    {
        id: -104,
        firstName: "Elenora",
        lastName: "Schowalter",
        birthDate: jd("1980-06-14", "YYYY-MM-DD"),
        department: departments[1],
        stillEmployed: true,
        jobs: jobs.filter(x => [-204].includes(x.id)),
    },
    {
        id: -105,
        firstName: "Clemmie",
        lastName: "O'Conner",
        birthDate: jd("1985-03-01", "YYYY-MM-DD"),
        department: departments[1],
        stillEmployed: false,
    },
    {
        id: -106,
        firstName: "Erling",
        lastName: "Kohler",
        birthDate: jd("1993-08-12", "YYYY-MM-DD"),
        department: departments[1],
        stillEmployed: true,
        jobs: jobs.filter(x => [-202].includes(x.id)),
    },
    {
        id: -107,
        firstName: "Dimitri",
        lastName: "Friesen",
        birthDate: jd("1987-12-21", "YYYY-MM-DD"),
        department: departments[1],
        stillEmployed: false,
    }
]

export function generateEmployees(length: number): any {
    let list = [];
    for (let i = 0; i < length; i++) {
        list.push({
            id: i,
            firstName: 'John' + i,
            name: 'Deere' + i
        })
    }
    return list;
}

export const testState1: StatePartialFrom<SliceEntityTablePage> = {
    totalCount: employees.length,
    loaded: employees.length,
    // tableSimple: {
    //     entities: employees,
    //     selected: -101
    // }
}

export const testState2: StatePartialFrom<SliceEntityTablePage> = {
    loaded: 10,
    totalCount: 20,
    // tableSimple: {
    //     entities: generateEmployees(10)
    // }
}

export const testState3: StatePartialFrom<SliceEntityTablePage> = {
    loaded: 20,
    totalCount: 20,
    // tableSimple: {
    //     entities: generateEmployees(20)
    // }
}

export const apolloMock = {
    // employeeService_findByFilter: { results: testState1.tableSimple?.entities, totalCount: testState1.tableSimple?.entities?.length },
    jobService_findByString: jobs,
    employeeService_findById: { ...employees[0], entityType: 'Employee', comment: "Lorem ipsum dolor sit amet, vide laudem ne duo, vel ea vocent oportere. Usu docendi percipitur an, duo graecis phaedrum eloquentiam cu. Cum timeam nonumes accumsan an, sea epicuri invenire sententiae ei. Vim consulatu voluptatibus ex." }
};