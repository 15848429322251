import { ConnectedComponentInSimpleComponent, ConnectedPageInfo, CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, FIELDS_READ, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
//@ts-ignore
import { Formik } from "formik";
import lodash, { uniqueId } from 'lodash';
import moment from "moment";
import React from "react";
import { Button, Container, Dropdown, Input, Segment } from "semantic-ui-react";
import { v4 as uuid } from 'uuid';
import { PeriodPickerRRC, PeriodPicker, PeriodType } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { ColumnConfigDropdownSource } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { columnConfigEntityDescriptor } from "@crispico/foundation-react/components/ColumnConfig/ColumnConfigEntityDescriptor";
import { ColumnConfigDropdown, ColumnConfigDropdownProps, sliceColumnConfigDropdown } from "@crispico/foundation-react/components/ColumnConfig/ColumnConfigDropdown";
import { ClientColumnConfig } from "@crispico/foundation-react/components/ColumnConfig/ClientColumnConfig";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { EntityFieldsWithCrudViewerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/EntityFieldsWithCrudViewerFieldEditor";
import { AnimationRRC } from "@crispico/foundation-react/components/animation/Animation";
import { HistoryGraphItemFactory } from "./historyGraphItem/HistoryGraphItemFactory";
import { HistoryGraphFieldItemRRC } from "./historyGraphItem/HistoryGraphFieldItem";
import _ from "lodash";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";


const AUDIT_GRAPH_SESSION_STORAGE_KEY = "auditGraph_";

function getFieldsForSession(entityName: string) {
    return window.sessionStorage.getItem(AUDIT_GRAPH_SESSION_STORAGE_KEY + entityName);
}

function setFieldsForSession(entityName: string, fields: string) {
    window.sessionStorage.setItem(AUDIT_GRAPH_SESSION_STORAGE_KEY + entityName, fields);
}

function clearFieldsForSession(entityName: string) {
    window.sessionStorage.removeItem(AUDIT_GRAPH_SESSION_STORAGE_KEY + entityName);
}

class AuditGraphState extends State {
    fields = [] as string[];
    initialFields = undefined as string | undefined;
    startDate = moment(Utils.now().getTime()).startOf("day").valueOf() as number;
    endDate = moment(Utils.now().getTime()).endOf("day").valueOf() as number;
    refreshUUID = uuid() as string;
    currentStartDate = undefined as number | undefined;
    currentEndDate = undefined as number | undefined;

}

class AuditGraphReducers<S extends AuditGraphState = AuditGraphState> extends Reducers<S> {
}

export type Props = RRCProps<AuditGraphState, AuditGraphReducers> & {
    entities: any[];
    entityDescriptor: EntityDescriptor;
    // use for history compare
    showOnlyLabelColumnInCrudViewer?: boolean;
};

type LocalState = {
    currentTime: number | undefined;
    showCurrentTime: boolean; // needed to not show the bar when first loading the page
}
export class AuditGraph extends React.Component<Props, LocalState> {
    protected periodPickerRef = React.createRef<PeriodPicker>();
    protected columnConfigDropdownConnectedPageInfo: ConnectedPageInfo = new ConnectedPageInfo(sliceColumnConfigDropdown, AuditGraphFieldLayoutSelector, "SliceAuditGraphFieldLayoutSelector_" + uuid());

    constructor(props: Props) {
        super(props);
        this.state = { currentTime: undefined, showCurrentTime: false };
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.s.startDate, props.s.startDate) || !lodash.isEqual(prevProps.s.endDate, props.s.endDate)) {
            props.r.setInReduxState({ currentStartDate: props.s.startDate, currentEndDate: props.s.endDate });
            // use for history compare tab which set start/end date already
            this.periodPickerRef.current?.props.r.setInReduxState({ startDate: moment(props.s.startDate).toDate().toISOString(), endDate: moment(props.s.endDate).toDate().toISOString() });
            if (!moment(props.s.startDate).isSame(moment(props.s.endDate), 'day')){
                this.periodPickerRef.current?.props.r.setInReduxState({ periodType: PeriodType.CUSTOM });
            }
        }
        if (prevProps && !lodash.isEqual(prevProps.entities, props.entities)) {
            props.r.setInReduxState({refreshUUID: uuid()})
        }
    }

    protected refreshAuditGraph() {
        const startDate = moment(this.periodPickerRef.current!.getStartDate()).toDate().getTime();
        const endDate = moment(this.periodPickerRef.current!.getEndDate()).toDate().getTime();
        this.props.r.setInReduxState({ refreshUUID: uuid(), startDate: startDate, endDate: endDate, currentStartDate: startDate, currentEndDate: endDate });
    }

    onFieldsChange(entityName: string, value: string) {
        this.props.r.setInReduxState({ fields: Utils.isNullOrEmpty(value) ? [] : value.split(",") });
        if (!Utils.isNullOrEmpty(value)) {
            setFieldsForSession(entityName, value);
        } else {
            clearFieldsForSession(entityName);
        }
    }

    render() {
        const { props } = this;
        const { entityDescriptor } = props;
        const fields = Object.keys(entityDescriptor.getAuthorizedFields(FIELDS_READ));
        var dummyFields = props.s.fields?.join(",");
        if (props.s.initialFields == undefined) { // at init, this is undefined, so we can get here the values from session storage in order to send them to formik
            let sessionFields = getFieldsForSession(this.props.entityDescriptor.name);

            // temp code
            if (Utils.isNullOrEmpty(sessionFields) && entityDescriptor.name === "EquipmentResource") {
                const { tempSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient;
                if (!Utils.isNullOrEmpty(tempSettings?.historyGraphInitialFields)) {
                    for (const group of tempSettings!.historyGraphInitialFields.split("|||")) {
                        const fields = group.split(";");
                        const fieldAndValue = fields[0].split("=");
                        if (fieldAndValue[1] == Utils.navigate(this.props.entities[0], fieldAndValue[0], false, '.')) {
                            sessionFields = fields[1];
                            break;
                        }
                    }
                }
            }
            
            dummyFields = !Utils.isNullOrEmpty(sessionFields) ? sessionFields! : "";
            this.props.r.setInReduxState({ fields: Utils.isNullOrEmpty(dummyFields) ? [] : dummyFields.split(","), initialFields: dummyFields });
        }
        const dummyEntity = { entityName: entityDescriptor.name, fields: dummyFields };

        let fd = new FieldDescriptor();
        fd.name = "fields";
        fd.type = FieldType.entityFields;
        fd.fieldForEntityName = "entityName";
        fd.fieldForEntity = "entity";
        fd.showOnlyAuditableFields = true;

        const historyGraphItems: JSX.Element[] = [];
        HistoryGraphItemFactory.INSTANCE.getItemsForEntityType(entityDescriptor).forEach(item => {
            const ItemClass = item as any;
            historyGraphItems.push(<ItemClass id={"HistoryGraphItem_" + entityDescriptor.name + "_" + item.displayName}
                refreshUUID={props.s.refreshUUID} startDate={props.s.startDate} endDate={props.s.endDate} entities={this.props.entities}
                showCurrentTime={this.state.showCurrentTime} currentTime={this.state.currentTime}
                currentStartDate={props.s.currentStartDate} currentEndDate={props.s.currentEndDate}
                onRangeChange={(startDate: number, endDate: number) => props.r.setInReduxState({ currentStartDate: startDate, currentEndDate: endDate })}
            />);
        });

        return <Container className="wh100 tiny-spacing">
            <div className="flex-container flex-center AuditGraph_container tiny-spacing">
                <Segment basic className="flex-container-row flex-center flex-grow-shrink-no-overflow w100 tiny-spacing">
                    <MessageExt className="flex-grow">
                        <PeriodPickerRRC id={uniqueId('periodPickerRRC-AuditGraph-')} ref={this.periodPickerRef} />
                    </MessageExt>
                    <Button icon="refresh" style={{ marginLeft: '5px' }} color="green" onClick={() => this.refreshAuditGraph()}></Button>
                </Segment>
                <Segment basic className="flex-container-row flex-center flex-grow-shrink-no-overflow tiny-spacing w100" style={{ zIndex: 2 }}>
                    <Formik initialValues={dummyEntity} onSubmit={() => { }}>
                        {(formikProps: any) => {
                            return <>
                                <EntityFieldsWithCrudViewerFieldEditor showOnlyLabelColumn={this.props.showOnlyLabelColumnInCrudViewer} entity={this.props.entities[0]}
                                    formikProps={formikProps} fieldDescriptor={fd} onFieldsChange={(field: string, value: string) => this.onFieldsChange(entityDescriptor.name, value)}
                                    entityDescriptor={entityDescriptor} fields={fields} />
                                <ConnectedComponentInSimpleComponent info={this.columnConfigDropdownConnectedPageInfo}
                                    entityDescriptor={entityDescriptor}
                                    source={ColumnConfigDropdownSource.EDITOR}
                                    addFieldsFromCC={(cc: ClientColumnConfig) => {
                                        const columns = cc.configObject.columns?.filter(column => EntityDescriptorForServerUtils.getFieldId(entityDescriptor.name, column.name) !== undefined);
                                        const fields = Utils.isNullOrEmpty(formikProps.values.fields) ? [] : formikProps.values.fields.split(",");
                                        formikProps.setFieldValue("fields", fields.concat(columns ? columns.map(c => c.name).filter(c => !fields.includes(c)) : []).join(","));
                                    }}
                                />
                            </>;
                        }}</Formik>
                </Segment>
                <Segment className="flex-container-row flex-center flex-grow-shrink-no-overflow w100 tiny-spacing small-padding">
                    <AnimationRRC id={uniqueId("animationForAuditGraph-")} startTime={props.s.currentStartDate} endTime={props.s.currentEndDate} currentTime={this.state.currentTime}
                        onChange={(reset: boolean, currentTime?: number) => this.setState({ showCurrentTime: !reset, currentTime })} padding="0 0 0 10px" /> 
                </Segment>
                {historyGraphItems}
                {props.s.fields.map(fieldName => {
                    try {
                        // checking if selection still valid
                        const fd = entityDescriptors[entityDescriptor.name].getField(fieldName);
                    } catch {
                        return <></>;
                    }

                    return <HistoryGraphFieldItemRRC id={"HistoryGraphFieldItem_" + entityDescriptor.name + "_" + fieldName}
                        entities={this.props.entities} entityName={entityDescriptor.name} fieldName={fieldName}
                        startDate={props.s.startDate} endDate={props.s.endDate}
                        currentStartDate={props.s.currentStartDate} currentEndDate={props.s.currentEndDate} refreshUUID={props.s.refreshUUID}
                        currentTime={this.state.currentTime} showCurrentTime={this.state.showCurrentTime}
                        onRangeChange={(startDate: number, endDate: number) => props.r.setInReduxState({ currentStartDate: startDate, currentEndDate: endDate })}
                    />;
                })}
            </div>
        </Container>

    }
}

export const AuditGraphRRC = ReduxReusableComponents.connectRRC(AuditGraphState, AuditGraphReducers, AuditGraph);

type AuditGraphFieldLayoutSelectorProps = ColumnConfigDropdownProps & { addFieldsFromCC: (cc: ClientColumnConfig) => void };

class AuditGraphFieldLayoutSelector extends ColumnConfigDropdown<AuditGraphFieldLayoutSelectorProps> {

    renderMenu() {
        const options = this.getOptions().map(option => {
            option.onClick = () => {
                this.props.dispatchers.loadAndUpdateColumnConfig(option.id).then(result => {
                    const cc = result;
                    this.props.addFieldsFromCC(cc ? cc : this.props.entityDescriptor.getDefaultColumnConfig());
                });
            }
            return option;
        });
        return <Dropdown.Menu>
            <Dropdown.Header>
                <Input ref={this.inputRef} fluid onKeyDown={(e: any) => { options.length > 0 && this.onKeyDown(e, options[0].id) }} placeholder={_msg('ColumnConfigDropdown.searchLabel')} id='ColumnConfigDropdown_inputID' icon='search' iconPosition='left' value={this.props.search} onChange={this.onSearchChange} ></Input>
            </Dropdown.Header>
            {this.renderMenuEntries(options, false)}
        </Dropdown.Menu>
    }

    render() {
        const disabled = !AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY);
        return <Button.Group positive><Dropdown disabled={disabled} onClose={this.onClose} onBlur={this.onBlur} onOpen={this.onOpenDropdown} open={this.props.dropdownOpened}
            upward={false} floating className="icon" style={{ marginLeft: "5px" }} icon="plus" labeled
            button text={_msg("AuditGraph.addFrom", columnConfigEntityDescriptor.getLabel())} >
            {this.renderMenu()}
        </Dropdown></Button.Group>
    }
}
"../..""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/FieldType""../../components/periodPicker/PeriodPicker""../../components/semanticUiReactExt""../../components/ColumnConfig/dataStructures""../../components/ColumnConfig/ColumnConfigEntityDescriptor""../../components/ColumnConfig/ColumnConfigDropdown""../../components/ColumnConfig/ClientColumnConfig""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils""../../AppMetaTempGlobals""../../entity_crud/fieldEditors/EntityFieldsWithCrudViewerFieldEditor""../../components/animation/Animation""../../reduxReusableComponents/ReduxReusableComponents"